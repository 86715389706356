import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import _ from "lodash";

import { baiFetch } from "../auth";
import { ResourceSlot, ResourceSlotDetailMap } from "../backendai";
import { availableProjectListAtom } from "./projects";

export const resourceSlotListAtom = atom(async (get) => {
  const { cpu, mem, ...resourceSlots } = await baiFetch(
    "/func/config/resource-slots"
  ).then((res) => res.json());
  return _.keys(resourceSlots).map((slot) => slot as ResourceSlot);
});

// Ceveat: Memory Leaks - https://jotai.org/docs/utilities/family#caveat-memory-leaks
export const resourceSlotDetailMapAtom = atomFamily(
  (resourceGroup: string) =>
    atom(async (get) => {
      const { cpu, mem, ...resourceSlots } = await baiFetch(
        `/func/config/resource-slots/details?sgroup=${resourceGroup}`
      ).then((res) => res.json());
      return resourceSlots as ResourceSlotDetailMap;
    }),
  (a, b) => a === b
);

interface ProjectResourceGroup {
  scaling_groups: {
    name: string;
  }[];
}

export const projectResourceGroupListAtom = atom(async (get) => {
  const projects = await get(availableProjectListAtom);
  const resourceGroups: ProjectResourceGroup[] = await Promise.all(
    _.map(projects, async (project) => {
      return baiFetch(`/func/scaling-groups?group=${project.name}`).then(
        (res) => res.json()
      );
    })
  );
  return {
    projects: _.map(projects, (project, idx: number) => {
      return {
        name: project.name,
        resourceGroup: _.map(
          resourceGroups[idx].scaling_groups.filter(
            (rg) => rg.name !== "upload"
          ),
          (rg) => rg.name
        ),
      };
    }),
  };
});
