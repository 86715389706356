/**
 * @generated SignedSource<<7f7d96800e515c3993d31f68fde1795a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineJobUnarchiveButton_pipelineJob$data = {
  readonly id: string;
  readonly " $fragmentType": "PipelineJobUnarchiveButton_pipelineJob";
};
export type PipelineJobUnarchiveButton_pipelineJob$key = {
  readonly " $data"?: PipelineJobUnarchiveButton_pipelineJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineJobUnarchiveButton_pipelineJob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineJobUnarchiveButton_pipelineJob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "PipelineJob",
  "abstractKey": null
};

(node as any).hash = "6ea10198bd2b4194d559aac7e9459aa3";

export default node;
