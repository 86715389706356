/**
 * @generated SignedSource<<2154740775aec7cdc920afee1494e7ed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ArchivePipelineJobInput = {
  clientMutationId?: string | null;
  id: string;
};
export type PipelineJobArchiveButtonMutation$variables = {
  input: ArchivePipelineJobInput;
};
export type PipelineJobArchiveButtonMutation$data = {
  readonly archivePipelineJob: {
    readonly pipelineJob: {
      readonly __typename: "ActivePipelineJobError";
      readonly message: string | null;
    } | {
      readonly __typename: "PipelineJob";
      readonly id: string;
    } | {
      readonly __typename: "UnauthenticatedError";
      readonly message: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | null;
};
export type PipelineJobArchiveButtonMutation = {
  response: PipelineJobArchiveButtonMutation$data;
  variables: PipelineJobArchiveButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "PipelineJob",
  "abstractKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v6 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "UnauthenticatedError",
  "abstractKey": null
},
v7 = {
  "kind": "InlineFragment",
  "selections": (v5/*: any*/),
  "type": "ActivePipelineJobError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineJobArchiveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchivePipelineJobPayload",
        "kind": "LinkedField",
        "name": "archivePipelineJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipelineJob",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineJobArchiveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ArchivePipelineJobPayload",
        "kind": "LinkedField",
        "name": "archivePipelineJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipelineJob",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2dbffb09d387087cbb8bfefab4a0b848",
    "id": null,
    "metadata": {},
    "name": "PipelineJobArchiveButtonMutation",
    "operationKind": "mutation",
    "text": "mutation PipelineJobArchiveButtonMutation(\n  $input: ArchivePipelineJobInput!\n) {\n  archivePipelineJob(input: $input) {\n    pipelineJob {\n      __typename\n      ... on PipelineJob {\n        id\n      }\n      ... on UnauthenticatedError {\n        message\n      }\n      ... on ActivePipelineJobError {\n        message\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3546071b3477f886fa99ed4734a412c0";

export default node;
