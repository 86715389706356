import { useLocalStorageState } from "ahooks";
import { Button, Popover, Tour } from "antd";
import { PopoverProps, TourProps } from "antd/lib";
import React, { useState } from "react";

import Flex from "./Flex";

interface Props {
  tourId: string;
  tourProps?: Pick<TourProps, "steps">;
  popoverProps?: Omit<PopoverProps, "content">;
  children?: React.ReactNode;
  title?: React.ReactNode;
  content?: React.ReactNode;
}

const TourPopover: React.FC<Props> = ({
  children,
  tourId,
  tourProps,
  popoverProps,
  ...props
}) => {
  const [tourCompleted, setTourCompleted] = useLocalStorageState<boolean>(
    `tour-completed.${tourId}`,
    {
      defaultValue: false,
    }
  );
  const [popoverOpen, setPopoverOpen] = useState<boolean>(
    popoverProps?.open ?? !tourCompleted
  );
  const [tourOpen, setTourOpen] = useState<boolean>(false);
  return (
    <>
      <Popover
        title={props.title}
        content={
          <Flex direction="column" align="stretch" justify="center">
            {props.content}
            <Flex
              direction="row"
              align="center"
              justify="between"
              style={{ paddingTop: 16 }}
            >
              <Button
                size="small"
                onClick={() => {
                  setPopoverOpen(false);
                  setTourCompleted(true);
                }}
              >
                Close
              </Button>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  setPopoverOpen(false);
                  setTourOpen(true);
                }}
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        }
        open={popoverOpen}
      >
        {children}
      </Popover>
      <Tour
        steps={tourProps?.steps}
        onClose={() => {
          setTourOpen(false);
          setTourCompleted(true);
        }}
        open={tourOpen}
      />
    </>
  );
};

export default TourPopover;
