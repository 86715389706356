import _ from "lodash";
import {
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";

import { baiFetch } from "./hooks/auth";

export const HTTP_401_UNAUTHORIZED = 401;

// eslint-disable-next-line
//@ts-ignore
const fetchRelay: FetchFunction = async (
  params,
  variables,
  cacheConfig,
  uploadables
) => {
  // console.debug(
  //   `fetching query ${params.name} with ${JSON.stringify(variables)} ${
  //     params.text
  //   }`
  // );
  // console.log(cacheConfig, uploadables);

  // default headers
  // TODO: 필요시 현재 사용자 sessionToken headers 지정
  const headers: {
    [key: string]: string;
  } = {};

  let body: any;
  if (uploadables) {
    headers["Content-Type"] = "multipart/form-data";
    body = body = new FormData();
    body.append(
      "operations",
      JSON.stringify({
        query: params.text,
        variables,
      })
    );

    const keys = _.keys(uploadables);
    const map = _.reduce(
      keys,
      (result, v, idx) => {
        result[idx.toString()] = [v];
        return result;
      },
      {} as {
        [key: string]: string[];
      }
    );
    body.append("map", JSON.stringify(map));
    _.each(keys, (v, idx) => {
      body.append(idx.toString(), uploadables[v]);
    });
  } else {
    body = JSON.stringify({
      query: params.text,
      variables,
    });
  }

  return baiFetch("/pipeline/graphql/", {
    method: "POST",
    headers,
    body: body,
  }).then(async (response) => {
    if (response.status === HTTP_401_UNAUTHORIZED) {
      throw new Error("InvalidSessionToken");

      // return response
      //   .clone()
      //   .json()
      //   .catch(() => {
      //     // handle when response is text.
      //     alert(1);
      //     const errorText = response.text();
      //     throw errorText;
      //   })
      //   .then((res) => {
      //     // TODO: to set the rule of error message with ErrorBoundary
      //     throw res;
      //   });
    } else {
      const res = await response.json();
      // if (res.errors) {
      //   throw res.errors;
      // }

      return res;
    }
  });
};

// Export a singleton instance of Relay Environment
// configured with our network function:
export const createRelayEnvironment = () => {
  return new Environment({
    network: Network.create(fetchRelay),
    store: new Store(new RecordSource()),
    // handlerProvider: null
  });
};
