/**
 * @generated SignedSource<<785f14209c6a640d8a6d1792bbe3f250>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DestroyPipelineInput = {
  clientMutationId?: string | null;
  id: string;
};
export type PipelineDeleteButtonDeleteMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DestroyPipelineInput;
};
export type PipelineDeleteButtonDeleteMutation$data = {
  readonly destroyPipeline: {
    readonly error: {
      readonly __typename: "ScheduleExistsError";
      readonly message: string | null;
    } | {
      readonly __typename: "UnauthenticatedError";
      readonly message: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
    readonly pipeline: {
      readonly id: string;
    } | null;
  } | null;
};
export type PipelineDeleteButtonDeleteMutation = {
  response: PipelineDeleteButtonDeleteMutation$data;
  variables: PipelineDeleteButtonDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "message",
    "storageKey": null
  }
],
v7 = {
  "kind": "InlineFragment",
  "selections": (v6/*: any*/),
  "type": "UnauthenticatedError",
  "abstractKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": (v6/*: any*/),
  "type": "ScheduleExistsError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineDeleteButtonDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DestroyPipelinePayload",
        "kind": "LinkedField",
        "name": "destroyPipeline",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Pipeline",
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": (v4/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PipelineDeleteButtonDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DestroyPipelinePayload",
        "kind": "LinkedField",
        "name": "destroyPipeline",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Pipeline",
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "deleteEdge",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id",
                "handleArgs": [
                  {
                    "kind": "Variable",
                    "name": "connections",
                    "variableName": "connections"
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "error",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v4/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6b54214a937e426005901a3fa7cd3a4a",
    "id": null,
    "metadata": {},
    "name": "PipelineDeleteButtonDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation PipelineDeleteButtonDeleteMutation(\n  $input: DestroyPipelineInput!\n) {\n  destroyPipeline(input: $input) {\n    pipeline {\n      id\n    }\n    error {\n      __typename\n      ... on UnauthenticatedError {\n        message\n      }\n      ... on ScheduleExistsError {\n        message\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "919fe6b06e07b149067ef74003dc8212";

export default node;
