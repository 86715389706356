/**
 * @generated SignedSource<<d0cde28b469f8ab9bb78a833b9c733cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PipelineInitializationType = "none" | "template" | "yaml" | "%future added value";
export type PipelineScope = "personal" | "project" | "%future added value";
export type CreatePipelineInput = {
  clientMutationId?: string | null;
  description?: string | null;
  environment?: PipelineEnvironmentInput | null;
  initialization?: PipelineInitializationType | null;
  name: string;
  project?: string | null;
  scope?: PipelineScope | null;
  storage?: PipelineFolderInput | null;
  template?: string | null;
  yaml?: string | null;
};
export type PipelineEnvironmentInput = {
  envs?: any | null;
};
export type PipelineFolderInput = {
  host: string;
  id?: any | null;
  name?: string | null;
};
export type PipelineEditorModalCreateMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreatePipelineInput;
};
export type PipelineEditorModalCreateMutation$data = {
  readonly createPipeline: {
    readonly pipeline: {
      readonly __typename: "Pipeline";
      readonly description: string;
      readonly id: string;
      readonly lastModified: any;
      readonly name: string;
      readonly storage: any | null;
      readonly yaml: string;
    } | {
      readonly __typename: "UnauthenticatedError";
      readonly message: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | null;
};
export type PipelineEditorModalCreateMutation = {
  response: PipelineEditorModalCreateMutation$data;
  variables: PipelineEditorModalCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yaml",
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UnauthenticatedError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineEditorModalCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreatePipelinePayload",
        "kind": "LinkedField",
        "name": "createPipeline",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "PipelineEditorModalCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreatePipelinePayload",
        "kind": "LinkedField",
        "name": "createPipeline",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v4/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "filters": null,
            "handle": "prependNode",
            "key": "",
            "kind": "LinkedHandle",
            "name": "pipeline",
            "handleArgs": [
              {
                "kind": "Variable",
                "name": "connections",
                "variableName": "connections"
              },
              {
                "kind": "Literal",
                "name": "edgeTypeName",
                "value": "PipelineEdge"
              }
            ]
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "92104da0adcf11464661bbd3749ee477",
    "id": null,
    "metadata": {},
    "name": "PipelineEditorModalCreateMutation",
    "operationKind": "mutation",
    "text": "mutation PipelineEditorModalCreateMutation(\n  $input: CreatePipelineInput!\n) {\n  createPipeline(input: $input) {\n    pipeline {\n      __typename\n      ... on Pipeline {\n        id\n        name\n        description\n        lastModified\n        storage\n        yaml\n      }\n      ... on UnauthenticatedError {\n        message\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d3e779aa9117a337b0f45a91713544cb";

export default node;
