/**
 * @generated SignedSource<<350712c625f155f900b52fdd638ae6d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetPipelineScheduleInput = {
  clientMutationId?: string | null;
  dayOfMonth: string;
  dayOfWeek: string;
  expires: any;
  hour: string;
  id: string;
  minute: string;
  monthOfYear: string;
  timezone?: string | null;
};
export type RunPipelineModalSetScheduleMutation$variables = {
  input: SetPipelineScheduleInput;
};
export type RunPipelineModalSetScheduleMutation$data = {
  readonly setPipelineSchedule: {
    readonly pipeline: {
      readonly __typename: string;
      readonly id?: string;
      readonly message?: string | null;
      readonly scheduledTask?: {
        readonly expireSeconds: number | null;
        readonly expires: any | null;
        readonly id: string;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"PipelineSummaryFragment">;
    } | null;
  } | null;
};
export type RunPipelineModalSetScheduleMutation = {
  response: RunPipelineModalSetScheduleMutation$data;
  variables: RunPipelineModalSetScheduleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expires",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expireSeconds",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UnauthenticatedError",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RunPipelineModalSetScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetPipelineSchedulePayload",
        "kind": "LinkedField",
        "name": "setPipelineSchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PeriodicTask",
                    "kind": "LinkedField",
                    "name": "scheduledTask",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Pipeline",
                "abstractKey": null
              },
              (v6/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PipelineSummaryFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RunPipelineModalSetScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SetPipelineSchedulePayload",
        "kind": "LinkedField",
        "name": "setPipelineSchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PeriodicTask",
                    "kind": "LinkedField",
                    "name": "scheduledTask",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CrontabSchedule",
                        "kind": "LinkedField",
                        "name": "crontab",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minute",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hour",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dayOfWeek",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dayOfMonth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "monthOfYear",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastModified",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "storage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "yaml",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "owner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      (v7/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PipelineJobConnection",
                    "kind": "LinkedField",
                    "name": "pipelinejobSet",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PipelineVersionConnection",
                    "kind": "LinkedField",
                    "name": "pipelineversionSet",
                    "plural": false,
                    "selections": [
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PipelineVersionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PipelineVersion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Pipeline",
                "abstractKey": null
              },
              (v6/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b7f5f9200449a85ffa01524169ef66e7",
    "id": null,
    "metadata": {},
    "name": "RunPipelineModalSetScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation RunPipelineModalSetScheduleMutation(\n  $input: SetPipelineScheduleInput!\n) {\n  setPipelineSchedule(input: $input) {\n    pipeline {\n      __typename\n      ... on Pipeline {\n        id\n        scheduledTask {\n          id\n          expires\n          expireSeconds\n        }\n      }\n      ... on UnauthenticatedError {\n        message\n      }\n      ...PipelineSummaryFragment\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment CrontabViewFragment on CrontabSchedule {\n  id\n  minute\n  hour\n  dayOfWeek\n  dayOfMonth\n  monthOfYear\n}\n\nfragment PipelineDeleteButtonFragment on Pipeline {\n  id\n  name\n  storage\n  scheduledTask {\n    expires\n    id\n  }\n}\n\nfragment PipelineEditorModalFragment on Pipeline {\n  id\n  name\n  description\n  storage\n  yaml\n  scheduledTask {\n    id\n  }\n}\n\nfragment PipelineSummaryFragment on Pipeline {\n  id\n  name\n  description\n  lastModified\n  isActive\n  storage\n  yaml\n  owner {\n    username\n    name\n    id\n  }\n  pipelinejobSet {\n    totalCount\n  }\n  pipelineversionSet {\n    totalCount\n    edges {\n      node {\n        id\n        createdAt\n      }\n    }\n  }\n  scheduledTask {\n    crontab {\n      ...CrontabViewFragment\n      id\n    }\n    expires\n    expireSeconds\n    id\n  }\n  ...PipelineEditorModalFragment\n  ...RunPipelineModalFragment\n  ...PipelineDeleteButtonFragment\n}\n\nfragment RunPipelineModalFragment on Pipeline {\n  id\n  name\n  yaml\n  scheduledTask {\n    crontab {\n      minute\n      hour\n      dayOfMonth\n      monthOfYear\n      dayOfWeek\n      id\n    }\n    expires\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4ed9593e41210a70a7f5e271876f386a";

export default node;
