/**
 * @generated SignedSource<<4a46574b47f2e84bcb506ff4720d0425>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UnarchivePipelineJobInput = {
  clientMutationId?: string | null;
  id: string;
};
export type PipelineJobUnarchiveButtonMutation$variables = {
  input: UnarchivePipelineJobInput;
};
export type PipelineJobUnarchiveButtonMutation$data = {
  readonly unarchivePipelineJob: {
    readonly pipelineJob: {
      readonly __typename: "PipelineJob";
      readonly id: string;
    } | {
      readonly __typename: "UnauthenticatedError";
      readonly message: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | null;
};
export type PipelineJobUnarchiveButtonMutation = {
  response: PipelineJobUnarchiveButtonMutation$data;
  variables: PipelineJobUnarchiveButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v4 = {
  "kind": "InlineFragment",
  "selections": (v3/*: any*/),
  "type": "PipelineJob",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UnauthenticatedError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineJobUnarchiveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnarchivePipelineJobPayload",
        "kind": "LinkedField",
        "name": "unarchivePipelineJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipelineJob",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineJobUnarchiveButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UnarchivePipelineJobPayload",
        "kind": "LinkedField",
        "name": "unarchivePipelineJob",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipelineJob",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v3/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "76490d713ef7b409e7082c70ba7474df",
    "id": null,
    "metadata": {},
    "name": "PipelineJobUnarchiveButtonMutation",
    "operationKind": "mutation",
    "text": "mutation PipelineJobUnarchiveButtonMutation(\n  $input: UnarchivePipelineJobInput!\n) {\n  unarchivePipelineJob(input: $input) {\n    pipelineJob {\n      __typename\n      ... on PipelineJob {\n        id\n      }\n      ... on UnauthenticatedError {\n        message\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "036a10c58c71e9e1a19bac4fb0206883";

export default node;
