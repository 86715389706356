import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import _ from "lodash";

import { baiFetch } from "../auth";
import { minifyGraphQLQuery } from "../backendai";
import { Project } from "./projects";

export * from "./folders";
export * from "./images";
export * from "./pipelines";
export * from "./projects";
export * from "./resources";

export const STORAGE_KEY = {
  SESSION_ID: "Backend_AI_FastTrack.sessionID",
  SSO_TOKEN: "Backend_AI_FastTrack.ssoToken",
};

export const activeKeyAtom = atom<string | string[]>(["1"]);

export const coreServiceVersionAtom = atom(async (get) => {
  const response: {
    version: string;
    manager: string;
  } = await baiFetch("/func/").then((res) => res.json());
  return response;
});

export const serviceVersionAtom = atom(async (get) => {
  const response = await baiFetch("/pipeline/hello/").then((res) => res.json());
  return response.version;
});

export const authTokenAtom = atomWithStorage<string | null>(
  STORAGE_KEY.SSO_TOKEN,
  null,
  {
    getItem(key: string, initialValue: string | null) {
      return localStorage.getItem(key) ?? initialValue;
    },
    setItem(key: string, newValue: string | null) {
      localStorage.setItem(key, newValue ?? "");
    },
    removeItem: localStorage.removeItem,
  },
  { getOnInit: true }
);

interface User {
  username: string;
  email: string;
  full_name: string;
  is_active: boolean;
  role: string;
  domain_name: string;
  groups: Project[];
}

export const userAtom = atom(async (get) => {
  const authToken = get(authTokenAtom);
  if (!authToken) {
    return null;
  }
  try {
    const response = await baiFetch("/func/admin/gql", {
      method: "POST",
      body: JSON.stringify({
        query: minifyGraphQLQuery(`
          query {
            user {
              username
              email
              full_name
              is_active
              role
              domain_name
              groups {
                id
                name
              }
            }
          }
        `),
      }),
    }).then((res) => res.json());
    return response?.data.user as User;
  } catch (e) {
    return null;
  }
});
