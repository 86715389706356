import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, InputRef, Table, theme } from "antd";
import { FormItemProps, FormListProps, Rule } from "antd/lib/form";
import _ from "lodash";
import React, { useRef } from "react";

import Flex from "./Flex";
import { EnvVar } from "./legacy/EnvVarEditorModal";

interface Props extends Omit<FormListProps, "children"> {
  formItemProps?: FormItemProps;
  keyRules?: Rule[];
}

export const KeyRules: { [key: string]: Rule } = {
  BLOCK_BACKENDAI_PREFIX: {
    pattern: /^(?!BACKENDAI_).+/,
    message: "Keys starting with 'BACKENDAI_' are not allowed.",
  },
};

export interface IEnvironmentVariable {
  key: string;
  value: string;
}

export const getEnvironmentVariableArray = (
  environmentVariableMap?: EnvVar
): IEnvironmentVariable[] =>
  _.entries(environmentVariableMap).map(([key, value]) => ({ key, value }));

export const getEnvironmentVariableMap = (
  environmentVariableArray?: IEnvironmentVariable[]
): EnvVar =>
  _.reduce(
    environmentVariableArray,
    (map, env) => ({ ...map, [env.key]: env.value }),
    {}
  );

const EnvironmentVariableList: React.FC<Props> = ({
  formItemProps,
  keyRules = [],
  ...props
}) => {
  const { token } = theme.useToken();
  const form = Form.useFormInstance();
  const inputRef = useRef<InputRef>(null);
  const columns = [
    {
      title: "Key",
      width: "48.75%",
    },
    {
      title: "Value",
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        locale={{
          emptyText: () => <></>,
        }}
        size="small"
        style={{ marginBottom: -8 }}
      />
      <Form.List {...props}>
        {(fields, { add, remove }) => (
          <Flex direction="column" gap={"xs"} align="stretch">
            {fields.map(({ key, name, ...restFields }, index) => (
              <Flex key={key} direction="row" align="baseline" gap={"xs"}>
                <Form.Item
                  {...restFields}
                  name={[name, "key"]}
                  style={{ marginBottom: 0, flex: 1 }}
                  rules={[
                    {
                      required: true,
                      message: "Key is required.",
                    },
                    {
                      pattern: /^[a-zA-Z_][a-zA-Z0-9_]*$/,
                      message:
                        "Key must starts with an alphabet letter or an underscore, and can only contain letters, numbers and underscores.",
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (
                          !_.isEmpty(value) &&
                          _.filter(
                            getFieldValue(props.name) as IEnvironmentVariable[],
                            (__, i) => i !== index
                          ).find((env) => env.key === value)
                        ) {
                          return Promise.reject("Key already exists.");
                        }
                        return Promise.resolve();
                      },
                    }),
                    ...keyRules,
                  ]}
                  {...formItemProps}
                >
                  <Input
                    ref={index === fields.length - 1 ? inputRef : null}
                    placeholder="Key"
                    onChange={() => {
                      const fieldNames = fields.map((field, index) => [
                        ..._.castArray(props.name),
                        index,
                        "key",
                      ]);
                      form.validateFields(fieldNames);
                    }}
                  />
                </Form.Item>
                <Form.Item
                  {...restFields}
                  name={[name, "value"]}
                  style={{ marginBottom: 0, flex: 1 }}
                  rules={[{ required: true, message: "Enter value" }]}
                >
                  <Input placeholder="Value" />
                </Form.Item>
                <MinusCircleOutlined
                  style={{ color: token.colorError }}
                  onClick={() => remove(name)}
                />
              </Flex>
            ))}
            <Form.Item noStyle>
              <Button
                type="dashed"
                style={{ color: token.colorPrimary }}
                onClick={() => {
                  add();
                  setTimeout(() => {
                    inputRef?.current?.focus();
                  }, 0);
                }}
                icon={<PlusOutlined />}
                block
              >
                Add
              </Button>
            </Form.Item>
          </Flex>
        )}
      </Form.List>
    </>
  );
};

export default EnvironmentVariableList;
