interface DescriptiveErrorOptions extends ErrorOptions {
  description?: string;
}

class DescriptiveError<T extends DescriptiveErrorOptions> extends Error {
  description?: string;

  constructor(message?: string, options?: T) {
    super(message, options);
    this.description = options?.description;
  }
}

// eslint-disable-next-line
interface AuthenticationErrorOptions extends DescriptiveErrorOptions {}

export class AuthenticationError extends DescriptiveError<AuthenticationErrorOptions> {}

// eslint-disable-next-line
interface ConfigurationErrorOptions extends DescriptiveErrorOptions {}

export class ConfigurationError extends DescriptiveError<ConfigurationErrorOptions> {}
