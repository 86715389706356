import { OneToOneOutlined, ShareAltOutlined } from "@ant-design/icons";
import { Segmented, Typography } from "antd";
import graphql from "babel-plugin-relay/macro";
import _ from "lodash";
import * as monaco from "monaco-editor";
import { useEffect, useRef } from "react";
import { useFragment } from "react-relay";

import { from_global_id } from "../helpers";
import CodeButtons from "./CodeButtons";
import Flex from "./Flex";
import WhiteSpace from "./WhiteSpace";
import { PipelineVersionYamlViewerFragment$key } from "./__generated__/PipelineVersionYamlViewerFragment.graphql";

// eslint-disable-next-line
// @ts-ignore
window.MonacoEnvironment = {
  // eslint-disable-next-line
  // @ts-ignore
  getWorker(moduleId, label) {
    switch (label) {
      case "editorWorkerService":
        return new Worker(
          new URL("monaco-editor/esm/vs/editor/editor.worker", import.meta.url)
        );
      case "yaml":
        return new Worker(new URL("monaco-yaml/yaml.worker", import.meta.url));
      default:
        throw new Error(`Unknown label ${label}`);
    }
  },
};

const { Text } = Typography;

interface Props {
  onRequestClose: () => void;
  onSaveComplete?: (yaml: string) => void;
  pipelineVersionFrgmt: PipelineVersionYamlViewerFragment$key | null;
}

const PipelineVersionYamlViewer: React.FC<Props> = ({
  onRequestClose,
  onSaveComplete: onClickSave,
  pipelineVersionFrgmt,
}) => {
  const pipelineVersion = useFragment(
    graphql`
      fragment PipelineVersionYamlViewerFragment on PipelineVersion {
        id
        yaml
      }
    `,
    pipelineVersionFrgmt
  );
  // https://github.com/microsoft/monaco-editor/blob/main/samples/browser-esm-webpack-typescript-react/src/components/Editor.tsx
  const divEl = useRef<HTMLDivElement>(null);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

  useEffect(() => {
    if (divEl.current) {
      editorRef.current = monaco.editor.create(divEl.current, {
        value: pipelineVersion?.yaml || "",
        language: "yaml",
        fontSize: 15,
        automaticLayout: true,
        readOnly: true,
      });
    }
    return () => {
      editorRef.current?.dispose();
    };
  }, [pipelineVersion?.id]);
  const shortVersionId = from_global_id(pipelineVersion?.id || "")[1].split(
    "-"
  )[0];

  return (
    <Flex direction="column" align="stretch" style={{ height: "100%" }}>
      <Flex justify="between" style={{ padding: "8px 10px" }}>
        <Flex>
          <OneToOneOutlined />
          <WhiteSpace direction="row" size="sm" />
          <Text strong>YAML Viewer: {shortVersionId}</Text>
          <WhiteSpace direction="row" />
          <CodeButtons
            buttonProps={{ size: "small", disabled: false }}
            types={["download", "copy"]}
            code={pipelineVersion?.yaml || ""}
            language="yaml"
            filename={`${shortVersionId}.yaml`}
            downloadText={"Download"}
          />
        </Flex>
        <Flex>
          <WhiteSpace direction="row" />
          <Segmented
            value={"yaml"}
            onChange={(value) => {
              onRequestClose();
            }}
            options={[
              {
                label: "Graph",
                value: "graph",
                icon: <ShareAltOutlined rotate={90} />,
              },
              {
                label: "YAML",
                value: "yaml",
                icon: <OneToOneOutlined />,
              },
            ]}
          />
          <WhiteSpace direction="row" />
        </Flex>
      </Flex>
      <Flex style={{ flex: 1 }}>
        <div
          className="Editor"
          ref={divEl}
          style={{
            display: "flex",
            height: "100%",
            flex: 1,
          }}
        ></div>
      </Flex>
    </Flex>
  );
};

export default PipelineVersionYamlViewer;
