/**
 * @generated SignedSource<<f343511c616ad8f8a97bd3fd5fa0e736>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineEditorModalFragment$data = {
  readonly description: string;
  readonly id: string;
  readonly name: string;
  readonly scheduledTask: {
    readonly id: string;
  } | null;
  readonly storage: any | null;
  readonly yaml: string;
  readonly " $fragmentType": "PipelineEditorModalFragment";
};
export type PipelineEditorModalFragment$key = {
  readonly " $data"?: PipelineEditorModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineEditorModalFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineEditorModalFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yaml",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PeriodicTask",
      "kind": "LinkedField",
      "name": "scheduledTask",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};
})();

(node as any).hash = "ca8f7945b3e61ff3285e074541591cf3";

export default node;
