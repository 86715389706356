/**
 * @generated SignedSource<<a19f407bc74d71a93424bd2aba2d44dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineTaskForm_pipeline$data = {
  readonly storage: any | null;
  readonly yaml: string;
  readonly " $fragmentType": "PipelineTaskForm_pipeline";
};
export type PipelineTaskForm_pipeline$key = {
  readonly " $data"?: PipelineTaskForm_pipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineTaskForm_pipeline">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineTaskForm_pipeline",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yaml",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storage",
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};

(node as any).hash = "cf69d56c237124212b46ebd206e459b5";

export default node;
