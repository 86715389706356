import _ from "lodash";
import React from "react";
import { BaseEdge, EdgeLabelRenderer, EdgeProps } from "reactflow";

import { getBezierPathWithLabel } from "../../helpers/bazierPath";
import Flex from "../Flex";

const DynamicPositionLabelEdge: React.FC<EdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  label,
  data = {},
  ...edgeProps
}) => {
  const [edgePath, labelX, labelY] = getBezierPathWithLabel({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    t: data.t ?? 0.75,
  });

  return (
    <>
      <BaseEdge id={id} path={edgePath} {...edgeProps} />
      <EdgeLabelRenderer>
        {_.isEmpty(label) ? null : (
          <Flex
            style={{
              position: "absolute",
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              background: "#B1B1B6",
              minWidth: 15,
              minHeight: 15,
              borderRadius: 8,
              fontSize: 10,
              color: "#fff",
            }}
            align="center"
            justify="center"
            className="nodrag nopan"
          >
            {label}
          </Flex>
        )}
      </EdgeLabelRenderer>
    </>
  );
};

export default DynamicPositionLabelEdge;
