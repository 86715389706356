/**
 * @generated SignedSource<<29cf6be0b06bde2e23415076ce949e3d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineYamlEditorFragment$data = {
  readonly dataflow: any | null;
  readonly id: string;
  readonly lastModified: any;
  readonly name: string;
  readonly storage: any | null;
  readonly yaml: string;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineTaskForm_pipeline" | "ResourcesInputItems_pipeline" | "VirtualFolderMountFormList_pipeline">;
  readonly " $fragmentType": "PipelineYamlEditorFragment";
};
export type PipelineYamlEditorFragment$key = {
  readonly " $data"?: PipelineYamlEditorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineYamlEditorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineYamlEditorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yaml",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataflow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storage",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "PipelineTaskForm_pipeline"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ResourcesInputItems_pipeline"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "VirtualFolderMountFormList_pipeline"
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};

(node as any).hash = "f5d1af36209cc982e409adb26a085bd1";

export default node;
