/**
 * @generated SignedSource<<1f04435f52d1666e70c0535be17f0946>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ResourcesInputItems_pipeline$data = {
  readonly yaml: string;
  readonly " $fragmentType": "ResourcesInputItems_pipeline";
};
export type ResourcesInputItems_pipeline$key = {
  readonly " $data"?: ResourcesInputItems_pipeline$data;
  readonly " $fragmentSpreads": FragmentRefs<"ResourcesInputItems_pipeline">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResourcesInputItems_pipeline",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yaml",
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};

(node as any).hash = "b1a753f73130a27e2df605c2756fabf8";

export default node;
