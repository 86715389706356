import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Form, Input, Space, Tooltip, theme } from "antd";
import graphql from "babel-plugin-relay/macro";
import _ from "lodash";
import React from "react";
import { useFragment } from "react-relay";

import VFolderPermissionTag from "./VFolderPermissionTag";
import VirtualFolderAliasInput, {
  DEFAULT_VFOLDER_ALIAS_PATH,
} from "./VirtualFolderAliasInput";
import { PipelineTaskForm_pipeline$key } from "./__generated__/PipelineTaskForm_pipeline.graphql";
import { VirtualFolderMountFormList_pipeline$key } from "./__generated__/VirtualFolderMountFormList_pipeline.graphql";

interface Props {
  name?: string;
  pipelineFrgmt:
    | VirtualFolderMountFormList_pipeline$key
    | PipelineTaskForm_pipeline$key
    | null;
  onClick?: () => void;
}

const VirtualFolderMountFormList: React.FC<Props> = ({
  name = "vFolders",
  pipelineFrgmt,
  onClick,
}) => {
  const { token } = theme.useToken();
  const form = Form.useFormInstance();
  const pipeline = useFragment(
    graphql`
      fragment VirtualFolderMountFormList_pipeline on Pipeline {
        storage
      }
    `,
    pipelineFrgmt
  );
  // TODO: Context + Hook
  const currentTaskName = Form.useWatch("name", form);
  const dedicatedFolderName = pipeline?.storage
    ? JSON.parse(pipeline.storage).name
    : undefined;
  return (
    <>
      <Form.Item name="envs" noStyle>
        {/* without hidden input for envs,  `form.validateFields()` will not return the `envs` value*/}
        <Input type="hidden" />
      </Form.Item>
      <Form.Item
        label="Virtual Folder"
        tooltip={`"/pipeline/outputs" will be an alias for "${dedicatedFolderName}/tasks/${currentTaskName}".`}
        style={{
          backgroundColor: "#fafafa",
          padding: "16px 16px 0 16px",
          border: "1px solid #D9D9D9",
          borderRadius: "2px",
        }}
      >
        <Form.List name={name}>
          {(fields, { add, remove }) => (
            <>
              <Space
                style={{ display: "flex", marginBottom: 16 }}
                align="baseline"
              >
                <Tooltip title="You cannot remove the pipeline dedicated folder.">
                  <MinusCircleOutlined
                    style={{ color: token.colorTextDisabled }}
                  />
                </Tooltip>
                <Input
                  readOnly
                  variant="borderless"
                  style={{ textOverflow: "clip" }}
                  value={dedicatedFolderName}
                />
                <Input disabled allowClear value={DEFAULT_VFOLDER_ALIAS_PATH} />
                <VFolderPermissionTag vFolderName={dedicatedFolderName} />
              </Space>
              {fields.map(({ key, name: fieldName, ...restField }) => {
                const folderName = form.getFieldValue([name, key, "name"]);
                return folderName !== dedicatedFolderName ? (
                  <Space
                    key={key}
                    style={{ display: "flex", marginBottom: 0 }}
                    align="baseline"
                  >
                    <MinusCircleOutlined
                      style={{ color: token.colorError }}
                      onClick={() => remove(fieldName)}
                    />
                    <Form.Item
                      {...restField}
                      name={[fieldName, "name"]}
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        readOnly
                        variant="borderless"
                        style={{ textOverflow: "clip" }}
                      />
                    </Form.Item>
                    <VirtualFolderAliasInput
                      name={[fieldName, "alias"]}
                      folderName={folderName}
                      style={{ marginBottom: 16 }}
                    />
                    <VFolderPermissionTag vFolderName={folderName} />
                  </Space>
                ) : undefined;
              })}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    onClick?.();
                  }}
                  block
                  icon={<PlusOutlined />}
                >
                  Select Virtual Folder
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </Form.Item>
    </>
  );
};

export default VirtualFolderMountFormList;
