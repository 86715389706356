import {
  CheckOutlined,
  LayoutOutlined,
  QuestionCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { inspect } from "@xstate/inspect";
import { useMachine } from "@xstate/react";
import { useUpdateEffect } from "ahooks";
import {
  Button,
  Drawer,
  Form,
  Modal,
  Space,
  Switch,
  Tooltip,
  Typography,
  message,
  notification,
} from "antd";
import graphql from "babel-plugin-relay/macro";
import dagre from "dagre";
import dayjs from "dayjs";
import { GenericGraphAdapter } from "incremental-cycle-detect";
import { useAtomValue } from "jotai";
import { default as YAML } from "js-yaml";
import _ from "lodash";
import React, {
  DragEventHandler,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import ReactFlow, {
  Background,
  ControlButton,
  Controls,
  Edge,
  EdgeTypes,
  MarkerType,
  MiniMap,
  Node,
  OnEdgesChange,
  OnNodesChange,
  ReactFlowProps,
  Viewport,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import { StringParam, useQueryParam } from "use-query-params";
import { v4 as uuidv4 } from "uuid";

import "../components/PipelineYamlEditor/TaskNode.css";
import {
  generateRandomString,
  getStatusBgColor,
  getStatusTextColor,
} from "../helpers";
import { PartialBy } from "../helpers/types";
import { projectIdAtom, userAtom } from "../hooks/atoms";
import { baiFetch } from "../hooks/auth";
import {
  ResourceSlot,
  VirtualFolder,
  useImageGroupsState,
  useResourceSlotsState,
} from "../hooks/backendai";
import { useEventNotStable } from "../hooks/eventNotStable";
import {
  PipelineTaskEditorContext,
  pipelineTaskEditor,
} from "../machines/pipelineTaskEditor";
import {
  IEnvironmentVariable,
  getEnvironmentVariableArray,
  getEnvironmentVariableMap,
} from "./EnvironmentVariableList";
import Flex from "./Flex";
import FlexActivityIndicator from "./FlexActivityIndicator";
import PipelineTaskForm, {
  ClusterMode,
  PipelineTaskFormInput,
} from "./PipelineTaskForm";
import DynamicPositionLabelEdge from "./PipelineYamlEditor/DynamicPositionLabelEdge";
import TaskNode from "./PipelineYamlEditor/TaskNode";
import TaskNodeDeletePopconfirm from "./PipelineYamlEditor/TaskNodeDeletePopconfirm";
import {
  DEFAULT_VFOLDER_ALIAS_PATH,
  DEFAULT_VFOLDER_ALIAS_SEPARATOR,
} from "./VirtualFolderAliasInput";
import { PipelineYamlEditorFragment$key } from "./__generated__/PipelineYamlEditorFragment.graphql";
import { PipelineYamlEditorJobFragment$key } from "./__generated__/PipelineYamlEditorJobFragment.graphql";
import { PipelineYamlEditorQuery } from "./__generated__/PipelineYamlEditorQuery.graphql";
import { PipelineYamlEditorUpdateMutation } from "./__generated__/PipelineYamlEditorUpdateMutation.graphql";
import { EnvVar } from "./legacy/EnvVarEditorModal";

const { Text } = Typography;

const edgeTypes: EdgeTypes = {
  labelPosition: DynamicPositionLabelEdge,
};

function applyAutoLayout(
  nodes: Pick<Node<TaskNodeData>, "id" | "data">[],
  edges: Edge[]
) {
  const g = new dagre.graphlib.Graph();
  g.setGraph({});
  // Default to assigning a new object as a label for each new edge.
  g.setDefaultEdgeLabel(function () {
    return {};
  });

  nodes.forEach((node) => {
    g.setNode(node.id, {
      label: node.data.label,
      width: 150,
      height: 40,
    });
  });

  edges.forEach((edge) => {
    g.setEdge(edge.source, edge.target);
  });

  dagre.layout(g);

  const newNodes = nodes.map((node) => {
    const nodeInfo = g.node(node.id);
    return {
      ...node,
      position: {
        x: nodeInfo.x,
        y: nodeInfo.y,
      },
    };
  });

  return newNodes;
}

process.env.REACT_APP_XSTATE_INSPECTOR &&
  inspect({
    url: "https://statecharts.io/inspect",
    iframe: false,
  });

export interface NodeValidationErrorMap {
  [key: string]: any[];
}

const PipelineYamlEditor: React.FC<{
  key?: string;
  pipelineFrgmt?: PipelineYamlEditorFragment$key | null;
  pipelineJobFrgmt?: PipelineYamlEditorJobFragment$key | null;
  onChangeNodeValidation?: (errors: NodeValidationErrorMap) => void;
}> = ({ key, pipelineFrgmt, pipelineJobFrgmt, onChangeNodeValidation }) => {
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const reactFlowInstance = useReactFlow();
  const [, { getImageOptionKey, getImageInfoByKey }] = useImageGroupsState();
  const user = useAtomValue(userAtom);

  const projectId = useAtomValue(projectIdAtom);
  const searchParams = new URLSearchParams();
  if (projectId !== "") {
    searchParams.append("group_id", projectId);
  }
  const { data: folderList } = useQuery<VirtualFolder[]>({
    queryKey: ["vFolderList", projectId, searchParams],
    queryFn: async () =>
      baiFetch("/func/folders" + (projectId !== "" ? "?" + searchParams : ""), {
        method: "GET",
      }).then((r) => r.json()),
    suspense: true,
    cacheTime: 0,
  });

  const resourceSlots = useResourceSlotsState();

  const task2FieldValues = (task: PipelineTask) => {
    // Set fields value here
    const name = task?.name;
    const type = task?.type;

    const envs: IEnvironmentVariable[] = getEnvironmentVariableArray(
      task.environment?.envs
    );

    // from environment.image
    const image = task?.environment?.image;
    // find a matching image in the list of images
    //if currentImage is exists, find a group and subgroup name.
    const imageInfo = getImageInfoByKey(image || "");

    const resources = task?.resources || {};
    const cpu = resources.cpu || 0;

    // WARN: https://lablup.atlassian.net/browse/FT-39
    // need to use parseFloat in both memory and shared memory
    // since both receive string type value such as "2g" from server-side
    const memory = parseFloat((resources.mem as string) || "0");
    const sharedMemory = parseFloat(
      (task?.resource_opts?.shmem as string) ?? "0"
    );
    const resourceSlot: ResourceSlot | undefined = _(resourceSlots).find(
      (slot) => Object.hasOwnProperty.call(resources, slot)
    );
    const formValueResources: {
      cpu: number;
      memory: number;
      [key: ResourceSlot]: number;
    } = {
      cpu: cpu,
      memory: memory,
    };
    if (resourceSlot !== undefined) {
      formValueResources[resourceSlot] = resources[resourceSlot];
    }

    const initialValues: Partial<PipelineTaskFormInput> = {
      projectResourceGroup: parseTaskLevelProjectResourceGroup(
        flowDataFromYaml.rawPipelineYml,
        task
      ),
      name,
      description: task?.description ?? "",
      skip: task.skip ?? false,
      type,
      clusterMode: task?.cluster_mode || "single-node",
      clusterSize: task?.cluster_size || 1,
      envs: _.isEmpty(envs) ? undefined : envs,
      environments: imageInfo.subGroupName,
      version: getImageOptionKey(imageInfo.image),
      image,
      slots: formValueResources,
      sharedMemory,
      acceleratorType: resourceSlot,
      command: task?.command,
      vFolders: _.map(task?.mounts, (mount) => {
        const [vFolderName, alias, mountOptions] = mount.split(
          DEFAULT_VFOLDER_ALIAS_SEPARATOR
        );
        const found = _.find(folderList, (f) => f.name === vFolderName);
        return {
          id: found?.id,
          name: vFolderName,
          alias: alias,
        };
      }),
    };

    return initialValues;
  };

  const [current, send] = useMachine(pipelineTaskEditor, {
    actions: {
      // eslint-disable-next-line
      // @ts-ignore
      addTempNode: (context: PipelineTaskEditorContext, { nodeType, x, y }) => {
        const position = reactFlowInstance.screenToFlowPosition({
          x: x,
          y: y,
        });
        const newNode: Node<TaskNodeData> = {
          id: getId(),
          type: nodeType,
          position: {
            // offset the node position to the center of the cursor
            x: position.x - 75,
            y: position.y - 25,
          },
          selected: true, // selection is required to open drawer
          data: {
            label: "New Node",
            task: {
              name: "",
              type: "Custom Task",
              cluster_mode: "single-node",
              cluster_size: 1,
              dependencies: [],
              ...defaultSessionConfiguration,
              environment: {
                ...defaultSessionConfiguration.environment,
                envs: {},
              },
            },
          },
          style: {
            borderStyle: "dashed",
            opacity: 0.8,
            color: "lightgray",
          },
        };

        setNodes((nodes) =>
          _.map(nodes, (node) => {
            // when new node is added, deselect all other nodes
            node.selected = false;
            return node;
          }).concat(newNode)
        );
      },
      removeTempNode: () => {
        setNodes(
          (ns) =>
            _.chain(ns)
              .filter(
                // if current selected node is not in the yaml task list, then filter it out
                (n) =>
                  !!_.find(
                    flowDataFromYaml.dataflow?.nodes,
                    (t) => t.id === n.id
                  )
              )
              .map((n) => {
                n.selected = false;
                return n;
              })
              .value()
          // })
        );
      },
      resetForm(context: PipelineTaskEditorContext, event, meta) {
        // eslint-disable-next-line
        // @ts-ignore
        if (event.type !== "xstate.init") {
          form.resetFields();
        }
      },
      saveFormValue(context: PipelineTaskEditorContext, event, meta) {
        // eslint-disable-next-line
        // @ts-ignore
        const { values } = event;
        const resourceSlot: ResourceSlot | undefined = resourceSlots.find(
          (resourceSlot) =>
            Object.hasOwnProperty.call(values.slots, resourceSlot)
        );
        const formValueResources: PipelineTaskResources = {
          cpu: values.slots.cpu,
          mem: values.slots.memory + "g",
        };
        if (resourceSlot !== undefined) {
          formValueResources[resourceSlot] = values.slots[resourceSlot];
        }

        const selectedResourceSetting: Partial<PipelineYAML> = {
          environment: {
            project:
              values.projectResourceGroup && values.projectResourceGroup[0],
            "scaling-group":
              values.projectResourceGroup && values.projectResourceGroup[1],
            image: values.version?.split("@")[0],
            envs: getEnvironmentVariableMap(values.envs),
          },
          resources: formValueResources,
          resource_opts: {
            shmem: values.sharedMemory?.toString() + "g",
          },
          mounts: _.filter(
            values.vFolders,
            (folder) => folder.name !== dedicatedFolderName
          ).map((folder) => {
            return _.includes(
              [folder.name, DEFAULT_VFOLDER_ALIAS_PATH],
              folder.alias
            )
              ? folder.name
              : [folder.name, folder.alias].join(
                  DEFAULT_VFOLDER_ALIAS_SEPARATOR
                );
          }),
        };

        const changedTask: PipelineTask = {
          name: values.name,
          description: values.description,
          skip: values.skip,
          type: values.type,
          cluster_mode: values.clusterMode,
          cluster_size: values.clusterSize,
          module_uri: "", // FIXME: empty value,
          command: values.command,
          dependencies: [],
          ...selectedResourceSetting,
        };

        setNodes((ns) =>
          _.map(ns, (node) => {
            return node.id === values.id
              ? {
                  ...node,
                  selected: false,
                  data: {
                    ...node.data,
                    label: changedTask.name,
                    task: changedTask,
                  },
                  style: {
                    borderStyle: values.skip ? "dashed" : "solid",
                  },
                }
              : {
                  ...node,
                  selected: false,
                };
          })
        );

        if (form.getFieldValue("setDefault")) {
          setDefaultSessionConfiguration({
            ...selectedResourceSetting,
            environment: {
              ...selectedResourceSetting.environment,
              envs: flowDataFromYaml.rawPipelineYml.environment?.envs || {},
            },
          });
        }
        tryUpdateMutationCommitKey();
      },
      setFieldsUsingSelectedNode() {
        if (selectedTaskNode?.data.task) {
          const values = task2FieldValues(selectedTaskNode?.data.task);
          const dedicatedFolder = _.find(
            folderList,
            (folder) => folder.name === dedicatedFolderName
          );
          const initialValue = dedicatedFolder
            ? [{ id: dedicatedFolder.id, name: dedicatedFolder.name }]
            : [];
          form.setFieldsValue({
            id: selectedTaskNode?.id,
            ...values,
            vFolders: !_.isEmpty(values.vFolders)
              ? values.vFolders
              : initialValue,
          });

          // call submit method to show the error message when the required field is missing
          if (
            nodeValidationErrorMap[
              selectedTaskNode.data.task.name || "NOT_FOUND"
            ]
          ) {
            setTimeout(() => form.submit(), 200);
          }
        }
      },
      saveNodePositions(context: PipelineTaskEditorContext, event, meta) {
        tryUpdateMutationCommitKey();
      },
      deleteNode(context: PipelineTaskEditorContext, event, meta) {
        // eslint-disable-next-line
        // @ts-ignore
        const targetNode = event.node;
        if (targetNode) {
          setNodes((nodes) =>
            _.filter(nodes, (node) => node.id !== targetNode.id)
          );
          setEdges((edges) =>
            _.filter(edges, (edge) => {
              return (
                edge.source !== targetNode.id && edge.target !== targetNode.id
              );
            })
          );
        }
        tryUpdateMutationCommitKey();
      },
      duplicateNode(context: PipelineTaskEditorContext, event, meta) {
        // eslint-disable-next-line
        // @ts-ignore
        const targetNode = event.node;
        if (targetNode === undefined) {
          return;
        }
        const postfix = generateRandomString(4);
        setNodes((nodes) => [
          ...nodes,
          {
            ...targetNode,
            id: getId(),
            data: {
              label: `${targetNode.data.label}-${postfix}`,
              task: {
                ...targetNode.data.task,
                name: `${targetNode.data.task?.name}-${postfix}`,
              },
            },
            position: {
              x:
                targetNode.position.x +
                Math.round((targetNode.width ?? 0) * 0.33),
              y:
                targetNode.position.y +
                Math.round((targetNode.height ?? 0) * 0.66),
            },
          },
        ]);
        tryUpdateMutationCommitKey();
      },
    },
    devTools: !!process.env.REACT_APP_XSTATE_INSPECTOR,
  });

  const [form] = Form.useForm<PipelineTaskFormInput>();
  const [formForValidation] = Form.useForm<PipelineTaskFormInput>();
  const [invalidNamesForValidation, setInvalidNamesForValidation] = useState<
    string[]
  >([]);

  const [selectedPipelineVersionId, setSelectedPipelineVersionId] =
    useQueryParam("pipelineVersionId", StringParam);

  const pipeline = useFragment(
    graphql`
      fragment PipelineYamlEditorFragment on Pipeline {
        id
        yaml
        name
        lastModified
        dataflow
        storage
        ...PipelineTaskForm_pipeline
        ...ResourcesInputItems_pipeline
        ...VirtualFolderMountFormList_pipeline
      }
    `,
    pipelineFrgmt || null
  );

  const pipelineJob = useFragment(
    graphql`
      fragment PipelineYamlEditorJobFragment on PipelineJob {
        id
        name
        yaml
        dataflow
        taskinstanceSet {
          edges {
            node {
              id
              status
              config
            }
          }
        }
      }
    `,
    pipelineJobFrgmt || null
  );

  const pipelineVersionNode = useLazyLoadQuery<PipelineYamlEditorQuery>(
    graphql`
      query PipelineYamlEditorQuery($id: ID!, $skipVersion: Boolean!) {
        pipelineVersion(id: $id) @skip(if: $skipVersion) {
          id
          yaml
          dataflow
        }
      }
    `,
    {
      id: selectedPipelineVersionId || "",
      skipVersion: selectedPipelineVersionId === undefined,
    },
    {
      fetchPolicy: "store-and-network",
    }
  );

  const pipelineVersion =
    pipelineVersionNode.pipelineVersion ||
    (pipelineJob
      ? {
          id: pipelineJob.id,
          yaml: pipelineJob.yaml,
          dataflow: pipelineJob.dataflow,
        }
      : {
          id: "",
          yaml: "",
          dataflow: "",
        });

  const dedicatedFolderName = pipeline?.storage
    ? JSON.parse(pipeline.storage).name
    : undefined;
  const isReadOnlyMode = pipeline
    ? selectedPipelineVersionId !== undefined
    : true;
  const yamlStr = pipelineVersion?.yaml || pipeline?.yaml;
  const dataflowStr = pipelineVersion?.dataflow || pipeline?.dataflow;

  // Only for pipelineJob
  const taskStatusMap: {
    [key: string]: string;
  } = useMemo(
    () =>
      _.reduce(
        pipelineJob?.taskinstanceSet.edges,
        (result, e) => {
          const name = e?.node?.config
            ? JSON.parse(e.node.config).name
            : undefined;
          return name
            ? {
                ...result,
                [name]: e?.node?.status,
              }
            : result;
        },
        {}
      ),
    [pipelineJob?.taskinstanceSet.edges]
  );
  const [nodeValidationErrorMap, setNodeValidationErrorMap] =
    useState<NodeValidationErrorMap>({});

  // Node and Edge
  const flowDataFromYaml = useMemo(() => {
    const rawPipelineYml = (YAML.load(yamlStr || "") as PipelineYAML) || {};
    let dataflowCandidate = JSON.parse(dataflowStr || "{}") as DataFlow;

    // restore position of nodes
    let nodesCandidate: PartialBy<Node<TaskNodeData>, "position">[] = _.map(
      rawPipelineYml.tasks,
      (task) => {
        const sameNameNodes = _.filter(
          dataflowCandidate?.nodes,
          (node) => node.data.task?.name === task.name
        );
        // if there are multiple nodes with the same name, ignore the matched data. because it is not valid
        const matchedData =
          sameNameNodes.length === 1 ? sameNameNodes[0] : undefined;

        const borderStyleMap = {
          borderStyle: task.skip ? "dashed" : "solid",
        };

        return {
          id: matchedData?.id || getId(),
          data: {
            label: task.name,
            task,
          },
          position: matchedData?.position,
          style: !_.isEmpty(taskStatusMap)
            ? {
                backgroundColor: getStatusBgColor(
                  taskStatusMap[task.name || ""]
                ),
                color: getStatusTextColor(taskStatusMap[task.name || ""]),
                borderColor: getStatusTextColor(taskStatusMap[task.name || ""]),
                animation:
                  taskStatusMap[task.name || ""] === "RUNNING"
                    ? `blink 2s infinite`
                    : ``,
                ...borderStyleMap,
              }
            : {
                ...borderStyleMap,
              },
        };
      }
    );

    const edgesCandidate: Edge[] = _.flatMap(nodesCandidate, ({ data }) => {
      const task = data.task;
      return _.map(task?.dependencies, (dependency, idx) => {
        return {
          id: getId(),
          source: _.find(
            nodesCandidate,
            (node) => node.data.label === dependency
          )?.id,
          target: _.find(
            nodesCandidate,
            (node) => node.data.label === task?.name
          )?.id,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 16,
            height: 16,
          },
          animated: taskStatusMap[dependency || ""] === "RUNNING",
          style: { strokeWidth: 1.5 },
          label: idx + 1 + "",
          type: "labelPosition",
        } as Edge;
      });
    });

    // if not matched with dataflow, then use auto layout
    if (!_.every(nodesCandidate, (node) => node.position)) {
      nodesCandidate = applyAutoLayout(nodesCandidate, edgesCandidate);
      dataflowCandidate = {
        nodes: nodesCandidate as Node<TaskNodeData>[],
        edges: edgesCandidate,
        viewport: {
          x: 0,
          y: 0,
          zoom: 1,
        },
      };
    }

    return {
      nodes: nodesCandidate as Node<TaskNodeData>[],
      edges: edgesCandidate,
      dataflow: dataflowCandidate,
      rawPipelineYml,
    };
  }, [yamlStr, dataflowStr, taskStatusMap]);

  const latestValidationId = useRef<string>();
  latestValidationId.current = `${pipeline?.id}-${selectedPipelineVersionId}`;

  // Validation effect
  useEffect(() => {
    let isMounted = true;
    const timerIds: ReturnType<typeof setTimeout>[] = [];
    onChangeNodeValidation?.({});
    setNodeValidationErrorMap({});
    const currentValidationId = latestValidationId.current;

    // from here, we will validate the pipeline on promise chain
    _.reduce(
      flowDataFromYaml.nodes,
      async (prevPromise, curr) => {
        return prevPromise.then((prevResult) => {
          // stop validation if the validation id is changed
          if (currentValidationId !== latestValidationId.current)
            return prevResult;
          if (curr.data.task && isMounted) {
            formForValidation.setFieldsValue(task2FieldValues(curr.data.task));
            return new Promise((resolve, reject) => {
              timerIds.push(
                setTimeout(async () => {
                  try {
                    const result = await formForValidation
                      .validateFields()
                      .then(() => prevResult)
                      .catch((e) => {
                        return !_.isEmpty(e.errorFields)
                          ? {
                              ...prevResult,
                              [curr.data.task?.name || ""]: e.errorFields,
                            }
                          : prevResult;
                      });
                    resolve(result);
                  } catch {
                    reject();
                  }
                }, 200)
              );
              _.defer(() => {});
            });
          } else {
            return prevResult;
          }
        });
      },
      Promise.resolve({})
    )
      .then((r: NodeValidationErrorMap) => {
        if (isMounted) {
          // stop validation if the validation id is changed
          if (currentValidationId !== latestValidationId.current) return;

          const allNames = flowDataFromYaml.nodes.map((n) => n.data.task?.name);
          const duplicatedNames = _.chain(allNames)
            .groupBy((name) => name)
            .pickBy((values) => values.length > 1)
            .mapValues((arr, key) => ({
              name: ["name"],
              errors: ["Duplicated name"],
            }))
            .value();

          _.each(duplicatedNames, (v, k) => {
            r[k] = r[k] || [];
            r[k].push(v);
          });

          onChangeNodeValidation?.(r);
          setNodeValidationErrorMap(r);
        }
      })
      .catch((e) => {
        // console.log(e);
      });

    return () => {
      isMounted = false;
      _.each(timerIds, (id) => clearTimeout(id));
    };
  }, [yamlStr, selectedPipelineVersionId]);

  const [nodes, setNodes, _onNodesChange] = useNodesState<{
    task?: PipelineTask;
    label?: string;
  }>(flowDataFromYaml.nodes);

  const [edges, setEdges, _onEdgesChange] = useEdgesState(
    flowDataFromYaml.edges
  );

  const selectedTaskNode = useMemo(() => {
    return nodes.find((node) => node.selected);
  }, [nodes]);

  useUpdateEffect(() => {
    setNodes(flowDataFromYaml.nodes);
    setEdges(flowDataFromYaml.edges);

    if (flowDataFromYaml?.dataflow.viewport) {
      reactFlowInstance.setViewport(flowDataFromYaml?.dataflow.viewport);
    } else {
      setTimeout(() => {
        reactFlowInstance.fitView({
          maxZoom: 1,
        });
      }, 100);
    }

    setDefaultSessionConfiguration({
      environment: flowDataFromYaml.rawPipelineYml.environment,
      resource_opts: flowDataFromYaml.rawPipelineYml.resource_opts,
      resources: flowDataFromYaml.rawPipelineYml.resources,
      mounts: flowDataFromYaml.rawPipelineYml.mounts,
    });
  }, [pipeline?.id, pipeline?.yaml, pipelineVersion?.id]);

  useUpdateEffect(() => {
    setNodes(flowDataFromYaml.nodes);
    setEdges(flowDataFromYaml.edges);
  }, [taskStatusMap]);

  const onNodeChange: OnNodesChange = (changes) => {
    if (isReadOnlyMode) {
      _onNodesChange(
        _.filter(
          changes,
          // Potential types are {"dimensions", "position", "select", "remove", "add", "reset"}.
          // ref: https://reactflow.dev/api-reference/types/node-change
          (c) => !_.includes(["add", "remove", "position"], c.type)
        )
      );
    } else {
      if (_.find(changes, (c) => c.type === "remove")) {
        tryUpdateMutationCommitKey();
      }
      _onNodesChange(changes);
    }
  };

  const onEdgesChange: OnEdgesChange = useEventNotStable((changes) => {
    _onEdgesChange(changes);
    tryUpdateMutationCommitKey();
  });

  const onConnect = useEventNotStable((params: any) => {
    //TODO: use this lib incremental way to detect cycle
    const graph = GenericGraphAdapter.create();
    _.each(edges, (e) => {
      graph.addEdge(e.source, e.target);
    });

    if (
      _.find(edges, (e) => {
        return e.source === params.source && e.target === params.target;
      }) !== undefined
    ) {
      notification.error({
        message: "Duplicate edge",
        description: "Cannot make a duplicate edge",
      });
    } else if (!graph.addEdge(params.source, params.target)) {
      notification.error({
        message: "Cycle detected",
        description: "Cannot make a edge that will cause cycle",
      });
    } else {
      setEdges((edges) =>
        addEdge(
          { ...params, markerEnd: { type: MarkerType.ArrowClosed } },
          edges
        )
      );
      tryUpdateMutationCommitKey();
    }
  });

  const onDragOver = useCallback(
    (event: {
      preventDefault: () => void;
      dataTransfer: { dropEffect: string };
    }) => {
      event.preventDefault();
      event.dataTransfer.dropEffect = "move";
    },
    []
  );

  const onDrop: DragEventHandler = (event) => {
    event.preventDefault();
    if (reactFlowWrapper.current) {
      const nodeType = event.dataTransfer.getData(
        "application/reactflow"
      ) as string;

      // check if the dropped element is valid
      if (typeof nodeType === "undefined" || !nodeType) {
        return;
      }

      send({
        type: "DROP_NEW_NODE",
        x: event.clientX,
        y: event.clientY,
        nodeType,
      });
    }
  };

  const [commitUpdate, isInFlightCommitUpdate] =
    useMutation<PipelineYamlEditorUpdateMutation>(graphql`
      mutation PipelineYamlEditorUpdateMutation($input: UpdatePipelineInput!) {
        updatePipeline(input: $input) {
          pipeline {
            __typename
            ... on Pipeline {
              id
              name
              description
              lastModified
              yaml
              dataflow
            }
            ... on UnauthenticatedError {
              message
            }
          }
        }
      }
    `);

  const defaultViewPortProps: Partial<ReactFlowProps> =
    isReadOnlyMode ||
    /*isPreview ||*/ _.isUndefined(flowDataFromYaml.dataflow.viewport)
      ? {
          fitView: true,
        }
      : {
          defaultViewport: {
            zoom: flowDataFromYaml.dataflow.viewport?.zoom || 1,
            x: flowDataFromYaml.dataflow.viewport?.x || 0,
            y: flowDataFromYaml.dataflow.viewport?.y || 0,
          },
        };

  const [mutationCommitKey, setMutationCommitKey] = useState(0);
  const [defaultSessionConfiguration, setDefaultSessionConfiguration] =
    useState<
      Pick<
        PipelineYAML,
        "environment" | "resources" | "resource_opts" | "mounts"
      >
    >({
      environment: {
        ...flowDataFromYaml.rawPipelineYml.environment,
        envs: {},
      },
      resources: flowDataFromYaml.rawPipelineYml.resources,
      resource_opts: flowDataFromYaml.rawPipelineYml.resource_opts,
      mounts: flowDataFromYaml.rawPipelineYml.mounts,
    });
  const tryUpdateMutationCommitKey = () => {
    if (!isReadOnlyMode) {
      setMutationCommitKey((v) => v + 1);
    }
  };

  // sync from react flow to yaml(commit graphql mutation)
  useUpdateEffect(() => {
    if (pipeline?.id) {
      const tasks: PipelineTask[] = parseFlowToPipelineTasks(nodes, edges).map(
        (task) => {
          const resources = task.resources || {};
          if (Object.hasOwnProperty.call(resources, "cuda.shares")) {
            task.resources["cuda.shares"] = (
              task.resources["cuda.shares"] || "0"
            ).toString();
          } else if (Object.hasOwnProperty.call(resources, "cuda.device")) {
            task.resources["cuda.device"] = (
              task.resources["cuda.device"] || "0"
            ).toString();
          }
          return task;
        }
      );

      const yaml: PipelineYAML = {
        ...flowDataFromYaml.rawPipelineYml,
        ..._.pickBy(defaultSessionConfiguration, (v) => v),
        ownership: {
          scope: flowDataFromYaml.rawPipelineYml.ownership?.scope ?? "personal",
          ...flowDataFromYaml.rawPipelineYml.ownership,
          domain_name: user?.domain_name,
        },
        name: pipeline?.name ?? "",
        tasks: tasks,
      };

      // use only id, task name & position
      const nodePositions = _.map(nodes, (node) => {
        return {
          id: node.id,
          data: {
            task: {
              name: node?.data?.task?.name,
            },
          },
          position: node.position,
        };
      });

      commitUpdate({
        variables: {
          input: {
            id: pipeline?.id,
            dataflow: {
              nodes: nodePositions,
              viewport: reactFlowInstance.getViewport(),
            },
            // remove undefined fields using JSON.stringify
            yaml: YAML.dump(JSON.parse(JSON.stringify(yaml)), { noRefs: true }),
          },
        },
        onCompleted(response, errors) {
          switch (response.updatePipeline?.pipeline?.__typename) {
            case "Pipeline":
              break;
            case "UnauthenticatedError":
              message.error(response.updatePipeline.pipeline.message);
              break;
          }
        },
        onError(error) {
          message.error("Failed to update pipeline.");
          send("UPDATE_FAIL");
        },
      });
    }
  }, [mutationCommitKey]);

  const requestCloseDrawerForm = () => {
    // check form is dirty
    if (current.matches("EditingNode.changed")) {
      // if dirty, show confirm dialog
      Modal.confirm({
        title: "Discard changes?",
        content: "You have unsaved changes. Are you sure to discard them?",
        onOk: () => {
          send("CANCEL");
        },
        okButtonProps: {
          danger: true,
          ghost: true,
        },
        okText: "Discard",
      });
    } else {
      // if not dirty, close drawer
      send("CANCEL");
    }
  };

  const nodeTypes = useMemo(
    () => ({
      default: TaskNode,
    }),
    []
  );

  return (
    <div ref={reactFlowWrapper} style={{ width: "100%", height: "100%" }}>
      <ReactFlow
        proOptions={{ hideAttribution: true }}
        onNodeDragStart={() => {
          send("NODE_DRAG_START");
        }}
        onNodeDrag={() => {
          send("NODE_DRAG");
        }}
        onNodeDragStop={() => {
          send("NODE_DRAG_STOP");
        }}
        onNodesDelete={() => {
          send("DELETE_NODE");
        }}
        nodesConnectable={!isReadOnlyMode}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodes={_.map(nodes, (n) => ({
          ...n,
          data: {
            ...n.data,
            isMissingRequired:
              nodeValidationErrorMap[n.data.task?.name || "NOT_EXISTED"],
            onDelete: () => {
              send({
                type: "DELETE_NODE",
                node: n,
              });
            },
            onDuplicate: () => {
              send({
                type: "DUPLICATE_NODE",
                node: n,
              });
            },
            isEditable: !isReadOnlyMode,
          },
        }))}
        edges={edges}
        {...defaultViewPortProps}
        fitViewOptions={{
          maxZoom: 1,
        }}
        onNodesChange={onNodeChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onInit={(reactFlowInstance) => {
          //WARN: defaultZoom and defaultPosition are not working. so set viewport manually after init
          // readOnlyMode should use auto fit view only.
          !isReadOnlyMode &&
            flowDataFromYaml.dataflow.viewport &&
            reactFlowInstance.setViewport(flowDataFromYaml.dataflow.viewport);
        }}
        onDrop={onDrop}
        onDragOver={onDragOver}
        snapToGrid={false}
        attributionPosition="bottom-right"
        multiSelectionKeyCode={null}
      >
        <MiniMap pannable zoomable />
        <Controls showInteractive={!isReadOnlyMode}>
          <Tooltip title="Auto layout" placement="right">
            <ControlButton
              onClick={() => {
                setNodes(applyAutoLayout(nodes, edges));
                setTimeout(() => {
                  reactFlowInstance.fitView({
                    maxZoom: 1,
                    padding: 0.1,
                  });
                }, 100);
              }}
            >
              <LayoutOutlined />
            </ControlButton>
          </Tooltip>
          {isReadOnlyMode ? null : (
            <Tooltip
              title={`${dayjs(pipeline?.lastModified).fromNow()} saved`}
              placement="right"
            >
              <ControlButton>
                {isInFlightCommitUpdate ? (
                  <SyncOutlined spin />
                ) : (
                  <CheckOutlined />
                )}
              </ControlButton>
            </Tooltip>
          )}
        </Controls>
        <Background />
      </ReactFlow>
      <Drawer
        key={key}
        title={
          <Flex direction="row" justify="between">
            Edit Task
            <Space direction="horizontal">
              <Switch
                size="default"
                defaultChecked={form.getFieldValue("skip")}
                onChange={(checked) => {
                  form.setFieldValue("skip", checked);
                }}
              />
              <Text style={{ fontWeight: "lighter" }}>Skip</Text>
              <Tooltip
                title={`This task will${
                  form.getFieldValue("skip") ? " " : " not "
                }be skipped.`}
                placement="right"
              >
                <QuestionCircleOutlined style={{ color: "#00000073" }} />
              </Tooltip>
            </Space>
          </Flex>
        }
        // NOTE: According to the documentation, the `Tour` component is expected to have a zIndex value of 1001.
        //       However, upon inspection, we discovered that the actual zIndex is set to 1030.
        //       To ensure proper layering, the drawer's zIndex has been adjusted to 1031,
        //       which is one unit higher than that of the Tour component.
        // - https://ant.design/components/tour#tour
        zIndex={1031}
        open={current.matches("EditingNode")}
        onClose={() => {
          requestCloseDrawerForm();
        }}
        destroyOnClose
        mask={true}
        width={512}
        footer={
          <Flex justify="between" direction="row">
            <TaskNodeDeletePopconfirm
              onConfirm={() => {
                if (selectedTaskNode) {
                  send({
                    type: "DELETE_NODE",
                    node: selectedTaskNode,
                  });
                }
              }}
            >
              <Button danger type="link" disabled={isReadOnlyMode}>
                Delete
              </Button>
            </TaskNodeDeletePopconfirm>
            <Space>
              <Button
                onClick={() => {
                  requestCloseDrawerForm();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                loading={isInFlightCommitUpdate}
                onClick={() => {
                  form.submit();
                }}
                disabled={isReadOnlyMode}
              >
                Save
              </Button>
            </Space>
          </Flex>
        }
      >
        <Suspense fallback={<FlexActivityIndicator />}>
          <Form.Provider
            onFormChange={(name, { changedFields }) => {
              if (!_.every(changedFields, (f) => !f.touched)) {
                send("CHANGE");
              }
            }}
          >
            <PipelineTaskForm
              form={form}
              pipelineFrgmt={pipeline}
              invalidNames={nodes
                .filter((n) => n !== selectedTaskNode)
                .map((n) => n.data.task?.name || "")}
              // taskNameFilter={(s) => selectedTaskNode?.data.task?.name !== s}
              resourceSlots={resourceSlots}
              onFinish={(values: PipelineTaskFormInput) => {
                send({
                  type: "SAVE",
                  values: values,
                });
              }}
              disabled={isReadOnlyMode}
            />
          </Form.Provider>
        </Suspense>
      </Drawer>

      {/* Only for the validation */}
      <PipelineTaskForm
        hidden={true}
        form={formForValidation}
        pipelineFrgmt={pipeline}
        invalidNames={invalidNamesForValidation}
        disabled={isReadOnlyMode}
      />
    </div>
  );
};

export default PipelineYamlEditor;

interface PipelineTaskEnvironment {
  image?: string;
  envs?: EnvVar;
  "scaling-group"?: string;
  project?: string;
}

type PipelineTaskResources = {
  cpu?: number;
  mem?: number | string;
  [key: ResourceSlot]: number;
};

export interface PipelineYAML {
  version?: string;
  name?: string;
  description?: string;
  ownership?: {
    domain_name?: string;
    scope: "personal" | "project";
    project?: string;
    "scaling-group"?: string;
  };
  environment?: PipelineTaskEnvironment;
  resources?: PipelineTaskResources;
  resource_opts?: {
    shmem?: string;
  };
  mounts?: string[];
  tasks?: PipelineTask[];
}

type PipelineTask = {
  name?: string;
  description?: string;
  skip?: boolean;
  type?: string;
  cluster_mode: ClusterMode;
  cluster_size: number;
  environment?: PipelineTaskEnvironment;
  resources?: PipelineTaskResources;
  resource_opts?: {
    shmem?: string;
  };
  mounts?: Array<string>;
  module_uri?: string;
  dependencies?: Array<string>;
  command?: string;
  label?: string;
};

export type TaskNodeData = {
  label?: string;
  task?: PipelineTask;
  isMissingRequired?: boolean;
  onDelete?: () => void;
  onDuplicate?: () => void;
  isEditable?: boolean;
};

interface DataFlow {
  nodes: Array<Node<TaskNodeData>>;
  edges: Array<Edge>;
  viewport: Viewport;
}

function parseFlowToPipelineTasks(nodes: Array<Node>, edges: Array<Edge>) {
  // reset all dependencies in node
  nodes.forEach((node) => {
    node.data.task.dependencies = [];
  });
  edges.forEach((edge) => {
    const sourceNode = nodes.find((node) => node.id === edge.source);
    nodes[
      nodes.findIndex((node) => node.id === edge.target)
    ]?.data.task.dependencies.push(sourceNode?.data.task?.name);
  });
  return nodes.map((node) => node.data.task);
}

const getId = () => uuidv4();

const removeItemOnce = (arr: any[], val: any) => {
  const index = arr.indexOf(val);
  if (index > -1) {
    arr.splice(index, 1);
  }
  return arr;
};

export function parseTaskLevelProjectResourceGroup(
  pipeline: PipelineYAML | undefined,
  task: PipelineTask | undefined
): string[] | undefined {
  return pipeline?.ownership?.scope === "project"
    ? pipeline.ownership.project && task?.environment?.["scaling-group"]
      ? [pipeline.ownership.project, task.environment["scaling-group"]]
      : undefined
    : task?.environment?.project && task.environment["scaling-group"]
      ? [task.environment.project, task.environment["scaling-group"]]
      : undefined;
}
