import { Button } from "antd";
import { ButtonProps } from "antd/lib";
import graphql from "babel-plugin-relay/macro";
import { LucideArchive } from "lucide-react";
import React from "react";
import { useFragment, useMutation } from "react-relay";

import { PipelineJobArchiveButtonMutation } from "./__generated__/PipelineJobArchiveButtonMutation.graphql";
import { PipelineJobArchiveButton_pipelineJob$key } from "./__generated__/PipelineJobArchiveButton_pipelineJob.graphql";

interface Props extends ButtonProps {
  pipelineJobFragment: PipelineJobArchiveButton_pipelineJob$key | null;
  onCompleted?: () => void;
}

const PipelineJobArchiveButton: React.FC<Props> = ({
  pipelineJobFragment,
  onCompleted,
  ...props
}) => {
  const pipelineJob = useFragment(
    graphql`
      fragment PipelineJobArchiveButton_pipelineJob on PipelineJob {
        id
      }
    `,
    pipelineJobFragment
  );
  const [commitArchive, isInFlightCommitArchive] =
    useMutation<PipelineJobArchiveButtonMutation>(graphql`
      mutation PipelineJobArchiveButtonMutation(
        $input: ArchivePipelineJobInput!
      ) {
        archivePipelineJob(input: $input) {
          pipelineJob {
            __typename
            ... on PipelineJob {
              id
            }
            ... on UnauthenticatedError {
              message
            }
            ... on ActivePipelineJobError {
              message
            }
          }
        }
      }
    `);
  const tryArchivePipelineJob = () => {
    if (pipelineJob === null) {
      return;
    }
    commitArchive({
      variables: {
        input: {
          id: pipelineJob.id,
        },
      },
      onCompleted(response, errors) {
        onCompleted?.();
      },
    });
  };
  return (
    <Button
      type="text"
      icon={<LucideArchive />}
      style={{ color: "#454545" }}
      size="small"
      disabled={isInFlightCommitArchive}
      loading={isInFlightCommitArchive}
      onClick={tryArchivePipelineJob}
      {...props}
    >
      Archive
    </Button>
  );
};

export default PipelineJobArchiveButton;
