/**
 * @generated SignedSource<<a2e9aec4a8303b57b8c8751f6bc48821>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PipelineJobStatusChoices = "CANCELLED" | "CANCELLING" | "ERROR" | "PAUSED" | "PENDING" | "RUNNING" | "TERMINATED" | "WAITING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PipelineJobStatusTag_pipelineJob$data = {
  readonly status: PipelineJobStatusChoices;
  readonly terminatedBy: {
    readonly isSuperuser: boolean;
  } | null;
  readonly " $fragmentType": "PipelineJobStatusTag_pipelineJob";
};
export type PipelineJobStatusTag_pipelineJob$key = {
  readonly " $data"?: PipelineJobStatusTag_pipelineJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineJobStatusTag_pipelineJob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineJobStatusTag_pipelineJob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "terminatedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isSuperuser",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PipelineJob",
  "abstractKey": null
};

(node as any).hash = "afb50dcbae66b1bfae09f01153a11497";

export default node;
