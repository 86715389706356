/**
 * @generated SignedSource<<7aaa1ec2054fe7cc9fef4c8c8c99933e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskInstanceStatusChoices = "BUILDING" | "CANCELLED" | "ERROR" | "PENDING" | "PREPARING" | "PULLING" | "RESIZING" | "RESTARTING" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "SUSPENDED" | "TERMINATED" | "TERMINATING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PipelineYamlEditorJobFragment$data = {
  readonly dataflow: any | null;
  readonly id: string;
  readonly name: string;
  readonly taskinstanceSet: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly config: any;
        readonly id: string;
        readonly status: TaskInstanceStatusChoices;
      } | null;
    } | null>;
  };
  readonly yaml: string;
  readonly " $fragmentType": "PipelineYamlEditorJobFragment";
};
export type PipelineYamlEditorJobFragment$key = {
  readonly " $data"?: PipelineYamlEditorJobFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineYamlEditorJobFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineYamlEditorJobFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yaml",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataflow",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskInstanceConnection",
      "kind": "LinkedField",
      "name": "taskinstanceSet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskInstanceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskInstance",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "status",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "config",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PipelineJob",
  "abstractKey": null
};
})();

(node as any).hash = "41efdaad1ffa40e66a6d3e44c67d0bdd";

export default node;
