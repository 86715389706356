import { OneToOneOutlined } from "@ant-design/icons";
import { Button, Modal, Typography } from "antd";
import { notification } from "antd/lib";
import graphql from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useAtomValue } from "jotai";
import _ from "lodash";
import * as monaco from "monaco-editor";
import { setDiagnosticsOptions } from "monaco-yaml";
import { useEffect, useRef, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { StringParam, useQueryParam } from "use-query-params";

import { from_global_id } from "../helpers";
import { pipelineSchemaVersionAtom } from "../hooks/atoms";
import CodeButtons from "./CodeButtons";
import Flex from "./Flex";
import WhiteSpace from "./WhiteSpace";
import { PipelineYamlCodeEditorFragment$key } from "./__generated__/PipelineYamlCodeEditorFragment.graphql";
import { PipelineYamlCodeEditorUpdatePipelineMutation } from "./__generated__/PipelineYamlCodeEditorUpdatePipelineMutation.graphql";

// eslint-disable-next-line
// @ts-ignore
window.MonacoEnvironment = {
  // eslint-disable-next-line
  // @ts-ignore
  getWorker(moduleId, label) {
    switch (label) {
      case "editorWorkerService":
        return new Worker(
          new URL("monaco-editor/esm/vs/editor/editor.worker", import.meta.url)
        );
      case "yaml":
        return new Worker(new URL("monaco-yaml/yaml.worker", import.meta.url));
      default:
        throw new Error(`Unknown label ${label}`);
    }
  },
};

// https://github.com/remcohaszing/monaco-yaml/issues/92#issuecomment-905836058
const modelUri = monaco.Uri.parse("a://b/foo.yaml");

const { Text } = Typography;

// loader.config({ monaco });
interface Props {
  onRequestClose: () => void;
  onSaveComplete?: (yaml: string) => void;
  pipelineFrgmt: PipelineYamlCodeEditorFragment$key | null;
}

const PipelineYamlCodeEditor: React.FC<Props> = ({
  onRequestClose,
  onSaveComplete,
  pipelineFrgmt,
}) => {
  const pipeline = useFragment(
    graphql`
      fragment PipelineYamlCodeEditorFragment on Pipeline {
        id
        yaml
        name
        lastModified
        dataflow
      }
    `,
    pipelineFrgmt || null
  );
  const [commitUpdate, isInFlightCommitUpdate] =
    useMutation<PipelineYamlCodeEditorUpdatePipelineMutation>(graphql`
      mutation PipelineYamlCodeEditorUpdatePipelineMutation(
        $input: UpdatePipelineInput!
      ) {
        updatePipeline(input: $input) {
          pipeline {
            __typename
            ... on Pipeline {
              lastModified
              yaml
            }
            ... on UnauthenticatedError {
              message
            }
          }
        }
      }
    `);

  // https://github.com/microsoft/monaco-editor/blob/main/samples/browser-esm-webpack-typescript-react/src/components/Editor.tsx
  const divEl = useRef<HTMLDivElement>(null);
  const editorRef = useRef<monaco.editor.IStandaloneCodeEditor>();

  const [selectedPipelineVersionId, setSelectedPipelineVersionId] =
    useQueryParam("pipelineVersionId", StringParam);
  const isReadOnly = selectedPipelineVersionId !== undefined;

  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const yamlSchemaVersion = useAtomValue(pipelineSchemaVersionAtom);
  useEffect(() => {
    setDiagnosticsOptions({
      completion: true,
      enableSchemaRequest: true,
      format: true,
      hover: true,
      schemas: [
        {
          // this is the schema id used in the yaml file
          uri:
            process.env.PUBLIC_URL +
            `/static/json-schema/pipeline-yaml.${yamlSchemaVersion}.json`,
          fileMatch: ["*"],
        },
      ],
      validate: true,
    });
  }, [yamlSchemaVersion]);

  useEffect(() => {
    setHasChanged(false);
    if (divEl.current) {
      editorRef.current = monaco.editor.create(divEl.current, {
        value: pipeline?.yaml || "",
        language: "yaml",
        fontSize: 15,
        automaticLayout: true,
        // model: monaco.editor.createModel("p1: \n", "yaml", modelUri),
        readOnly: isReadOnly,
      });

      editorRef.current.onDidChangeModelContent((event) => {
        const markers = monaco.editor.getModelMarkers({});
        // const content = monaco.editor.getModel().modified.getValue();
        // console.log(monaco.editor.getModel(modelUri)?.getValue());
        setHasChanged(true);
      });
    }
    return () => {
      editorRef.current?.dispose();
    };
  }, [pipeline?.id]);
  return (
    <Flex direction="column" align="stretch" style={{ height: "100%" }}>
      <Flex justify="between" style={{ padding: "8px 10px" }}>
        <Flex direction="column" align="start">
          <Flex>
            <OneToOneOutlined />
            <WhiteSpace direction="row" size="sm" />
            <Text strong>YAML Code Editor: {pipeline?.name}</Text>
            <WhiteSpace direction="row" />
            <CodeButtons
              buttonProps={{ size: "small", disabled: hasChanged }}
              types={["download", "copy"]}
              code={pipeline?.yaml || ""}
              language="yaml"
              filename={`${from_global_id(pipeline?.id || "")[1]}.yaml`}
              downloadText={"Download"}
            />
          </Flex>
          <Text type="secondary">
            Last saved: {dayjs(pipeline?.lastModified).format("lll")}
          </Text>
        </Flex>
        <Flex>
          <Button
            onClick={() => {
              if (hasChanged) {
                Modal.confirm({
                  title: "Discard changes?",
                  content:
                    "You have unsaved changes. Are you sure to discard them?",
                  onOk: () => {
                    onRequestClose();
                  },
                  okButtonProps: {
                    danger: true,
                    ghost: true,
                  },
                  okText: "Discard",
                });
              } else {
                onRequestClose();
              }
            }}
          >
            Close
          </Button>
          <WhiteSpace direction="row" />
          <Button
            loading={isInFlightCommitUpdate}
            disabled={isReadOnly || !hasChanged}
            onClick={() => {
              // eslint-disable-next-line
              //@ts-ignore
              const editedYaml = editorRef.current.getValue();
              editedYaml &&
                commitUpdate({
                  variables: {
                    input: {
                      id: pipeline?.id || "",
                      yaml: editedYaml,
                    },
                  },
                  onCompleted(response, errors) {
                    if (errors) {
                      errors.forEach((error) => {
                        const errorObj: {
                          message: string;
                          details: {
                            code: string;
                            description?: string;
                            additionalInfo?: object;
                            suggestions?: string[];
                          };
                        } = JSON.parse(error.message);
                        notification.error({
                          message: `UpdatePipeline mutation failed.`,
                          description: (
                            <Flex
                              direction="column"
                              align="start"
                              justify="center"
                            >
                              <Text>{errorObj.details.description}</Text>
                              <Text type="secondary">
                                {errorObj.details.code}
                              </Text>
                            </Flex>
                          ),
                          duration: 5,
                        });
                      });
                      return;
                    }
                    onSaveComplete?.(editedYaml);
                    setHasChanged(false);
                  },
                });
            }}
            type="primary"
          >
            Save
          </Button>
        </Flex>
      </Flex>
      <Flex style={{ flex: 1 }}>
        <div
          className="Editor"
          ref={divEl}
          style={{
            display: "flex",
            height: "100%",
            flex: 1,
          }}
        ></div>
      </Flex>
    </Flex>
  );
};

export default PipelineYamlCodeEditor;
