import { Typography } from "antd";
import { BlockProps } from "antd/lib/typography/Base";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";

import { CrontabViewFragment$key } from "./__generated__/CrontabViewFragment.graphql";

const { Text } = Typography;

interface Props extends BlockProps {
  crontabFrgmt: CrontabViewFragment$key | null;
  stringOnly?: boolean;
}
const CrontabView: React.FC<Props> = ({
  crontabFrgmt,
  stringOnly,
  ...props
}) => {
  const crontab = useFragment(
    graphql`
      fragment CrontabViewFragment on CrontabSchedule {
        id
        minute
        hour
        dayOfWeek
        dayOfMonth
        monthOfYear
      }
    `,
    crontabFrgmt
  );
  const str = [
    crontab?.minute,
    crontab?.hour,
    crontab?.dayOfMonth,
    crontab?.monthOfYear,
    crontab?.dayOfWeek,
  ].join(" ");
  return stringOnly ? <>{str}</> : <Text {...props}>{str}</Text>;
};

export default CrontabView;
