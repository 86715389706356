import {
  CheckOutlined,
  PlaySquareOutlined,
  ScheduleFilled,
  ScheduleOutlined,
} from "@ant-design/icons";
import { useUpdateEffect } from "ahooks";
import {
  Alert,
  Button,
  DatePicker,
  Descriptions,
  Divider,
  Form,
  Input,
  Modal,
  ModalProps,
  Space,
  Tabs,
  Typography,
  message,
  theme,
} from "antd";
import graphql from "babel-plugin-relay/macro";
import { getSchedule, stringToArray } from "cron-converter";
import dayjs from "dayjs";
import { default as YAML } from "js-yaml";
import _ from "lodash";
import React, { useState } from "react";
import Cron, { CronError } from "react-js-cron";
import "react-js-cron/dist/styles.css";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { darcula } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { from_global_id, numberMaxRule, numberMinRule } from "../helpers";
import { useMemoWhenToggledOn } from "../hooks";
import CodeButtons from "./CodeButtons";
import EnvironmentVariableList, {
  IEnvironmentVariable,
  KeyRules,
  getEnvironmentVariableMap,
} from "./EnvironmentVariableList";
import Flex from "./Flex";
import { PipelineYAML } from "./PipelineYamlEditor";
import WhiteSpace from "./WhiteSpace";
import { RunPipelineModalCancelScheduleMutation } from "./__generated__/RunPipelineModalCancelScheduleMutation.graphql";
import { RunPipelineModalFragment$key } from "./__generated__/RunPipelineModalFragment.graphql";
import { RunPipelineModalMutation } from "./__generated__/RunPipelineModalMutation.graphql";
import { RunPipelineModalSetScheduleMutation } from "./__generated__/RunPipelineModalSetScheduleMutation.graphql";

const { Text } = Typography;

interface CronEditorModalProps extends ModalProps {
  onRequestClose: (cron?: string) => void;
  pipelineFgmt: RunPipelineModalFragment$key | null;
  disableRun?: boolean;
}

const CRON_DEFAULT = "0 9 * * *";
const MIN_NUMBER_OF_TIMES = 1;
const MAX_NUMBER_OF_TIMES = parseInt(
  window.__RUNTIME_CONFIG__.FRONTEND_RUNTIME_JOB_SCHEDULE_MAX_NUMBER_OF_TIMES ||
    "3"
);
const DEFAULT_TIMEZONE = "Asia/Seoul";
const LOCAL_TIMEZONE = new Intl.DateTimeFormat().resolvedOptions().timeZone;

interface PipelineJobFormInput {
  name?: string;
  envs?: IEnvironmentVariable[];
  num_runs?: string;
  expires?: string;
}

const getScheduleTimesWithExpire = (
  cronValue: string,
  num_runs_string: string | undefined
) => {
  const times = _.toNumber(num_runs_string);
  if (times > 0) {
    try {
      const arr = stringToArray(cronValue);
      const schedule = getSchedule(
        arr,
        new Date(),
        LOCAL_TIMEZONE || DEFAULT_TIMEZONE
      );
      let lastRun;
      for (let i = 0; i < times; i++) {
        lastRun = schedule.next();
      }
      const expires = dayjs(lastRun).utc(false).add(1, "seconds").toISOString();
      return {
        last: lastRun,
        expires,
      };
    } catch (error) {
      return {};
    }
  }
  return {};
};
const RunPipelineModal: React.FC<CronEditorModalProps> = ({
  onRequestClose,
  pipelineFgmt,
  disableRun,
  ...props
}) => {
  const pipeline = useFragment(
    graphql`
      fragment RunPipelineModalFragment on Pipeline {
        id
        name
        yaml
        scheduledTask {
          crontab {
            minute
            hour
            dayOfMonth
            monthOfYear
            dayOfWeek
          }
          expires
        }
      }
    `,
    pipelineFgmt
  );

  const [currentMode, setCurrentMode] = useState<"run" | "schedule">(
    pipeline?.scheduledTask ? "schedule" : "run"
  );

  const [error, setError] = useState<CronError>();
  const [cronIntervalLimitError, setCronIntervalLimitError] =
    useState<boolean>(false);
  const [cronValue, setCronValue] = useState(CRON_DEFAULT);
  const [form] = Form.useForm<PipelineJobFormInput>();

  useUpdateEffect(() => {
    //reset all states when modal is opened and has `destroyOnClose`
    if (props.open && props.destroyOnClose) {
      setError(undefined);
      // setIsRepeat(mode === "schedule" ? true : false);

      const crontab = pipeline?.scheduledTask?.crontab;
      setCronValue(
        crontab
          ? `${crontab.minute} ${crontab.hour} ${crontab.dayOfMonth} ${crontab.monthOfYear} ${crontab.dayOfWeek}`
          : CRON_DEFAULT
      );

      form.setFieldsValue({
        num_runs: MIN_NUMBER_OF_TIMES + "",
        name: "",
      });
    }
  }, [props.destroyOnClose, props.open]);

  const navigate = useNavigate();
  const { token } = theme.useToken();

  const rawPipelineYml =
    (YAML.load(pipeline?.yaml || "") as PipelineYAML) || {};

  const [commitRunPipeline, isInFlightCommitRun] =
    useMutation<RunPipelineModalMutation>(graphql`
      mutation RunPipelineModalMutation($input: RunPipelineInput!) {
        runPipeline(input: $input) {
          pipelineJob {
            __typename
            ... on UnauthenticatedError {
              message
            }
            ... on NotActivePipelineError {
              message
            }
            ... on NoTaskPipelineError {
              message
            }
            ... on PipelineJob {
              id
            }
          }
        }
      }
    `);

  const [commitSetSchedule, isInFlightCommitSetSchedule] =
    useMutation<RunPipelineModalSetScheduleMutation>(graphql`
      mutation RunPipelineModalSetScheduleMutation(
        $input: SetPipelineScheduleInput!
      ) {
        setPipelineSchedule(input: $input) {
          pipeline {
            __typename
            ... on Pipeline {
              id
              scheduledTask {
                id
                expires
                expireSeconds
              }
            }
            ... on UnauthenticatedError {
              message
            }
            ...PipelineSummaryFragment
          }
        }
      }
    `);

  const [commitCancelSchedule, isInFlightCommitCancelSchedule] =
    useMutation<RunPipelineModalCancelScheduleMutation>(graphql`
      mutation RunPipelineModalCancelScheduleMutation(
        $input: CancelPipelineScheduleInput!
      ) {
        cancelPipelineSchedule(input: $input) {
          pipeline {
            __typename
            ... on Pipeline {
              id
            }
            ... on UnauthenticatedError {
              message
            }
            ...PipelineSummaryFragment
          }
        }
      }
    `);

  const num_runs = Form.useWatch("num_runs", form);
  const setSchedule = () => {
    if (pipeline?.id) {
      form.validateFields().then((values) => {
        const cronParams = cronValue.split(" ");

        let utcExpires: string | undefined;

        if (MAX_NUMBER_OF_TIMES === 0) {
          // eslint-disable-next-line
          //@ts-ignore
          utcExpires = dayjs(values.expires?.toDate()).toISOString();
        } else {
          const { last, expires } = getScheduleTimesWithExpire(
            cronValue,
            num_runs
          );
          utcExpires = expires;
        }

        commitSetSchedule({
          variables: {
            input: {
              id: pipeline.id,
              minute: cronParams[0],
              hour: cronParams[1],
              dayOfMonth: cronParams[2],
              monthOfYear: cronParams[3],
              dayOfWeek: cronParams[4],
              expires: utcExpires,
              timezone: LOCAL_TIMEZONE || DEFAULT_TIMEZONE,
            },
          },
          onCompleted(response) {
            switch (response.setPipelineSchedule?.pipeline?.__typename) {
              case "Pipeline":
                Modal.success({
                  title: "Schedule saved",
                  content:
                    "The pipeline job schedule has been saved. Until you cancel the schedule, the pipeline job will be run automatically.",
                });
                break;
              case "UnauthenticatedError":
                Modal.error({
                  title: "Unauthenticated",
                  content: response.setPipelineSchedule?.pipeline?.message,
                });
                break;
            }
          },
          onError(error) {
            Modal.error({
              title: "Error",
              width: 800,
              content: (
                <SyntaxHighlighter
                  language="json"
                  style={darcula}
                  wrapLongLines
                  codeTagProps={{
                    style: {
                      fontFamily: "monospace",
                    },
                  }}
                >
                  {JSON.stringify(error, null, 2)}
                </SyntaxHighlighter>
              ),
            });
          },
        });
      });
    }
  };

  const cancelSchedule = () => {
    if (pipeline?.id) {
      commitCancelSchedule({
        variables: {
          input: {
            id: pipeline.id,
          },
        },
        onCompleted(response, errors) {
          switch (response.cancelPipelineSchedule?.pipeline?.__typename) {
            case "Pipeline":
              Modal.success({
                title: "Schedule cancelled",
                content:
                  "The pipeline job schedule has been cancelled. The pipeline job will no longer be run automatically.",
              });
              break;
            case "UnauthenticatedError":
              message.error(response.cancelPipelineSchedule.pipeline.message);
              break;
          }
        },
      });
    }
  };

  const openedTimeISO = useMemoWhenToggledOn(() => {
    return dayjs(new Date())
      .tz(dayjs.tz.guess() || DEFAULT_TIMEZONE, true)
      .format("YYYY-MM-DDTHH:mm:ssZ");
  }, !!props.open);

  const defaultPipelineJobName = `${pipeline?.name}-job-${openedTimeISO}`;
  const runPipeline = () => {
    form.validateFields().then((values) => {
      if (pipeline?.id) {
        commitRunPipeline({
          variables: {
            input: {
              id: pipeline?.id,
              name: values.name || defaultPipelineJobName,
              envs: JSON.stringify(getEnvironmentVariableMap(values.envs)),
            },
          },
          onCompleted({ runPipeline }, error) {
            if (runPipeline?.pipelineJob?.__typename === "PipelineJob") {
              const pipelineJobId = runPipeline.pipelineJob.id;
              Modal.confirm({
                icon: <CheckOutlined />,
                content: "Would you like to view the job detail?",
                title: "Pipeline Job Created",
                okButtonProps: {
                  title: "View Job",
                  style: {
                    backgroundColor: token.colorPrimary,
                  },
                },
                cancelButtonProps: {
                  title: "Close",
                },
                onOk() {
                  navigate(`/pipeline-jobs/${pipelineJobId}`);
                },
              });
              onRequestClose();
              return;
            }
            switch (runPipeline?.pipelineJob?.__typename) {
              case "UnauthenticatedError":
              case "NoTaskPipelineError":
              case "NotActivePipelineError":
                message.error(runPipeline.pipelineJob.message);
                break;
              default:
                Modal.error({
                  title: "Error : runPipeline",
                  width: 800,
                  content: (
                    <SyntaxHighlighter
                      language="json"
                      style={darcula}
                      wrapLongLines
                      codeTagProps={{
                        style: {
                          fontFamily: "monospace",
                        },
                      }}
                    >
                      {JSON.stringify(error, null, 2)}
                    </SyntaxHighlighter>
                  ),
                });
            }
            onRequestClose();
          },
          onError(error) {
            Modal.error({
              title: "Error : unknown",
              width: 800,
              content: (
                <SyntaxHighlighter
                  language="json"
                  style={darcula}
                  wrapLongLines
                  codeTagProps={{
                    style: {
                      fontFamily: "monospace",
                    },
                  }}
                >
                  {JSON.stringify(error, null, 2)}
                </SyntaxHighlighter>
              ),
            });
          },
        });
      }
    });
  };

  return (
    <Modal
      destroyOnClose={true}
      forceRender
      title={"Run Pipeline"}
      {...props}
      width="550px"
      onCancel={() => {
        onRequestClose();
      }}
      footer={[
        <Flex justify="between" key="delete">
          {currentMode === "schedule" && pipeline?.scheduledTask ? (
            <Button
              type="text"
              danger
              loading={isInFlightCommitCancelSchedule}
              onClick={() => {
                cancelSchedule();
              }}
            >
              Cancel Schedule
            </Button>
          ) : (
            <span></span>
          )}
          <Flex direction="row">
            <Button onClick={() => onRequestClose()}>Close</Button>
            <WhiteSpace direction="row" />
            <Button
              icon={
                currentMode === "schedule" ? (
                  <ScheduleOutlined />
                ) : (
                  <PlaySquareOutlined />
                )
              }
              disabled={
                ((error !== undefined || cronIntervalLimitError) &&
                  currentMode === "schedule") ||
                disableRun
              }
              type="primary"
              onClick={() => {
                if (currentMode === "schedule") {
                  setSchedule();
                } else {
                  runPipeline();
                }
              }}
              loading={isInFlightCommitRun || isInFlightCommitSetSchedule}
              hidden={!!pipeline?.scheduledTask && currentMode === "schedule"}
            >
              {currentMode === "schedule" ? "Set a schedule" : "Run"}
            </Button>
          </Flex>
        </Flex>,
      ]}
    >
      <Space direction="vertical" style={{ width: "100%" }}>
        <Descriptions size="small" bordered>
          <Descriptions.Item label="Summary">
            {rawPipelineYml.tasks?.length ?? "0"} task
            {(rawPipelineYml.tasks?.length ?? 0 > 1) ? "s" : ""}
          </Descriptions.Item>
          <Descriptions.Item label="Workflow YAML">
            <CodeButtons
              code={pipeline?.yaml || ""}
              language="yaml"
              filename={`${from_global_id(pipeline?.id || "")[1]}.yaml`}
            />
          </Descriptions.Item>
        </Descriptions>

        <Form
          form={form}
          preserve={false}
          initialValues={{
            num_runs: MIN_NUMBER_OF_TIMES,
          }}
        >
          <Tabs
            activeKey={currentMode}
            // eslint-disable-next-line
            //@ts-ignore
            onChange={setCurrentMode}
            type="card"
            items={[
              {
                key: "run",
                label: (
                  <span>
                    <PlaySquareOutlined /> Run Now
                  </span>
                ),
                children: (
                  <>
                    <Form.Item
                      name="name"
                      label="PipelineJob Name"
                      rules={[
                        {
                          required: false,
                        },
                        {
                          max: 20,
                          message:
                            "PipelineJob name must be less than 20 characters",
                        },
                        {
                          pattern: /^[a-zA-Z0-9_-]+$/,
                          message:
                            "PipelineJob name must be composed of letters, numbers, underscores, and hyphens",
                        },
                      ]}
                    >
                      <Input
                        placeholder={defaultPipelineJobName}
                        disabled={isInFlightCommitRun}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Environment Variables"
                      tooltip="These environment variables will be used as once-only values for this pipeline job. Each variable can override other task-level or pipeline-level variables with the same name."
                    >
                      <EnvironmentVariableList
                        name={["envs"]}
                        keyRules={[KeyRules.BLOCK_BACKENDAI_PREFIX]}
                      />
                    </Form.Item>
                  </>
                ),
              },
              {
                key: "schedule",
                label: (
                  <span>
                    {pipeline?.scheduledTask ? (
                      <ScheduleFilled />
                    ) : (
                      <ScheduleOutlined />
                    )}{" "}
                    Schedule
                  </span>
                ),
                children: (
                  <>
                    {LOCAL_TIMEZONE === undefined && ( // Microsoft Edge
                      <div>
                        <Alert
                          message={`Chromium-based browsers under version 119.0.6045.33 can fail to resolve local timezone.`}
                          type="warning"
                          showIcon
                          closable
                        />
                        <WhiteSpace direction="column" />
                      </div>
                    )}
                    <Alert
                      message={`The scheduler timezone is ${
                        LOCAL_TIMEZONE || DEFAULT_TIMEZONE
                      }.`}
                      type="info"
                      showIcon
                    />
                    <WhiteSpace direction="column" />
                    <Cron
                      disabled={!!pipeline?.scheduledTask}
                      value={cronValue}
                      setValue={(value: string) => {
                        if (
                          value === "* * * * *" ||
                          value.split(" ")[0] === "*"
                        ) {
                          setCronIntervalLimitError(true);
                        } else {
                          setCronIntervalLimitError(false);
                        }
                        setCronValue(value);
                      }}
                      onError={setError}
                      allowedPeriods={["hour", "day", "month", "week"]}
                    />
                    <Form.Item
                      validateStatus={
                        error || cronIntervalLimitError ? "error" : undefined
                      }
                      help={
                        error?.description
                          ? error.description
                          : cronIntervalLimitError
                            ? "You can only set a cron expression with every minute."
                            : pipeline?.scheduledTask
                              ? "The schedule is already set. If you want to change it, you need to cancel the schedule first."
                              : "You can set a cron expression directly and select using the dropdown."
                      }
                    >
                      <Input
                        value={cronValue}
                        disabled={!!pipeline?.scheduledTask}
                        onChange={(e) => setCronValue(e.target.value)}
                      />
                    </Form.Item>
                    <WhiteSpace direction="column" size="xs" />
                    <Divider />

                    {pipeline?.scheduledTask !==
                    null ? null : MAX_NUMBER_OF_TIMES !== 0 ? (
                      <Form.Item
                        label="Expire after"
                        name="num_runs"
                        // tooltip={`This schedule will be expired after this number of runs. (1-${MAX_NUMBER_OF_TIMES})`}
                        extra={
                          <>
                            This schedule will be expired after this number of
                            runs.
                          </>
                        }
                        rules={[
                          {
                            required: true,
                            message: "Please input the number of runs",
                          },
                          numberMinRule(1, `Minimum number is 1`),
                          numberMaxRule(
                            MAX_NUMBER_OF_TIMES,
                            `Run must be less than ${MAX_NUMBER_OF_TIMES + 1}`
                          ),
                        ]}
                        hidden={pipeline?.scheduledTask !== null}
                      >
                        <Input suffix="time(s)" min={1} type="number" />
                      </Form.Item>
                    ) : (
                      <Form.Item
                        label="Expires"
                        name="expires"
                        extra="(optional) You can set the expiration date of this schedule."
                      >
                        <DatePicker showTime showNow={false} />
                      </Form.Item>
                    )}
                    {pipeline?.scheduledTask?.expires ? (
                      <>
                        <Alert
                          type="warning"
                          showIcon
                          message={
                            <Text>
                              {dayjs(pipeline?.scheduledTask?.expires).isBefore(
                                Date.now()
                              )
                                ? "Already expired"
                                : "Expires"}{" "}
                              at &nbsp;
                              {dayjs(pipeline?.scheduledTask?.expires)
                                .local()
                                .format("lll")}
                            </Text>
                          }
                        />
                        {/* {scheduleTimeArr[0] && (
                        <Alert
                          type="warning"
                          showIcon
                          message={`Next run time: ${dayjs(
                            scheduleTimeArr[0].toISOString()
                          ).format("lll")}`}
                        />
                      )} */}
                      </>
                    ) : null}
                  </>
                ),
              },
            ]}
          />
        </Form>
      </Space>
    </Modal>
  );
};

export default RunPipelineModal;
