import { useRef, useState } from "react";

export const useDateISOState = (initialValue?: string) => {
  const [value, setValue] = useState(initialValue || new Date().toISOString());

  const update = (newValue?: string) => {
    setValue(newValue || new Date().toISOString());
  };
  return [value, update] as const;
};

export const useUpdatableState = (initialValue = "first") => {
  return useDateISOState(initialValue);
};

// `useMemoWhenToggledOn` will run the given factory function and memoize the result as long as 'isOn' is true.
// The result will be cached and returned on subsequent calls, unless 'isOn' becomes true again.
export const useMemoWhenToggledOn = <T>(factory: () => T, isOn: boolean) => {
  const prevIsOnRef = useRef<boolean>();
  const prevResultRef = useRef<T>();
  let currentResult: T | undefined;

  if (prevIsOnRef.current !== isOn && isOn) {
    currentResult = factory();
  } else {
    currentResult = prevResultRef.current;
  }

  prevIsOnRef.current = isOn;
  prevResultRef.current = currentResult;

  return currentResult;
};
