import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Divider, Space, Tabs, Tag } from "antd";
import { ItemType } from "antd/es/breadcrumb/Breadcrumb";
import { LucideActivity, LucideArchive } from "lucide-react";
import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { StringParam, useQueryParam, withDefault } from "use-query-params";

import AutoRefreshSwitch from "../components/AutoRefreshSwitch";
import Flex from "../components/Flex";
import WhiteSpace from "../components/WhiteSpace";
import { from_global_id } from "../helpers";

const PipelineJobTable = React.lazy(
  () => import("../components/PipelineJobTable")
);

const PipelineJobList = () => {
  const [pipelineId, setPipelineId] = useQueryParam("pipelineId", StringParam);
  const [tab, setTab] = useQueryParam(
    "tab",
    withDefault(StringParam, "active")
  );
  const [refreshKey, setRefreshKey] = useState<number>(0);

  const items: ItemType[] = [
    {
      title: (
        <Link to={"/"}>
          <HomeOutlined />
        </Link>
      ),
    },
    {
      title: "Pipeline Jobs",
    },
  ];

  const tabBarExtraContent = useMemo(
    () => ({
      right: (
        <AutoRefreshSwitch
          onRefresh={() => {
            setRefreshKey((v) => v + 1);
          }}
          interval={5000}
        >
          Auto-refresh every 5 seconds
        </AutoRefreshSwitch>
      ),
    }),
    []
  );

  return (
    <div style={{ padding: 16 }}>
      <Space direction="vertical" style={{ width: "100%" }}>
        <Flex direction="row" justify="between">
          <Breadcrumb items={items} />
        </Flex>
        {pipelineId ? (
          <Space direction="horizontal">
            filter:
            <Tag
              closable
              onClose={() => {
                setPipelineId(null);
              }}
            >
              pipeline <Divider type="vertical" style={{ margin: "0 4px" }} />
              {from_global_id(pipelineId)[1]}
            </Tag>
          </Space>
        ) : null}
        <Tabs
          type="card"
          tabBarStyle={{ marginBottom: 0 }}
          activeKey={tab}
          onChange={(activeKey) => setTab(activeKey)}
          items={[
            {
              key: "active",
              label: (
                <Flex direction="row" align="center" justify="between">
                  <LucideActivity />
                  <WhiteSpace direction="row" size="xs" />
                  <span>Active</span>
                </Flex>
              ),
              children: (
                <PipelineJobTable
                  pipelineId={pipelineId}
                  filter={{ recordStatus: { exact: "ACTIVE" } }}
                  tab={"active"}
                  refreshKey={refreshKey}
                />
              ),
            },
            {
              key: "archived",
              label: (
                <Flex direction="row" align="center" justify="between">
                  <LucideArchive />
                  <WhiteSpace direction="row" size="xs" />
                  <span>Archived</span>
                </Flex>
              ),
              children: (
                <PipelineJobTable
                  pipelineId={pipelineId}
                  filter={{ recordStatus: { exact: "ARCHIVED" } }}
                  tab={"archived"}
                  refreshKey={refreshKey}
                />
              ),
            },
          ]}
          tabBarExtraContent={tabBarExtraContent}
        />
      </Space>
    </div>
  );
};

export default PipelineJobList;
