/**
 * @generated SignedSource<<c1c7f3c3eb50a84c1e30907ce807f732>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineYamlCodeEditorFragment$data = {
  readonly dataflow: any | null;
  readonly id: string;
  readonly lastModified: any;
  readonly name: string;
  readonly yaml: string;
  readonly " $fragmentType": "PipelineYamlCodeEditorFragment";
};
export type PipelineYamlCodeEditorFragment$key = {
  readonly " $data"?: PipelineYamlCodeEditorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineYamlCodeEditorFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineYamlCodeEditorFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yaml",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dataflow",
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};

(node as any).hash = "827d79fcb00bbfb80290332a9a91aad0";

export default node;
