import { ReloadOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import { AnsiUp } from "ansi-up";
import { Button, Modal, ModalProps } from "antd";
import React from "react";
import { SyntaxHighlighterProps } from "react-syntax-highlighter";

import { from_global_id } from "../helpers";
import { baiFetch } from "../hooks/auth";
import Flex from "./Flex";
import WhiteSpace from "./WhiteSpace";

const ansi_up = new AnsiUp();

interface SessionLogModalProps extends ModalProps {
  onRequestClose: () => void;
  taskInstanceGlobalId?: string;
  highlighterProps?: Partial<SyntaxHighlighterProps>;
}

// const logInfoQuery = selectorFamily({
//   key: "SessionLogModal/logInfoQuery",
//   get:
//     (sessionId?: string) =>
//     async ({ get }) => {
//       const loginCheck = get(loginCheckState);
//       if (sessionId) {
//         const response = await fetch(
//           `${getWebServerEndpoint()}/func/session/${sessionId}/logs?owner_access_key=${
//             loginCheck.data.access_key
//           }`,
//           {
//             method: "GET",
//             credentials: "include",
//             mode: "cors",
//           }
//         ).then((r) => r.json());
//         return response?.result?.logs || "";
//       }
//       return "";
//     },
// });

const SessionLogModal: React.FC<SessionLogModalProps> = ({
  taskInstanceGlobalId,
  onRequestClose,
  highlighterProps,
  title,
  ...props
}) => {
  const taskInstanceId = from_global_id(taskInstanceGlobalId ?? "")[1];
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["SessionLogModal/logInfoQuery", taskInstanceGlobalId],
    queryFn: () =>
      baiFetch(`/pipeline/task-instances/${taskInstanceId}/logs`, {
        method: "GET",
      }).then((res) => res.json()),
    enabled: !!taskInstanceGlobalId,
  });

  return (
    <Modal
      title={
        <Flex direction="row">
          {title ? title : "Session Logs"}
          <WhiteSpace direction="row" />
          <Button
            loading={isLoading}
            icon={<ReloadOutlined />}
            onClick={() => refetch()}
          />
        </Flex>
      }
      width={1000}
      onCancel={onRequestClose}
      onOk={onRequestClose}
      footer={null}
      {...props}
    >
      <pre
        style={{ fontFamily: "monospace" }}
        dangerouslySetInnerHTML={{
          __html: ansi_up.ansi_to_html(data?.result?.logs),
        }}
      />
    </Modal>
  );
};

export default SessionLogModal;
