import { Form, Input } from "antd";
import { FormItemProps } from "antd/lib";
import React from "react";

export const DEFAULT_VFOLDER_ALIAS_PATH = "/pipeline/outputs";

export const DEFAULT_VFOLDER_ALIAS_SEPARATOR = ":";

export const getDefaultOutputFolderName = (
  folderName: string,
  taskName: string
): string => `${folderName}/tasks/${taskName}`;

interface Props extends FormItemProps {
  folderName: string;
  dedicated?: boolean;
}

const VirtualFolderAliasInput: React.FC<Props> = ({
  folderName,
  dedicated,
  ...props
}) => {
  return (
    <Form.Item
      {...props}
      initialValue={dedicated ? DEFAULT_VFOLDER_ALIAS_PATH : undefined}
    >
      <Input disabled={dedicated} allowClear />
    </Form.Item>
  );
};

export default VirtualFolderAliasInput;
