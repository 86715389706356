import { useAtomValue } from "jotai";
import _ from "lodash";

import metadata from "./_image_metadata.json";
import {
  coreServiceVersionAtom,
  resourceSlotDetailMapAtom,
  resourceSlotListAtom,
} from "./atoms";
import { Image, imageListAtom, imageMetadataAtom } from "./atoms/images";

// TODO: error handling
// https://recoiljs.org/docs/guides/asynchronous-data-queries

const imageInfo = metadata.imageInfo as {
  [key: string]: any;
};

// https://github.com/lablup/backend.ai/blob/61a4a557756498c673f50fa0fcb158d0195de521/src/ai/backend/common/types.py#L1149-L1155
type AcceleratorMetadata = {
  slot_name: string;
  description: string;
  human_readable_name: string;
  display_unit: string;
  number_format: AcceleratorNumberFormat;
  display_icon: string;
};

type AcceleratorNumberFormat = {
  binary: boolean;
  round_length: number;
};

// "cuda.device", "cuda.shares", "cuda.1g.5gb-mig", ...
type MIGPostfix = `device:${number}g.${number}gb-mig`;
export type ResourceSlot = `${string}.${"device" | "shares" | MIGPostfix}`; // "cpu" | "mem"

// https://github.com/lablup/backend.ai/blob/d8ac38b4752e40d37444438c54e0ddbb88875038/src/ai/backend/manager/api/etcd.py#L37-L86
export type ResourceSlotDetailMap = {
  [key: ResourceSlot]: AcceleratorMetadata;
};

export const useResourceSlotsDetails = (
  resourceGroup: string
): ResourceSlotDetailMap => {
  return useAtomValue(resourceSlotDetailMapAtom(resourceGroup));
};

export const useResourceSlotsState = (): ResourceSlot[] => {
  const resourceSlots = useAtomValue(resourceSlotListAtom);
  return resourceSlots;
};

export interface VFolderHostListInfo {
  default: string;
  allowed: string[];
  volume_info: {
    [key: string]: {
      backend: string;
      capabilities: string[];
    };
  };
}

// ref: https://github.com/lablup/backend.ai/blob/8d31486ce09d7f952aa5cab59e8391f0522b65dc/src/ai/backend/manager/models/vfolder.py#L162-L176
export type VirtualFolderOperationStatus =
  | "ready"
  | "performing"
  | "cloning"
  | "mounted"
  | "error"
  //
  | "delete-pending" // VFolder is in trash bin
  | "delete-ongoing" // VFolder is being deleted in storage
  | "delete-complete" // VFolder is deleted permanently, only DB row remains
  | "delete-error";

// ref: https://github.com/lablup/backend.ai/blob/8d31486ce09d7f952aa5cab59e8391f0522b65dc/src/ai/backend/manager/models/vfolder.py#L256-L269
export const DEAD_VFOLDER_STATUSES: VirtualFolderOperationStatus[] = [
  "delete-pending",
  "delete-ongoing",
  "delete-complete",
  "delete-error",
];

export interface VirtualFolder {
  name: string;
  id: string;
  host: string;
  quota_scope_id: string;
  status: VirtualFolderOperationStatus;
  numFiles?: number; // legacy
  num_files: number;
  used_bytes: number;
  usage_mode: string; // "data"
  created: string;
  created_at: string;
  last_used: string;
  is_owner: boolean;
  permission: string;
  user: string;
  group: string | null; // "None"
  creator: string;
  user_email: string;
  group_name: string | null;
  ownership_type: string;
  type: string;
  cloneable: boolean;
  max_files: number;
  max_size: string | null;
  cur_size: number;
}

export const useImageGroupsState = () => {
  const imagesList = useAtomValue(imageListAtom);
  const getImageInfoKey = (name: string) => {
    let imageName: string;
    const specs: string[] = name.split("/");
    if (specs.length === 1) {
      imageName = specs[0];
    } else {
      imageName = specs[1];
    }
    return imageName;
  };

  const result: {
    [key: string]: {
      [key: string]: Image[];
    };
  } = _.chain(imagesList)
    .groupBy((item) => {
      return (
        imageInfo[getImageInfoKey(item.name)]?.group || "Custom Environments"
      );
    })
    .mapValues((images, groupName) => {
      return _.chain(
        images.sort((a: Image, b: Image) =>
          compareImageVersion(
            b?.tag?.split("-")?.[0] ?? "",
            a?.tag?.split("-")?.[0] ?? ""
          )
        )
      )
        .groupBy((image) => {
          return imageInfo[getImageInfoKey(image.name)]?.name || image.name;
        })
        .value();
    })
    .value();

  const getImageInfo = ({
    name,
    tag,
    architecture,
  }: {
    name: string;
    tag: string;
    architecture?: string;
  }) => {
    const foundInfo: {
      groupName?: string;
      subGroupName?: string;
      image?: any;
    } = {
      groupName: undefined,
      subGroupName: undefined,
      image: undefined,
    };

    _.map(result, (subGroups, _groupName) =>
      _.map(subGroups, (images, _subGroupName) => {
        foundInfo.image =
          foundInfo.image ||
          _.find(images, (image) => {
            if (
              image.name === name &&
              image.tag === tag &&
              (architecture ? image.architecture === architecture : true)
            ) {
              foundInfo.groupName = _groupName;
              foundInfo.subGroupName = _subGroupName;
              return true;
            }
          });
      })
    );

    return foundInfo;
  };

  return [
    result,
    {
      getImageInfo,
      getImageOptionKey(image: Image) {
        return image
          ? `${image.registry}/${image.name}:${image.tag}@${image.architecture}`
          : undefined;
      },
      getImageInfoByKey(version: string) {
        const key = version?.split("@")[0];
        const [registry, ...withoutRegistry] = _.split(key, "/");
        const [_name, _tag] = _.split(withoutRegistry.join("/"), ":");

        return getImageInfo({
          name: _name || "",
          tag: _tag || "",
        });
      },
    },
  ] as const;
};

export function isAPIVersionCompatible(
  apiVersion: string,
  targetVersion: string
): boolean {
  if (apiVersion !== null && targetVersion !== null) {
    apiVersion = apiVersion
      .split(".")
      .map((s) => s.padStart(10))
      .join(".");
    targetVersion = targetVersion
      .split(".")
      .map((s) => s.padStart(10))
      .join(".");
  }
  return targetVersion <= apiVersion;
}

export function useAPIVersionCompatibility(version: string): boolean {
  const { version: apiVersion } = useAtomValue(coreServiceVersionAtom);
  return isAPIVersionCompatible(apiVersion, version);
}

const compareImageVersion = (lh: string, rh: string): number => {
  const v1 = lh.split(".").map(Number);
  const v2 = rh.split(".").map(Number);
  const maxlen = Math.max(v1.length, v2.length);
  for (let i = 0; i < maxlen; i++) {
    const n1 = v1[i] || 0;
    const n2 = v2[i] || 0;
    if (n1 > n2) {
      return 1;
    } else if (n1 < n2) {
      return -1;
    }
  }
  return 0;
};

export const useImageMetadata = () => {
  return useAtomValue(imageMetadataAtom);
};

export function minifyGraphQLQuery(query: string): string {
  // Remove line breaks, tabs, and multiple spaces
  return query.replace(/\s+/g, " ").trim();
}
