import { Progress, Tooltip, theme } from "antd";
import graphql from "babel-plugin-relay/macro";
import _ from "lodash";
import React from "react";
import { useFragment } from "react-relay";

import Flex from "./Flex";
import { PipelineJobProgressFragment$key } from "./__generated__/PipelineJobProgressFragment.graphql";

const PipelineJobProgress: React.FC<{
  pipelineJobFgmt: PipelineJobProgressFragment$key;
  children?: React.ReactNode;
}> = ({ pipelineJobFgmt, children }) => {
  const { token } = theme.useToken();
  const pipelineJob = useFragment(
    graphql`
      fragment PipelineJobProgressFragment on PipelineJob {
        status
        taskinstanceSet {
          totalCount
          edges {
            node {
              id
              result
              status
            }
          }
        }
      }
    `,
    pipelineJobFgmt
  );

  const taskInstanceObjResult = {
    SUCCESS: 0,
    FAILURE: 0,
    UNDEFINED: 0,
  };

  const taskInstanceObjStatus = {
    RUNNING: 0,
    TERMINATED: 0,
  };

  Object.assign(
    taskInstanceObjStatus,
    _.countBy(
      pipelineJob.taskinstanceSet.edges.map((edge: any) => {
        return edge?.node?.status;
      })
    )
  );

  Object.assign(
    taskInstanceObjResult,
    _.countBy(
      pipelineJob.taskinstanceSet.edges.map((edge: any) => {
        return edge?.node?.result;
      })
    )
  );

  const MAX_VISIBLE_STEPS = 10;

  const strokeColor: string[] = _.concat(
    _.range(taskInstanceObjResult.SUCCESS).map(() => token.colorSuccess),
    _.range(taskInstanceObjStatus.RUNNING).map(() => token.colorPrimary),
    _.range(taskInstanceObjResult.FAILURE).map(() => token.colorError),
    _.range(pipelineJob.taskinstanceSet.totalCount).map(() => "lightgray")
  ).slice(0, pipelineJob.taskinstanceSet.totalCount);

  const progressStepWidth = 12;

  return (
    <Flex
      direction="column"
      align="center"
      style={{ flex: 1, alignItems: "stretch", width: 142, lineHeight: 0.75 }}
    >
      <Tooltip
        title={`${taskInstanceObjResult.SUCCESS} SUCCESS / ${taskInstanceObjStatus.RUNNING} RUNNING / ${taskInstanceObjResult.FAILURE} FAILURE`}
        placement="bottom"
      >
        {_.chunk(strokeColor, MAX_VISIBLE_STEPS).map((steps, i) => (
          <Progress
            key={i}
            size={[progressStepWidth, 8]}
            steps={steps.length}
            strokeColor={steps}
            percent={Math.round(
              (steps.filter((x) => x !== "lightgray").length / steps.length) *
                100
            )}
            showInfo={false}
          />
        ))}
      </Tooltip>
    </Flex>
  );
};

export default PipelineJobProgress;
