import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Tooltip, theme } from "antd";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";

import Flex from "./Flex";
import { PipelineJobResultIcon_pipelineJob$key } from "./__generated__/PipelineJobResultIcon_pipelineJob.graphql";

const PipelineJobResultIcon: React.FC<{
  pipelineJobFragment: PipelineJobResultIcon_pipelineJob$key;
  children?: React.ReactNode;
}> = ({ pipelineJobFragment, children }) => {
  const { token } = theme.useToken();
  const pipelineJob = useFragment(
    graphql`
      fragment PipelineJobResultIcon_pipelineJob on PipelineJob {
        result
      }
    `,
    pipelineJobFragment
  );

  return (
    <Flex direction="row" align="center" justify="center">
      <Tooltip title={pipelineJob.result}>
        {pipelineJob.result === "SUCCESS" ? (
          <CheckCircleOutlined style={{ color: token.colorSuccess }} />
        ) : pipelineJob.result === "FAILURE" ? (
          <CloseCircleOutlined style={{ color: token.colorError }} />
        ) : (
          <MinusCircleOutlined style={{ color: "gray" }} />
        )}
      </Tooltip>
    </Flex>
  );
};

export default PipelineJobResultIcon;
