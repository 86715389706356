import { Alert, Tag, Typography } from "antd";
import graphql from "babel-plugin-relay/macro";
import { useAtomValue } from "jotai";
import { default as YAML } from "js-yaml";
import _ from "lodash";
import React, { Suspense, useEffect, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { StringParam, useQueryParam } from "use-query-params";

import { projectAtom } from "../hooks/atoms";
import FlexActivityIndicator from "./FlexActivityIndicator";
import NotFoundTemplate from "./NotFoundTemplate";
import PipelineSummary from "./PipelineSummary";
import PipelineTaskTemplates from "./PipelineTaskTemplates";
import PipelineVersionSummary from "./PipelineVersionSummary";
import PipelineVersionYamlViewer from "./PipelineVersionYamlViewer";
import PipelineYamlCodeEditor from "./PipelineYamlCodeEditor";
import PipelineYamlEditor, {
  NodeValidationErrorMap,
  PipelineYAML,
} from "./PipelineYamlEditor";
import { PipelineDetailQuery } from "./__generated__/PipelineDetailQuery.graphql";

const { Text } = Typography;

interface PipelineDetailProps {
  pipelineId: string;
}

const PipelineDetail: React.FC<PipelineDetailProps> = ({ pipelineId }) => {
  const [mode, setMode] = useQueryParam("mode", StringParam);
  const toggleEditMode = () =>
    setMode((m) => (m === "code" ? "graph" : "code"));
  const [selectedPipelineVersionId] = useQueryParam(
    "pipelineVersionId",
    StringParam
  );
  const { pipeline, pipelineVersion } = useLazyLoadQuery<PipelineDetailQuery>(
    graphql`
      query PipelineDetailQuery(
        $id: ID!
        $versionId: ID!
        $skipVersion: Boolean!
      ) {
        pipeline(id: $id) {
          id
          yaml
          ...PipelineSummaryFragment
          ...PipelineYamlEditorFragment
          ...PipelineYamlCodeEditorFragment
        }
        pipelineVersion(id: $versionId) @skip(if: $skipVersion) {
          id
          yaml
          dataflow
          ...PipelineVersionYamlViewerFragment
          ...PipelineVersionSummaryFragment
        }
      }
    `,
    {
      id: pipelineId,
      skipVersion: selectedPipelineVersionId === undefined,
      versionId: selectedPipelineVersionId || "",
    },
    {
      fetchPolicy: "store-and-network",
    }
  );

  const project = useAtomValue(projectAtom);
  const navigate = useNavigate();
  useEffect(() => {
    const pipelineYaml = YAML.load(pipeline?.yaml ?? "") as PipelineYAML;
    if (
      project !== undefined &&
      project.name !== pipelineYaml.ownership?.project
    ) {
      navigate("/pipelines", { replace: true });
    }
  }, [project, pipeline?.yaml]);

  const [nodeValidationErrors, setNodeValidationErrors] =
    useState<NodeValidationErrorMap>();

  return pipeline && pipeline.id ? (
    <>
      {mode !== "code" ? (
        <>
          <div
            key={pipeline?.id}
            style={{
              position: "absolute",
              top: 8,
              left: 8,
              right: 8,
              zIndex: 1000,
            }}
          >
            {selectedPipelineVersionId ? (
              <PipelineVersionSummary
                onClickCodeEditor={toggleEditMode}
                pipelineVersionFrgmt={pipelineVersion || null}
              />
            ) : (
              <PipelineSummary
                pipelineFrgmt={pipeline}
                disableRun={!_.isEmpty(nodeValidationErrors)}
                onClickCodeEditor={toggleEditMode}
              />
            )}
            {selectedPipelineVersionId ? null : (
              <PipelineTaskTemplates style={{ marginTop: "-1px" }} />
            )}
            {!_.isEmpty(nodeValidationErrors) && (
              <Alert
                style={{ marginTop: 4 }}
                type="warning"
                message={
                  <Text>
                    Please review and correct the following task
                    {_.size(nodeValidationErrors) > 1 ? "s" : ""}:&nbsp;
                    {_.map(nodeValidationErrors, (e, n) => (
                      <Tag key={n}>{n}</Tag>
                    ))}
                  </Text>
                }
                closable
                showIcon
              />
            )}
          </div>
          <Suspense fallback={<FlexActivityIndicator />}>
            <ReactFlowProvider>
              <PipelineYamlEditor
                pipelineFrgmt={pipeline}
                onChangeNodeValidation={setNodeValidationErrors}
              />
            </ReactFlowProvider>
          </Suspense>
        </>
      ) : selectedPipelineVersionId ? (
        <PipelineVersionYamlViewer
          pipelineVersionFrgmt={pipelineVersion || null}
          onRequestClose={toggleEditMode}
        />
      ) : (
        <Suspense fallback={<></>}>
          <PipelineYamlCodeEditor
            onRequestClose={toggleEditMode}
            pipelineFrgmt={pipeline}
            onSaveComplete={() => {
              // toggleEditMode();
            }}
          />
        </Suspense>
      )}
    </>
  ) : (
    <NotFoundTemplate
      title="Pipeline not found."
      subTitle="Please select a valid pipeline ID."
    />
  );
};

export default PipelineDetail;
