import { atom } from "jotai";
import { atomWithReset } from "jotai/utils";
import _ from "lodash";

import { userAtom } from ".";
import { baiFetch } from "../auth";
import { minifyGraphQLQuery } from "../backendai";

export interface Project {
  id: string;
  name: string;
}

export const projectListAtom = atom(async (get) => {
  const user = await get(userAtom);
  return user?.groups as Project[];
});

export const availableProjectListAtom = atom(async (get) => {
  const user = await get(userAtom); // NOTE: dependency
  if (user === null) {
    throw new Error("InvalidSessionToken");
  }
  const response: {
    data: {
      user: {
        groups: Project[];
      };
    };
  } = await baiFetch("/func/admin/gql", {
    method: "POST",
    body: JSON.stringify({
      // WARN: the result of this query has only scaling_groups allowed to the current project
      query: minifyGraphQLQuery(`
        query {
          user {
            username
            groups {
              id
              name
            }
          }
        }
      `),
      variables: {},
    }),
  }).then((res) => res.json());
  return response.data.user.groups.filter(
    // NOTE: Manager does not support `user_nodes { group_nodes { name } }`.
    (group) => group.name !== "model-store"
  );
});

export const projectIdAtom = atomWithReset<string>(""); // currentProjectidState

export const projectAtom = atom(async (get, set) => {
  const projectList = await get(projectListAtom);
  const projectId = get(projectIdAtom);
  return _.find(projectList, (project) => project.id === projectId) as Project;
});
