import { useToggle } from "ahooks";
import {
  Checkbox,
  Form,
  FormInstance,
  FormProps,
  Input,
  InputNumber,
  Select,
  Switch,
  Typography,
  theme,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import graphql from "babel-plugin-relay/macro";
import _ from "lodash";
import React from "react";
import { useFragment } from "react-relay";

import { numberMinRule } from "../helpers";
import {
  DEAD_VFOLDER_STATUSES,
  ResourceSlot,
  useAPIVersionCompatibility,
} from "../hooks/backendai";
import EnvironmentVariableList, {
  IEnvironmentVariable,
  KeyRules,
} from "./EnvironmentVariableList";
import Flex from "./Flex";
import ResourcesInputItems, { ResourcesFormInput } from "./ResourcesInputItems";
import VirtualFolderMountFormList from "./VirtualFolderMountFormList";
import { PipelineTaskForm_pipeline$key } from "./__generated__/PipelineTaskForm_pipeline.graphql";

const VirtualFolderSelectorModal = React.lazy(
  () => import("./VirtualFolderSelectorModal")
);

const { Text } = Typography;

export type ClusterMode = "single-node" | "multi-node";

export interface PipelineTaskFormInput extends ResourcesFormInput {
  id: string;
  name?: string;
  description?: string;
  skip?: boolean;
  type?: string;
  clusterMode: ClusterMode;
  clusterSize: number;
  envs?: IEnvironmentVariable[];
  command?: string;
  vFolders?: {
    id?: string;
    name: string;
    alias?: string;
  }[];
  scalingGroup?: string;
}

interface PipelineTaskFormProps extends FormProps {
  form: FormInstance<PipelineTaskFormInput>;
  pipelineFrgmt: PipelineTaskForm_pipeline$key | null;
  invalidNames?: string[];
  resourceSlots?: ResourceSlot[];
}

const PipelineTaskForm: React.FC<PipelineTaskFormProps> = ({
  form,
  pipelineFrgmt,
  invalidNames,
  resourceSlots = [],
  ...props
}) => {
  const [visibleFolderModal, { toggle: toggleFolderModal }] = useToggle(false);

  const pipeline = useFragment(
    graphql`
      fragment PipelineTaskForm_pipeline on Pipeline {
        yaml
        storage
      }
    `,
    pipelineFrgmt
  );

  const supportsMultiContainer = useAPIVersionCompatibility("v6.20200815"); // https://github.com/lablup/backend.ai-webui/blob/16f14cc7d097dd5eba41adbcc04347b95a5dafe2/src/lib/backend.ai-client-esm.ts#L563-L564

  // Remove one occurrence of the task name from the list using
  const pipelineDedicatedFolderName = pipeline?.storage
    ? JSON.parse(pipeline.storage).name
    : undefined;

  const { token } = theme.useToken();

  const maxTaskNameLength = 20;

  return (
    <Form form={form} layout="vertical" scrollToFirstError={true} {...props}>
      <Form.Item hidden name="id">
        <Input />
      </Form.Item>
      <Form.Item name={"skip"} valuePropName="checked" hidden>
        <Switch />
      </Form.Item>
      <Form.Item
        name="name"
        label="Task Name"
        rules={[
          {
            required: true,
            message: "Please input the name of the task!",
          },
          {
            max: maxTaskNameLength,
            message: "Task name may not be longer than 20 characters.",
          },
          {
            pattern: /^[a-zA-Z0-9_-]+$/,
            message:
              "Task name must be composed of letters, numbers, underscores, and hyphens",
          },
          {
            validator: (__, value) =>
              _.includes(invalidNames, value)
                ? Promise.reject(
                    new Error("You can not use the same name for two tasks")
                  )
                : Promise.resolve(),
          },
        ]}
      >
        <Input count={{ show: true, max: maxTaskNameLength }} />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <TextArea disabled={props.disabled} />
      </Form.Item>
      <Form.Item
        name="type"
        label="Task Type"
        initialValue={"custom"}
        tooltip="More task types are under development."
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select disabled>
          <Select.Option key="custom">Custom Task</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        label="Environment Variables"
        tooltip="These environment variables will be assigned to the task. Each variable can override a pipeline-level variable with the same name."
      >
        <EnvironmentVariableList
          name={"envs"}
          keyRules={[KeyRules.BLOCK_BACKENDAI_PREFIX]}
        />
      </Form.Item>
      <Form.Item name="command" label="Command" rules={[{ required: true }]}>
        <TextArea style={{ fontFamily: "Monaco" }} disabled={props.disabled} />
      </Form.Item>
      <Form.Item
        label={<Text strong>Session Configurations</Text>}
        style={{
          backgroundColor: "#fafafa",
          padding: "16px 16px 0 16px",
          border: "1px solid",
          borderColor: token.colorBorder,
          borderRadius: "2px",
        }}
      >
        <ResourcesInputItems
          pipelineFrgmt={pipelineFrgmt}
          resourceSlots={resourceSlots}
        />
        {supportsMultiContainer ? (
          <Form.Item
            label="Cluster Mode"
            tooltip="When running a session, the managed node and worker node(s) can be placed on a single physical node/virtual machine or split across multiple physical nodes/virtual machines."
            rules={[{ required: true }]}
            style={{ marginBottom: 0 }}
          >
            <Flex direction="row" align="center" gap={"xs"}>
              <Form.Item name="clusterMode" rules={[{ required: true }]}>
                <Select style={{ width: "256px" }}>
                  <Select.Option value="single-node">single-node</Select.Option>
                  <Select.Option value="multi-node">multi-node</Select.Option>
                </Select>
              </Form.Item>
              <Form.Item
                style={{ marginBottom: 0 }}
                shouldUpdate={(prevValues, nextValues) => {
                  if (prevValues.clusterSize !== nextValues.clusterSize) {
                    return true;
                  }
                  if (prevValues.clusterMode === nextValues.clusterMode) {
                    return false;
                  }
                  if (nextValues.clusterMode === "single-node") {
                    nextValues.clusterSize = 1;
                  }
                  return true;
                }}
              >
                {({ getFieldValue }) => {
                  return (
                    <Form.Item
                      name="clusterSize"
                      rules={[
                        {
                          required: true,
                        },
                        numberMinRule(
                          1,
                          "Cluster Size must be greater than or equal to 1"
                        ),
                      ]}
                    >
                      <InputNumber
                        style={{ width: "100%" }}
                        suffix={`Node${
                          getFieldValue("clusterSize") > 1 ? "s" : ""
                        }`}
                        disabled={
                          props.disabled ||
                          getFieldValue("clusterMode") === "single-node"
                        }
                      />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Flex>
          </Form.Item>
        ) : null}
        <VirtualFolderMountFormList
          name={"vFolders"}
          pipelineFrgmt={pipelineFrgmt}
          onClick={() => {
            toggleFolderModal();
          }}
        />
        <Form.Item name="setDefault" valuePropName="checked">
          <Checkbox>
            Set these resource selections to the default values for this
            pipeline.
          </Checkbox>
        </Form.Item>
      </Form.Item>
      <VirtualFolderSelectorModal
        open={visibleFolderModal}
        defaultSelectedKeys={_.map(form.getFieldValue("vFolders"), "id")}
        disabledKeys={[pipelineDedicatedFolderName]}
        filter={(folder) =>
          !folder.name.startsWith(".") &&
          !DEAD_VFOLDER_STATUSES.includes(folder.status)
        }
        onRequestClose={(nextFolders) => {
          toggleFolderModal();
          if (nextFolders === undefined) {
            return;
          }
          const prevFolders = form.getFieldValue("vFolders");
          const existedFolders = _.intersection(nextFolders, prevFolders);
          const addedFolders = _.difference(nextFolders, prevFolders);

          form.setFieldValue(
            "vFolders",
            _.map([...existedFolders, ...addedFolders], (folder, index) => {
              return {
                name: folder.name,
                id: folder.id,
                alias: form.getFieldValue(["vFolders", index, "alias"]),
              };
            })
          );
        }}
      />
    </Form>
  );
};

export default React.memo(PipelineTaskForm);
