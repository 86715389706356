import { ClockCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import dayjs from "dayjs";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { formatTime } from "../helpers";
import { baiFetch } from "../hooks/auth";

interface Props {
  computeSessionId: string;
  fetchKey: number;
}

const TaskInstanceExecutionTimeTag: React.FC<Props> = ({
  computeSessionId,
  fetchKey,
}) => {
  const [statusHistory, setStatusHistory] = useState<{
    RUNNING?: Date;
    TERMINATED?: Date;
  } | null>(null);
  const requestStatusHistory = useCallback(
    async (computeSessionId: string) => {
      const response = await baiFetch("/func/admin/gql", {
        method: "POST",
        body: JSON.stringify({
          query: `
          query SessionStatusHistory($id: UUID!) {
            compute_session(id: $id) {
              status_history
            }
          }
        `,
          variables: {
            id: computeSessionId,
          },
        }),
      }).then((res) => res.json());
      if (response.data?.compute_session?.status_history) {
        setStatusHistory(
          JSON.parse(response.data.compute_session.status_history)
        );
      }
    },
    [fetchKey]
  );
  useEffect(() => {
    if (computeSessionId !== null) {
      requestStatusHistory(computeSessionId);
    }
  }, [requestStatusHistory, computeSessionId]);

  return (
    <>
      {statusHistory && statusHistory.RUNNING ? (
        <Tooltip title="Total run time">
          <ClockCircleOutlined />{" "}
          {formatTime(
            statusHistory["TERMINATED"]
              ? dayjs(statusHistory["TERMINATED"]).diff(
                  dayjs(statusHistory["RUNNING"]),
                  "second"
                )
              : dayjs(Date.now()).diff(
                  dayjs(statusHistory["RUNNING"]),
                  "second"
                ),
            dayjs.locale().split("-")[0]
          )}
        </Tooltip>
      ) : null}
    </>
  );
};

export default TaskInstanceExecutionTimeTag;
