/**
 * @generated SignedSource<<a5845320da6bd456fc01e794d929d3d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineOrder = "CREATED_AT_ASC" | "CREATED_AT_DESC" | "LAST_MODIFIED_ASC" | "LAST_MODIFIED_DESC" | "NAME_ASC" | "NAME_DESC" | "VERSION_ASC" | "VERSION_DESC" | "%future added value";
export type PipelineFilterInputType = {
  and?: ReadonlyArray<PipelineFilterInputType | null> | null;
  createdAt?: PipelineCreatedAtFilterInputType | null;
  description?: PipelineDescriptionFilterInputType | null;
  lastModified?: PipelineLastModifiedFilterInputType | null;
  name?: PipelineNameFilterInputType | null;
  not?: PipelineFilterInputType | null;
  or?: ReadonlyArray<PipelineFilterInputType | null> | null;
  project?: PipelineProjectFilterInputType | null;
};
export type PipelineNameFilterInputType = {
  contains?: string | null;
  exact?: string | null;
  icontains?: string | null;
};
export type PipelineDescriptionFilterInputType = {
  contains?: string | null;
  exact?: string | null;
  icontains?: string | null;
};
export type PipelineProjectFilterInputType = {
  exact?: string | null;
};
export type PipelineCreatedAtFilterInputType = {
  exact?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
};
export type PipelineLastModifiedFilterInputType = {
  exact?: any | null;
  gt?: any | null;
  gte?: any | null;
  lt?: any | null;
  lte?: any | null;
};
export type PipelineInfiniteListPaginationQuery$variables = {
  after?: string | null;
  filter?: PipelineFilterInputType | null;
  first?: number | null;
  order?: ReadonlyArray<PipelineOrder | null> | null;
};
export type PipelineInfiniteListPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PipelineInfiniteListPaginationFragment">;
};
export type PipelineInfiniteListPaginationQuery = {
  response: PipelineInfiniteListPaginationQuery$data;
  variables: PipelineInfiniteListPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "order"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order",
    "variableName": "order"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineInfiniteListPaginationQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "PipelineInfiniteListPaginationFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineInfiniteListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PipelineConnection",
        "kind": "LinkedField",
        "name": "pipelines",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "PipelineEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Pipeline",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastModified",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "yaml",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PipelineJobConnection",
                    "kind": "LinkedField",
                    "name": "pipelinejobSet",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "storage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "owner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      (v4/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PipelineVersionConnection",
                    "kind": "LinkedField",
                    "name": "pipelineversionSet",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PipelineVersionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PipelineVersion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v5/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PeriodicTask",
                    "kind": "LinkedField",
                    "name": "scheduledTask",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CrontabSchedule",
                        "kind": "LinkedField",
                        "name": "crontab",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minute",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hour",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dayOfWeek",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dayOfMonth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "monthOfYear",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expires",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expireSeconds",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "ClientExtension",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__id",
                "storageKey": null
              }
            ]
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "order",
          "filter"
        ],
        "handle": "connection",
        "key": "PipelineList_pipelines",
        "kind": "LinkedHandle",
        "name": "pipelines"
      }
    ]
  },
  "params": {
    "cacheID": "a94fe8212c5c723771e30d4f164cd24b",
    "id": null,
    "metadata": {},
    "name": "PipelineInfiniteListPaginationQuery",
    "operationKind": "query",
    "text": "query PipelineInfiniteListPaginationQuery(\n  $after: String\n  $filter: PipelineFilterInputType\n  $first: Int\n  $order: [PipelineOrder]\n) {\n  ...PipelineInfiniteListPaginationFragment\n}\n\nfragment CrontabViewFragment on CrontabSchedule {\n  id\n  minute\n  hour\n  dayOfWeek\n  dayOfMonth\n  monthOfYear\n}\n\nfragment PipelineDeleteButtonFragment on Pipeline {\n  id\n  name\n  storage\n  scheduledTask {\n    expires\n    id\n  }\n}\n\nfragment PipelineEditorModalFragment on Pipeline {\n  id\n  name\n  description\n  storage\n  yaml\n  scheduledTask {\n    id\n  }\n}\n\nfragment PipelineInfiniteListPaginationFragment on Query {\n  pipelines(first: $first, after: $after, order: $order, filter: $filter) {\n    totalCount\n    edges {\n      node {\n        id\n        name\n        createdAt\n        description\n        lastModified\n        yaml\n        pipelinejobSet {\n          totalCount\n        }\n        ...PipelineSummaryFragment\n        scheduledTask {\n          id\n          expires\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment PipelineSummaryFragment on Pipeline {\n  id\n  name\n  description\n  lastModified\n  isActive\n  storage\n  yaml\n  owner {\n    username\n    name\n    id\n  }\n  pipelinejobSet {\n    totalCount\n  }\n  pipelineversionSet {\n    totalCount\n    edges {\n      node {\n        id\n        createdAt\n      }\n    }\n  }\n  scheduledTask {\n    crontab {\n      ...CrontabViewFragment\n      id\n    }\n    expires\n    expireSeconds\n    id\n  }\n  ...PipelineEditorModalFragment\n  ...RunPipelineModalFragment\n  ...PipelineDeleteButtonFragment\n}\n\nfragment RunPipelineModalFragment on Pipeline {\n  id\n  name\n  yaml\n  scheduledTask {\n    crontab {\n      minute\n      hour\n      dayOfMonth\n      monthOfYear\n      dayOfWeek\n      id\n    }\n    expires\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "caa25fc39683e47c315e234e8d03bd65";

export default node;
