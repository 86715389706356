import graphql from "babel-plugin-relay/macro";
import React, { Suspense, useMemo } from "react";
import { useLazyLoadQuery } from "react-relay";

import { useDateISOState } from "../hooks";
import FlexActivityIndicator from "./FlexActivityIndicator";
import PipelineVersionInfiniteList, {
  PipelineVersionInfiniteListProps,
} from "./PipelineVersionInfiniteList";
import {
  PipelineVersionSelectorQuery,
  PipelineVersionSelectorQuery$variables,
} from "./__generated__/PipelineVersionSelectorQuery.graphql";

interface PipelineVersionSelectorProps
  extends Pick<PipelineVersionInfiniteListProps, "onSelectPipelineVersion"> {
  pipelineId: string | null | undefined;
  selectedPipelineVersionId?: string;
  refreshKey?: string;
}

const PipelineVersionSelector: React.FC<PipelineVersionSelectorProps> = ({
  pipelineId,
  refreshKey,
  selectedPipelineVersionId,
  onSelectPipelineVersion,
}) => {
  const queryVariables = useMemo<PipelineVersionSelectorQuery$variables>(
    () => ({
      first: 10,
      order: ["CREATED_AT_DESC"],
      filter: {
        pipeline: {
          exact: pipelineId,
        },
      },
    }),
    []
  );

  const [internalFetchKey, updateInternalFetchKey] = useDateISOState();

  const PipelineVersionSelectorQueryRef =
    useLazyLoadQuery<PipelineVersionSelectorQuery>(
      graphql`
        query PipelineVersionSelectorQuery(
          $first: Int!
          $after: String
          $order: [PipelineVersionOrder]
          $filter: PipelineVersionFilterInputType
        ) {
          ...PipelineVersionInfiniteListPaginationFragment
        }
      `,
      queryVariables,
      {
        fetchKey: `${refreshKey}-${internalFetchKey}`,
        fetchPolicy: "store-and-network",
      }
    );

  return (
    <Suspense fallback={<FlexActivityIndicator />}>
      <PipelineVersionInfiniteList
        pipelineVersionQueryRef={PipelineVersionSelectorQueryRef}
        queryVariables={queryVariables}
        selectedPipelineVersionId={selectedPipelineVersionId}
        onSelectPipelineVersion={onSelectPipelineVersion}
      />
    </Suspense>
  );
};

export default PipelineVersionSelector;
