/**
 * @generated SignedSource<<9b3b0306b965d21881c7caf8b6319529>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PipelineDeleteButtonFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly scheduledTask: {
    readonly expires: any | null;
  } | null;
  readonly storage: any | null;
  readonly " $fragmentType": "PipelineDeleteButtonFragment";
};
export type PipelineDeleteButtonFragment$key = {
  readonly " $data"?: PipelineDeleteButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineDeleteButtonFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineDeleteButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PeriodicTask",
      "kind": "LinkedField",
      "name": "scheduledTask",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expires",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};

(node as any).hash = "469baba3360e65db2d7359439935d6a7";

export default node;
