import {
  MenuOutlined,
  PlaySquareOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { useLocalStorageState } from "ahooks";
import { Button, Layout, Select, Typography } from "antd";
import { Grid, TourProps, theme } from "antd/lib";
import { useAtom, useAtomValue } from "jotai";
import _ from "lodash";
import { ExternalLinkIcon } from "lucide-react";
import React, { Suspense } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

import { availableProjectListAtom, projectIdAtom } from "../hooks/atoms";
import BAIMenu from "./BAIMenu";
import BAISider from "./BAISider";
import Flex from "./Flex";
import FlexActivityIndicator from "./FlexActivityIndicator";
import TourPopover from "./TourPopover";
import UserDropdown from "./UserDropdown";

const ProjectSelect = React.lazy(() => import("./ProjectSelect"));

const { Text } = Typography;

const { Content } = Layout;

function MainLayout() {
  const { token } = theme.useToken();
  const location = useLocation();
  const [sideCollapsed, setSideCollapsed] = useLocalStorageState<boolean>(
    "sideCollapsed",
    {
      defaultValue: false,
    }
  );
  const gridBreakpoint = Grid.useBreakpoint();
  const projects = useAtomValue(availableProjectListAtom);
  const [projectId, setProjectId] = useAtom(projectIdAtom);
  const steps: TourProps["steps"] = [
    {
      title: "Select a project",
      description:
        "Choose a project from the select component to filter the pipelines.",
      target: () =>
        document.getElementsByClassName("ant-select")[0]
          .parentElement as HTMLElement,
    },
    {
      title: "View filtered projects",
      description:
        "Once a project is selected, the pipelines belonging to the selected project will be listed here.",
      target: () =>
        document.getElementsByClassName(
          "infinite-scroll-component"
        )[0] as HTMLElement,
    },
  ];
  return (
    <Layout className="main-layout">
      <BAISider
        collapsed={sideCollapsed}
        onCollapse={setSideCollapsed}
        logo={
          <img
            alt="Backend.AI"
            src={"/static/manifest/backend.ai-fasttrack-text-logo.svg"}
            style={{
              width: 191,
              height: 32,
              cursor: "pointer",
            }}
          />
        }
        logoCollapsed={
          <img
            alt="Backend.AI"
            src={"/static/manifest/backend.ai-fasttrack-brand-simple.svg"}
            style={{
              width: 48,
              height: 32,
              cursor: "pointer",
            }}
          />
        }
        logoTitle={
          window.__RUNTIME_CONFIG__.FRONTEND_RUNTIME_SIDEBAR_TITLE ? (
            <>
              <span
                style={{
                  whiteSpace: "nowrap",
                }}
                dangerouslySetInnerHTML={{
                  __html: decodeURIComponent(
                    window.__RUNTIME_CONFIG__.FRONTEND_RUNTIME_SIDEBAR_TITLE
                  ),
                }}
              ></span>
            </>
          ) : (
            "FastTrack"
          )
        }
        logoTitleCollapsed={
          window.__RUNTIME_CONFIG__.FRONTEND_RUNTIME_SIDEBAR_TITLE_SHORT ??
          "FastTrack"
        }
      >
        <BAIMenu
          selectedKeys={[location.pathname.split("/")[1]]}
          items={[
            {
              label: (
                <Link to={"pipelines"} style={{ fontWeight: 400 }}>
                  Pipelines
                </Link>
              ),
              icon: <ShareAltOutlined rotate={90} />,
              key: "pipelines",
            },
            {
              label: (
                <Link to={"pipeline-jobs"} style={{ fontWeight: 400 }}>
                  Pipeline Jobs
                </Link>
              ),
              icon: <PlaySquareOutlined />,
              key: "pipeline-jobs",
            },
            {
              type: "divider",
            },
            {
              label: (
                <Flex direction="row" align="center" justify="between">
                  <a
                    href={
                      window.__RUNTIME_CONFIG__
                        .FRONTEND_RUNTIME_WEBSERVER_ENDPOINT
                    }
                    target="_blank"
                  >
                    Backend.AI WebUI
                  </a>
                  <ExternalLinkIcon color={token.colorTextDescription} />
                </Flex>
              ),
              icon: (
                <img
                  src={"/static/manifest/backend.ai-brand-simple.svg"}
                  style={{
                    width: 14,
                    minHeight: 14,
                  }}
                />
              ),
              key: "backend-ai-webui",
            },
          ]}
        />
        <Flex style={{ flex: 1 }} />
        <Flex
          justify="center"
          style={{
            width: "100%",
            padding: 20,
            textAlign: "center",
          }}
        >
          <Text type="secondary" style={{ color: "#949494", fontSize: "12px" }}>
            Lablup Inc.
          </Text>
        </Flex>
      </BAISider>
      <Layout>
        <Layout.Header
          style={{
            padding: 0,
            backgroundColor: token.colorBgBase,
          }}
        >
          <Flex direction="row" align="center" justify="between">
            <Flex align="center" justify="center">
              <Button
                style={{ marginLeft: 8 }}
                icon={<MenuOutlined />}
                type="text"
                onClick={() => {
                  setSideCollapsed((v) => !v);
                }}
              />
            </Flex>
            <Flex direction="row" align="center" justify="center">
              <TourPopover
                tourId="global-scope-project-select"
                title={<h3>Introducing project select</h3>}
                content={<p>Filter pipelines by selecting a project.</p>}
                popoverProps={{
                  placement: "bottom",
                }}
                tourProps={{ steps }}
              >
                <Flex
                  direction="row"
                  align="center"
                  justify="between"
                  gap={gridBreakpoint.md ? "sm" : "xs"}
                >
                  <Text type="secondary">Project</Text>
                  <Suspense
                    fallback={
                      <Select placeholder="Loading..." loading disabled />
                    }
                  >
                    <ProjectSelect
                      style={{
                        minWidth: 100,
                        maxWidth: gridBreakpoint.lg ? undefined : 100,
                      }}
                      size={gridBreakpoint.lg ? "large" : "middle"}
                      onSelectChange={(value) => {
                        setProjectId(
                          projects.find((p) => p.name === value)?.id ?? ""
                        );
                      }}
                      options={[
                        {
                          label: <Text type="secondary">All</Text>,
                          value: "", // NOTE: `value` in Select options should not be `null`.
                        },
                        ...projects.map((p) => ({
                          label: p.name,
                          value: p.name,
                        })),
                      ]}
                      defaultValue={projectId}
                    />
                  </Suspense>
                </Flex>
              </TourPopover>
              <UserDropdown />
            </Flex>
          </Flex>
        </Layout.Header>
        <Content>
          <Suspense fallback={<FlexActivityIndicator />}>
            <Outlet />
          </Suspense>
        </Content>
      </Layout>
    </Layout>
  );
}

export default MainLayout;
