import _ from "lodash";
import React from "react";

export interface WhiteSpaceProps {
  // style?: StyleProp<ViewStyle>;
  /* xs 4, sm 8, md 12, lg 16, xl 24, xxl 32, xxxl 40 */
  size?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl" | "xxxl";
  direction?: "row" | "column";
}

const theme: any = {
  v_spacing_xs: 4,
  v_spacing_sm: 8,
  v_spacing_md: 12,
  v_spacing_lg: 16,
  v_spacing_xl: 24,
  v_spacing_xxl: 32,
  v_spacing_xxxl: 40,
};

const WhiteSpace: React.FC<WhiteSpaceProps> = ({
  // style,
  size = "md",
  direction,
}) => {
  const spaceSize = _.isNumber(size) ? size : theme[`v_spacing_${size}`];
  return (
    <div
      style={{
        [direction === "row" ? "width" : "height"]: spaceSize,
      }}
    />
  );
};

export default WhiteSpace;
