import { atom } from "jotai";

import { baiFetch } from "../auth";
import { minifyGraphQLQuery } from "../backendai";

export interface Image {
  name: string;
  humanized_name: string;
  tag: string;
  registry: string;
  architecture: string;
  digest: string;
  installed: boolean;
  resource_limits: {
    key: string;
    min: number;
    max: number;
  }[];
  labels: {
    key: string;
    value: string;
  }[];
}

type ImageMetadata = {
  name: string;
  description: string;
  group: string;
  tags: string[];
  icon: string;
  label: {
    category?: string;
    tag: string;
    color: string;
  }[];
};

export const imageListAtom = atom(async (get) => {
  const response = await baiFetch("/func/admin/gql", {
    method: "POST",
    body: JSON.stringify({
      query: minifyGraphQLQuery(`
        query($installed: Boolean) {
          images(is_installed: $installed) {
            name
            humanized_name
            tag
            registry
            architecture
            digest
            installed
            resource_limits {
              key
              min
              max
            }
            labels {
              key
              value
            }
          }
        }
      `),
      variables: { installed: true },
    }),
  }).then((res) => res.json());
  const images: Image[] = response.data.images;
  return images.filter(
    (image) =>
      !image.labels.some(
        (label) =>
          label.key === "ai.backend.features" &&
          label.value.split(" ").includes("private")
      )
  );
});

export const imageMetadataAtom = atom(async (get) => {
  const response: {
    imageInfo: {
      [key: string]: ImageMetadata;
    };
    tagAlias: {
      [key: string]: string;
    };
    tagReplace: {
      [key: string]: string;
    };
  } = await fetch("/static/image_metadata.json").then((res) => res.json());
  return response;
});
