/**
 * @generated SignedSource<<71f7e8c96530d2ebba91a3237118adea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CrontabViewFragment$data = {
  readonly dayOfMonth: string;
  readonly dayOfWeek: string;
  readonly hour: string;
  readonly id: string;
  readonly minute: string;
  readonly monthOfYear: string;
  readonly " $fragmentType": "CrontabViewFragment";
};
export type CrontabViewFragment$key = {
  readonly " $data"?: CrontabViewFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CrontabViewFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CrontabViewFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "minute",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hour",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayOfWeek",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dayOfMonth",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "monthOfYear",
      "storageKey": null
    }
  ],
  "type": "CrontabSchedule",
  "abstractKey": null
};

(node as any).hash = "0c7f7701091534134012d9a4717945b6";

export default node;
