import { atom } from "jotai";
import { atomFamily } from "jotai/utils";
import _ from "lodash";

import { baiFetch } from "../auth";
import { VFolderHostListInfo, VirtualFolder } from "../backendai";

export const hostListInfoAtom = atom(async (get) => {
  const response = await baiFetch("/func/folders/_/hosts").then((res) =>
    res.json()
  );
  return response as VFolderHostListInfo;
});

interface FileMetadata {
  name: string;
  type: "FILE" | "DIRECTORY";
  size: number;
  mode: string; // oct
  created: string;
  modified: string;
}

// @deprecated
interface FileMetadataLegacy {
  filename: string;
  size: number;
  mode: string;
  ctime: number;
  atime: number;
  mtime: number;
}

export const fileListAtom = atomFamily(
  ({ vFolderName, path }: { vFolderName: string; path: string }) =>
    atom(async (get) => {
      if (_.isEmpty(vFolderName)) {
        return [];
      }
      const response: {
        items: FileMetadata[];
        files?: FileMetadataLegacy[];
      } = await baiFetch(
        `/func/folders/${vFolderName}/files?path=${path}`
      ).then((res) => {
        if (res.status === 404) {
          return null;
        }
        return res.json();
      });
      return response ? (response.items as FileMetadata[]) : response;
    }),
  (a, b) => _.isEqual(a, b)
);

export const folderAtom = atomFamily(
  ({ vFolderName }: { vFolderName: string }) =>
    atom(async (get) => {
      const response = await baiFetch(`/func/folders/${vFolderName}`).then(
        (res) => {
          if (res.status === 404) {
            return null;
          }
          return res.json();
        }
      );
      return response as VirtualFolder;
    }),
  (a, b) => a.vFolderName === b.vFolderName
);
