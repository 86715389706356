/**
 * @generated SignedSource<<17187f653ff2e5bc6d91c9247a46c972>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskInstanceStatusChoices = "BUILDING" | "CANCELLED" | "ERROR" | "PENDING" | "PREPARING" | "PULLING" | "RESIZING" | "RESTARTING" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "SUSPENDED" | "TERMINATED" | "TERMINATING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskInstanceStatusTag_taskInstance$data = {
  readonly status: TaskInstanceStatusChoices;
  readonly " $fragmentType": "TaskInstanceStatusTag_taskInstance";
};
export type TaskInstanceStatusTag_taskInstance$key = {
  readonly " $data"?: TaskInstanceStatusTag_taskInstance$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskInstanceStatusTag_taskInstance">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskInstanceStatusTag_taskInstance",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "TaskInstance",
  "abstractKey": null
};

(node as any).hash = "fbc746d8e42d1a8b6a9a1e89e8e6c248";

export default node;
