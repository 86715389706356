export function getLocale(): string {
  for (const locale of navigator.languages) {
    if (locale.includes("-")) {
      // e.g. 'ko-KR', 'en-US'
      return locale.split("-")[0];
    }
    return locale; // e.g. 'ko', 'en'
  }
  return "en";
}
