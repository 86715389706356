/**
 * @generated SignedSource<<407bcb0b51d705d1d1628418535e9bf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PipelineJobStatusChoices = "CANCELLED" | "CANCELLING" | "ERROR" | "PAUSED" | "PENDING" | "RUNNING" | "TERMINATED" | "WAITING" | "%future added value";
export type TaskInstanceResultChoices = "FAILURE" | "SUCCESS" | "UNDEFINED" | "%future added value";
export type TaskInstanceStatusChoices = "BUILDING" | "CANCELLED" | "ERROR" | "PENDING" | "PREPARING" | "PULLING" | "RESIZING" | "RESTARTING" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "SUSPENDED" | "TERMINATED" | "TERMINATING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PipelineJobProgressFragment$data = {
  readonly status: PipelineJobStatusChoices;
  readonly taskinstanceSet: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly result: TaskInstanceResultChoices;
        readonly status: TaskInstanceStatusChoices;
      } | null;
    } | null>;
    readonly totalCount: number;
  };
  readonly " $fragmentType": "PipelineJobProgressFragment";
};
export type PipelineJobProgressFragment$key = {
  readonly " $data"?: PipelineJobProgressFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineJobProgressFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineJobProgressFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskInstanceConnection",
      "kind": "LinkedField",
      "name": "taskinstanceSet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskInstanceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskInstance",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "result",
                  "storageKey": null
                },
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PipelineJob",
  "abstractKey": null
};
})();

(node as any).hash = "2d8ca82148ba2a1afb5320598ef5c4d4";

export default node;
