import { SiderProps, Typography, theme } from "antd";
import Sider from "antd/es/layout/Sider";
import React from "react";
import { Link } from "react-router-dom";

import Flex from "./Flex";

interface BAISiderProps extends SiderProps {
  // logo?: React.ReactNode;
  logoCollapsed?: React.ReactNode;
  logo?: React.ReactNode;
  logoTitleCollapsed?: React.ReactNode;
  logoTitle?: React.ReactNode;
}

const DEFAULT_COLLAPSED_WIDTH = 74;

const BAISider: React.FC<BAISiderProps> = ({
  children,
  logo,
  logoCollapsed,
  logoTitle,
  logoTitleCollapsed,
  ...otherProps
}) => {
  const { token } = theme.useToken();
  return (
    <>
      <style>
        {`
          .bai-sider .ant-layout-sider-children {
            display: flex;
            flex-direction: column;
          }

          .bai-sider::-webkit-scrollbar {
            width: 0px;
          }
          
          .bai-sider::-webkit-scrollbar-track {
            background: transparent; 
          }
          
          .bai-sider::-webkit-scrollbar-thumb {
            background: transparent;
          }
        `}
      </style>
      <Sider
        collapsedWidth={DEFAULT_COLLAPSED_WIDTH}
        width={221}
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          height: "100vh",
          position: "sticky",
          top: 0,
          left: 0,
          borderRight: "1px solid",
          borderColor: token.colorBorder,
          backgroundColor: "#F4F7FD",
          paddingTop: token.paddingContentVerticalSM,
          scrollbarColor: "auto",
        }}
        {...otherProps}
        className="bai-sider"
      >
        <Link to="/">
          <Flex
            direction="column"
            justify="start"
            align="start"
            style={{
              padding: otherProps.collapsed
                ? `${12 + token.marginSM}px 12px 12px 12px`
                : `${12 + token.marginSM}px 16px 12px 16px`,
              overflow: "visible",
              transition: "all 0.2s ease-in-out",
              marginBottom: token.marginSM,
            }}
            className={"logo-and-text-container"}
          >
            <div className="logo-img-wrap">
              <div style={{ display: otherProps.collapsed ? "none" : "block" }}>
                {logo}
              </div>
              <div style={{ display: otherProps.collapsed ? "block" : "none" }}>
                {logoCollapsed}
              </div>
            </div>
            <div className="logo-title-wrap" style={{ marginTop: 6 }}>
              <Typography.Text
                style={{
                  fontSize: otherProps.collapsed ? 13 : 16,
                  lineHeight: "16px",
                  fontWeight: 200,
                  whiteSpace: "nowrap",
                }}
              >
                {otherProps.collapsed ? logoTitleCollapsed : logoTitle}
              </Typography.Text>
            </div>
          </Flex>
        </Link>
        {children}
      </Sider>
    </>
  );
};

export default BAISider;
