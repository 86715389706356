/**
 * @generated SignedSource<<6820ed4cc103724771cd170833f2968e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RunPipelineModalFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly scheduledTask: {
    readonly crontab: {
      readonly dayOfMonth: string;
      readonly dayOfWeek: string;
      readonly hour: string;
      readonly minute: string;
      readonly monthOfYear: string;
    } | null;
    readonly expires: any | null;
  } | null;
  readonly yaml: string;
  readonly " $fragmentType": "RunPipelineModalFragment";
};
export type RunPipelineModalFragment$key = {
  readonly " $data"?: RunPipelineModalFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"RunPipelineModalFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RunPipelineModalFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yaml",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PeriodicTask",
      "kind": "LinkedField",
      "name": "scheduledTask",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "CrontabSchedule",
          "kind": "LinkedField",
          "name": "crontab",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minute",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hour",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dayOfMonth",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "monthOfYear",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dayOfWeek",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expires",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
};

(node as any).hash = "305af545731faadfe2785a0b6cce79be";

export default node;
