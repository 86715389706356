import {
  LockOutlined,
  OneToOneOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Collapse, Descriptions, Segmented, Typography } from "antd";
import graphql from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React from "react";
import { useFragment } from "react-relay";

import { from_global_id } from "../helpers";
import Flex from "./Flex";
import { PipelineVersionSummaryFragment$key } from "./__generated__/PipelineVersionSummaryFragment.graphql";

interface PipelineVersionSummaryProps {
  onClickCodeEditor?: () => void;
  pipelineVersionFrgmt: PipelineVersionSummaryFragment$key | null;
}

const PipelineVersionSummary: React.FC<PipelineVersionSummaryProps> = ({
  onClickCodeEditor,
  pipelineVersionFrgmt,
}) => {
  const pipelineVersion = useFragment(
    graphql`
      fragment PipelineVersionSummaryFragment on PipelineVersion {
        id
        yaml
        author {
          username
        }
        createdAt
      }
    `,
    pipelineVersionFrgmt
  );

  const shortVersionId = from_global_id(pipelineVersion?.id || "")[1]?.split(
    "-"
  )[0];

  return (
    <div>
      <Collapse expandIconPosition="end">
        <Collapse.Panel
          header={
            <Flex direction="row" justify="between">
              <Typography.Text>
                <LockOutlined /> Pipeline Version Summary
              </Typography.Text>
              <Segmented
                value={"graph"}
                onChange={(value) => {
                  onClickCodeEditor?.();
                }}
                options={[
                  {
                    label: "Graph",
                    value: "graph",
                    icon: <ShareAltOutlined rotate={90} />,
                  },
                  {
                    label: "YAML",
                    value: "yaml",
                    icon: <OneToOneOutlined />,
                  },
                ]}
              />
              {/* <Button icon={<OneToOneOutlined />} onClick={onClickCodeEditor}>
                YAML
              </Button> */}
            </Flex>
          }
          key="1"
        >
          <Descriptions bordered>
            <Descriptions.Item label="id">
              <Typography.Text
                style={{
                  fontFamily: "monospace",
                }}
              >
                {shortVersionId}
              </Typography.Text>
            </Descriptions.Item>
            <Descriptions.Item label="Created By">
              {pipelineVersion?.author?.username}
            </Descriptions.Item>
            <Descriptions.Item label="Created At">
              <Typography.Text
                style={{
                  fontFamily: "monospace",
                }}
              >
                {dayjs(pipelineVersion?.createdAt).utc(true).format("lll")}
              </Typography.Text>
            </Descriptions.Item>
          </Descriptions>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

export default PipelineVersionSummary;
