/**
 * @generated SignedSource<<67523398d060f3488ddaa7c1776ca516>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdatePipelineInput = {
  clientMutationId?: string | null;
  dataflow?: PipelineDataflowInput | null;
  description?: string | null;
  environment?: PipelineEnvironmentInput | null;
  id: string;
  isActive?: boolean | null;
  name?: string | null;
  yaml?: string | null;
};
export type PipelineEnvironmentInput = {
  envs?: any | null;
};
export type PipelineDataflowInput = {
  nodes?: ReadonlyArray<PipelineNodeInput | null> | null;
  viewport?: PipelineViewportInput | null;
};
export type PipelineNodeInput = {
  data?: PipelineNodeDataInput | null;
  id: string;
  position?: PipelineNodePositionInput | null;
};
export type PipelineNodeDataInput = {
  label?: string | null;
  task?: PipelineNodeTaskInput | null;
};
export type PipelineNodeTaskInput = {
  name?: string | null;
};
export type PipelineNodePositionInput = {
  x?: number | null;
  y?: number | null;
};
export type PipelineViewportInput = {
  x?: number | null;
  y?: number | null;
  zoom?: number | null;
};
export type PipelineEditorModalUpdateMutation$variables = {
  input: UpdatePipelineInput;
};
export type PipelineEditorModalUpdateMutation$data = {
  readonly updatePipeline: {
    readonly pipeline: {
      readonly __typename: "Pipeline";
      readonly description: string;
      readonly id: string;
      readonly lastModified: any;
      readonly name: string;
      readonly storage: any | null;
      readonly yaml: string;
    } | {
      readonly __typename: "UnauthenticatedError";
      readonly message: string | null;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null;
  } | null;
};
export type PipelineEditorModalUpdateMutation = {
  response: PipelineEditorModalUpdateMutation$data;
  variables: PipelineEditorModalUpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastModified",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "storage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yaml",
      "storageKey": null
    }
  ],
  "type": "Pipeline",
  "abstractKey": null
},
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UnauthenticatedError",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PipelineEditorModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePipelinePayload",
        "kind": "LinkedField",
        "name": "updatePipeline",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PipelineEditorModalUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdatePipelinePayload",
        "kind": "LinkedField",
        "name": "updatePipeline",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ea0e7f6bd280732aefddf3cdd2276526",
    "id": null,
    "metadata": {},
    "name": "PipelineEditorModalUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation PipelineEditorModalUpdateMutation(\n  $input: UpdatePipelineInput!\n) {\n  updatePipeline(input: $input) {\n    pipeline {\n      __typename\n      ... on Pipeline {\n        id\n        name\n        description\n        lastModified\n        storage\n        yaml\n      }\n      ... on UnauthenticatedError {\n        message\n      }\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "33870704d76d001fcdb42386680b6a1f";

export default node;
