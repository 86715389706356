import { DownOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import { MenuProps } from "antd/lib";
import { LucideMoreHorizontal } from "lucide-react";
import React from "react";
import { Handle, NodeProps, Position } from "reactflow";

import Flex from "../Flex";
import { TaskNodeData } from "../PipelineYamlEditor";
import "./TaskNode.css";
import TaskNodeDeletePopconfirm from "./TaskNodeDeletePopconfirm";

const handleStyle: React.CSSProperties = {
  height: "14px",
  width: "25px",
  background: "#ddd",
  border: "1px solid #333",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const TaskNode: React.FC<NodeProps<TaskNodeData>> = ({ data }) => {
  const items: MenuProps["items"] = [
    {
      label: <Button type="text">Duplicate</Button>,
      key: "0",
      style: { padding: 0, margin: "5px 0px" },
      onClick: () => data.onDuplicate?.(),
    },
    {
      label: (
        <TaskNodeDeletePopconfirm onConfirm={data.onDelete}>
          <Button
            type="text"
            style={{ width: "100%" }}
            onClick={(e) => e.stopPropagation()}
            danger
          >
            Delete
          </Button>
        </TaskNodeDeletePopconfirm>
      ),
      key: "1",
      style: { padding: 0, margin: "5px 0px" },
    },
  ];
  return (
    <>
      {data.isEditable ? (
        <Dropdown
          menu={{ items }}
          trigger={["click"]}
          placement={"bottomRight"}
        >
          <Button
            className="nodrag"
            type="text"
            icon={
              <LucideMoreHorizontal
                style={{
                  color: "rgba(0, 0, 0, 0.45)",
                }}
              />
            }
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              height: 18,
              width: 24,
            }}
          />
        </Dropdown>
      ) : null}
      <Handle
        type="target"
        position={Position.Top}
        style={{
          ...handleStyle,
        }}
      />
      <Flex direction="row" align="center" justify="center" gap={"xs"}>
        {data.isMissingRequired ? (
          <ExclamationCircleFilled style={{ color: "#EFB041" }} />
        ) : null}
        {data.label}
      </Flex>
      <Handle type="source" position={Position.Bottom} style={handleStyle}>
        <DownOutlined
          style={{ fontSize: 8, color: "#666", pointerEvents: "none" }}
        />
      </Handle>
    </>
  );
};

export default TaskNode;
