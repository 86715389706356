import { useControllableValue } from "ahooks";
import { Select } from "antd";
import { SelectProps } from "antd/lib";
import { useAtomValue } from "jotai";
import _ from "lodash";
import React from "react";

import { hostListInfoAtom } from "../hooks/atoms";

interface VirtualFolderHostSelectProps extends Omit<SelectProps, "options"> {
  filter?: (hostName: string) => boolean;
}
const VirtualFolderHostSelect: React.FC<VirtualFolderHostSelectProps> = ({
  filter,
  ...props
}) => {
  const hostListInfo = useAtomValue(hostListInfoAtom);
  const [value, setValue] = useControllableValue<string>(props);
  return (
    <Select
      {...props}
      value={value}
      onChange={(value) => {
        setValue(value);
      }}
      options={_.chain(hostListInfo.allowed)
        .filter(filter)
        .map((hostName) => ({
          label: hostName,
          value: hostName,
        }))
        .value()}
    />
  );
};

export default VirtualFolderHostSelect;
