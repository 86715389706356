import {
  PartitionOutlined,
  PlayCircleOutlined,
  ScheduleOutlined,
} from "@ant-design/icons";
import { useUpdateEffect } from "ahooks";
import { Divider, List, Skeleton, Space, Tooltip, Typography } from "antd";
import { theme } from "antd/lib";
import graphql from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { default as YAML } from "js-yaml";
import _ from "lodash";
import { LucideUsersRound } from "lucide-react";
import React, { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { usePaginationFragment } from "react-relay";

import { PipelineListQuery$variables } from "../screens/__generated__/PipelineListQuery.graphql";
import Flex from "./Flex";
import styles from "./PipelineInfiniteList.module.css";
import { PipelineYAML } from "./PipelineYamlEditor";
import {
  PipelineInfiniteListPaginationFragment$data,
  PipelineInfiniteListPaginationFragment$key,
} from "./__generated__/PipelineInfiniteListPaginationFragment.graphql";
import { PipelineInfiniteListPaginationQuery } from "./__generated__/PipelineInfiniteListPaginationQuery.graphql";

const { Text } = Typography;

export type PipelineNodeType = NonNullableNodeOnEdges<
  PipelineInfiniteListPaginationFragment$data["pipelines"]
>;

const PipelineInfiniteList: React.FC<{
  pipelineQueryRef: any;
  selectedPipelineId?: string | null;
  onSelectPipeline: (pipeline: PipelineNodeType) => void;
  // search?: string;
  queryVariables: PipelineListQuery$variables;
  // filter?: PipelineFilterInputType;
}> = ({
  pipelineQueryRef,
  selectedPipelineId,
  onSelectPipeline,
  queryVariables,
}) => {
  const { token } = theme.useToken();
  const pipelinePagination = usePaginationFragment<
    PipelineInfiniteListPaginationQuery,
    PipelineInfiniteListPaginationFragment$key
  >(
    graphql`
      fragment PipelineInfiniteListPaginationFragment on Query
      @refetchable(queryName: "PipelineInfiniteListPaginationQuery") {
        pipelines(first: $first, after: $after, order: $order, filter: $filter)
          @connection(key: "PipelineList_pipelines") {
          __id
          totalCount
          edges {
            node {
              id
              name
              createdAt
              description
              lastModified
              yaml
              pipelinejobSet {
                totalCount
              }
              ...PipelineSummaryFragment
              scheduledTask {
                id
                expires
              }
            }
          }
        }
      }
    `,
    pipelineQueryRef
  );

  useUpdateEffect(() => {
    pipelinePagination.refetch(queryVariables, {
      fetchPolicy: "network-only",
    });
  }, [JSON.stringify(queryVariables)]);

  const yamlObjMap: {
    [key: string]: PipelineYAML;
  } = useMemo(() => {
    return _.reduce(
      pipelinePagination.data.pipelines?.edges,
      (acc, edge) => {
        return edge?.node?.id
          ? {
              ...acc,
              [edge.node.id]: YAML.load(edge.node.yaml || "") as PipelineYAML,
            }
          : acc;
      },
      {}
    );
  }, [pipelinePagination.data.pipelines?.edges]);

  return (
    <InfiniteScroll
      dataLength={pipelinePagination.data.pipelines?.edges.length || 0}
      next={() => {
        pipelinePagination.loadNext(20);
      }}
      hasMore={pipelinePagination.hasNext}
      loader={
        <Skeleton
          paragraph={{ rows: 1 }}
          style={{ padding: "8px 10px" }}
          active
        />
      }
      endMessage={
        <Divider plain>
          Total: {pipelinePagination.data.pipelines?.totalCount ?? 0}
        </Divider>
      }
      scrollableTarget="scrollableDiv"
    >
      <List
        itemLayout="horizontal"
        dataSource={
          _.map(
            pipelinePagination.data.pipelines?.edges,
            (e) => e?.node
          ) as PipelineNodeType[]
        }
        renderItem={(item) => {
          const isSelected = item?.id === selectedPipelineId;
          const pipelineYaml = YAML.load(item.yaml || "") as PipelineYAML;
          return (
            <List.Item
              className={isSelected ? styles.itemSelected : styles.itemNormal}
              key={item.id}
              style={{
                cursor: "pointer",
              }}
              extra={<span style={{ fontSize: 12 }}></span>}
              onClick={() => {
                onSelectPipeline(item);
              }}
            >
              <List.Item.Meta
                // avatar={
                //   <Avatar
                //     className={
                //       isSelected ? styles.avatarSelected : styles.avatarNormal
                //     }
                //   >
                //     {toInitialString(item.name)}
                //   </Avatar>
                // }
                className={styles.itemMetaNormal}
                title={
                  <Flex direction="row" align="center" justify="start">
                    <div style={{ fontWeight: 400 }}>{item.name}</div>
                    {pipelineYaml?.ownership?.scope === "project" ? (
                      <Tooltip title={pipelineYaml.ownership.project}>
                        <LucideUsersRound
                          style={{ marginLeft: 8 }}
                          color={token.colorTextSecondary}
                        />
                      </Tooltip>
                    ) : null}
                  </Flex>
                }
                // description={item.description || <span>&nbsp;</span>}
                description={
                  <Text type="secondary" style={{ fontSize: 12 }}>
                    <Flex direction="row" justify="between">
                      <Text type="secondary">
                        {/* <PartitionOutlined />{" "}
                    {item.pipelinejobSet.totalCount}
                    &nbsp;&nbsp; */}
                        <Space>
                          <span>
                            <PartitionOutlined />
                            &nbsp;
                            {yamlObjMap[item.id]?.tasks?.length?.toString() ||
                              "0"}
                          </span>
                          <span>
                            <PlayCircleOutlined />
                            &nbsp;{item.pipelinejobSet?.totalCount ?? 0}
                          </span>
                          {item.scheduledTask ? (
                            <ScheduleOutlined
                              style={
                                dayjs(item?.scheduledTask?.expires).isBefore(
                                  new Date()
                                )
                                  ? {
                                      opacity: 0.3,
                                    }
                                  : {}
                              }
                            />
                          ) : null}
                        </Space>
                      </Text>
                      <Text type="secondary">
                        {dayjs(item?.lastModified).fromNow()}
                      </Text>
                    </Flex>
                  </Text>
                }
              ></List.Item.Meta>
            </List.Item>
          );
        }}
      />
    </InfiniteScroll>
  );
};

export const PipelineInfiniteListFallback = () => {
  return (
    <Flex direction="column">
      {_.map(_.range(5), (i) => (
        <Skeleton
          key={`${i}`}
          paragraph={{ rows: 1 }}
          style={{ padding: "8px 10px" }}
          active
        />
      ))}
    </Flex>
  );
};

export default PipelineInfiniteList;
