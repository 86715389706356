import { Alert } from "antd";

export const USER_AGENT_MATCHER = {
  Edge: /Edg[A]*\/(\d+[.\d+]*)/i,
  Opera: /OPR\/(\d+[.\d]*)/i,
  Chrome: /Chrome\/(\d+[.\d+]*)/i,
  Safari: /Version\/(\d+[.\d+]*)/i,
  Firefox: /Firefox\/(\d+[.\d+]*)/i,
};

const OutdatedBrowserAlert = () => {
  const userAgent = navigator.userAgent;
  let isOutdatedBrowser = false;
  let browserName = "";
  let browserVersion = "";
  let requiredBrowserVersion = 0;

  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
  if (userAgent.includes("Edg")) {
    // Edge: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36 Edg/111.0.1661.54
    // Mobile: Mozilla/5.0 (Linux; Android 13; SM-S906N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Mobile Safari/537.36 EdgA/111.0.1661.59
    browserName = "Edge";
    const regexpMatchArray = userAgent.match(USER_AGENT_MATCHER.Edge);
    browserVersion = regexpMatchArray ? regexpMatchArray[1] : "0";
    const edgeVersion = parseInt(browserVersion);
    requiredBrowserVersion = parseInt(
      window.__RUNTIME_CONFIG__.MINIMUM_SUPPORTED_BROWSER_VERSIONS[
        "edge"
      ].toString()
    );
    isOutdatedBrowser = edgeVersion < requiredBrowserVersion;
  } else if (userAgent.includes("Opera")) {
    //
  } else if (userAgent.includes("OPR")) {
    // Opera: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36 OPR/97.0.0.0
    browserName = "Opera";
    const regexpMatchArray = userAgent.match(USER_AGENT_MATCHER.Opera);
    browserVersion = regexpMatchArray ? regexpMatchArray[1] : "0";
    const operaVersion = parseInt(browserVersion);
    requiredBrowserVersion = parseInt(
      window.__RUNTIME_CONFIG__.MINIMUM_SUPPORTED_BROWSER_VERSIONS[
        "opera"
      ].toString()
    );
    isOutdatedBrowser = operaVersion < requiredBrowserVersion;
  } else if (userAgent.includes("Chrome")) {
    // Chrome: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Safari/537.36
    // Mobile: Mozilla/5.0 (Linux; Android 13; SM-S906N) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/111.0.0.0 Mobile Safari/537.36
    browserName = "Chrome";
    const regexpMatchArray = userAgent.match(USER_AGENT_MATCHER.Chrome);
    browserVersion = regexpMatchArray ? regexpMatchArray[1] : "0";
    const chromeVersion = parseInt(browserVersion);
    requiredBrowserVersion = parseInt(
      window.__RUNTIME_CONFIG__.MINIMUM_SUPPORTED_BROWSER_VERSIONS[
        "chrome"
      ].toString()
    );
    isOutdatedBrowser = chromeVersion < requiredBrowserVersion;
  } else if (userAgent.includes("Safari")) {
    // Safari: Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/16.3 Safari/605.1.15 (Safari)
    browserName = "Safari";
    const regexpMatchArray = userAgent.match(USER_AGENT_MATCHER.Safari);
    browserVersion = regexpMatchArray ? regexpMatchArray[1] : "0";
    const safariVersion = parseFloat(browserVersion);
    requiredBrowserVersion = parseFloat(
      window.__RUNTIME_CONFIG__.MINIMUM_SUPPORTED_BROWSER_VERSIONS[
        "safari"
      ].toString()
    );
    isOutdatedBrowser = safariVersion < requiredBrowserVersion;
  } else if (userAgent.includes("Firefox")) {
    // Firefox: Mozilla/5.0 (Macintosh; Intel Mac OS X 10.15; rv:109.0) Gecko/20100101 Firefox/111.0
    browserName = "Firefox";
    const regexpMatchArray = userAgent.match(USER_AGENT_MATCHER.Firefox);
    browserVersion = regexpMatchArray ? regexpMatchArray[1] : "0";
    const firefoxVersion = parseInt(browserVersion);
    requiredBrowserVersion = parseInt(
      window.__RUNTIME_CONFIG__.MINIMUM_SUPPORTED_BROWSER_VERSIONS[
        "firefox"
      ].toString()
    );
    isOutdatedBrowser = firefoxVersion < requiredBrowserVersion;
  }

  return (
    <>
      {isOutdatedBrowser && (
        <Alert
          type="error"
          message="Your browser is out-of-date."
          description={`Please update the browser to ${browserName}/${requiredBrowserVersion}. (Current: ${browserName}/${browserVersion})`}
          showIcon
          closable
        />
      )}
    </>
  );
};

export default OutdatedBrowserAlert;
