import {
  ClockCircleOutlined,
  EditOutlined,
  OneToOneOutlined,
  PlayCircleOutlined,
  ScheduleOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import { useToggle } from "ahooks";
import {
  Button,
  Collapse,
  Descriptions,
  Space,
  Tooltip,
  Typography,
  message,
  theme,
} from "antd";
import { DescriptionsProps } from "antd/lib";
import graphql from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { useAtom, useAtomValue } from "jotai";
import { default as YAML } from "js-yaml";
import _ from "lodash";
import { LucideUsersRound } from "lucide-react";
import React, { useState } from "react";
import { ConnectionHandler, useFragment } from "react-relay";
import { Link, useNavigate } from "react-router-dom";
import { StringParam, useQueryParams } from "use-query-params";

import { from_global_id } from "../helpers";
import { activeKeyAtom, folderAtom, projectAtom } from "../hooks/atoms";
import { PipelineFilterInputType } from "../screens/__generated__/PipelineListQuery.graphql";
import CrontabView from "./CrontabView";
import Flex from "./Flex";
import { FolderExplorerButton } from "./FolderExplorer/FolderExplorer";
import PipelineDeleteButton from "./PipelineDeleteButton";
import PipelineEditorModal from "./PipelineEditorModal";
import { PipelineYAML } from "./PipelineYamlEditor";
import RunPipelineModal from "./RunPipelineModal";
import WhiteSpace from "./WhiteSpace";
import { PipelineDeleteButtonDeleteMutation$data } from "./__generated__/PipelineDeleteButtonDeleteMutation.graphql";
import { PipelineSummaryFragment$key } from "./__generated__/PipelineSummaryFragment.graphql";

const { Text } = Typography;
const { Panel } = Collapse;

interface Props {
  className?: string;
  style?: React.CSSProperties;
  pipelineFrgmt: PipelineSummaryFragment$key | null;
  children?: React.ReactNode;
  disableRun?: boolean;
  onClickCodeEditor?: () => void;
}

const PipelineSummary: React.FC<Props> = ({
  style,
  className,
  pipelineFrgmt,
  children,
  disableRun,
  onClickCodeEditor,
}) => {
  const [activeKey, setActiveKey] = useAtom(activeKeyAtom);
  const [visibleEditor, { toggle: toggleVisibleEditor }] = useToggle(false);
  const [runModalMode, setRunModalMode] = useState<
    undefined | "schedule" | "run"
  >();
  const { token } = theme.useToken();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useQueryParams({
    pipelineVersionId: StringParam,
  });

  const project = useAtomValue(projectAtom);

  const defaultQueryFilter: PipelineFilterInputType = {
    ...(project !== undefined
      ? {
          project: { exact: project.name },
        }
      : {}),
  };

  const isReadOnly = queryParams.pipelineVersionId !== undefined;

  const pipeline = useFragment(
    graphql`
      fragment PipelineSummaryFragment on Pipeline {
        id
        name
        description
        lastModified
        isActive
        storage
        yaml
        owner {
          username
          name
        }
        pipelinejobSet {
          totalCount
        }
        pipelineversionSet {
          totalCount
          edges {
            node {
              id
              createdAt
            }
          }
        }
        scheduledTask {
          crontab {
            ...CrontabViewFragment
          }
          expires
          expireSeconds
        }
        ...PipelineEditorModalFragment
        ...RunPipelineModalFragment
        ...PipelineDeleteButtonFragment
      }
    `,
    pipelineFrgmt
  );

  const pipelineId = pipeline?.id || "";

  const pipelineYaml = YAML.load(pipeline?.yaml || "") as PipelineYAML;

  const pipelineVersion = isReadOnly
    ? pipeline?.pipelineversionSet.edges
        .map((pipelineVersion) => pipelineVersion?.node)
        .find((node) => node?.id === queryParams.pipelineVersionId)
    : null;

  const isExpiredSchedule = dayjs(pipeline?.scheduledTask?.expires).isBefore(
    new Date()
  );

  const vFolderName = JSON.parse(pipeline?.storage).name;
  const vFolder = useAtomValue(folderAtom({ vFolderName }));

  const descriptionsItems: DescriptionsProps["items"] = [
    {
      label: "Id",
      children: (
        <Text style={{ maxWidth: 150 }} copyable ellipsis>
          {from_global_id(pipelineId)[1]}
        </Text>
      ),
    },
    pipelineYaml?.ownership?.scope === "project"
      ? {
          label: "Project",
          children: (
            <Flex direction="row" align="center" justify="start">
              <LucideUsersRound
                style={{ marginRight: 8 }}
                color={token.colorTextSecondary}
              />
              {pipelineYaml.ownership.project}
            </Flex>
          ),
        }
      : {
          label: "Owner",
          children: pipeline?.owner.username,
        },
    {
      label: "Last Modified",
      children: isReadOnly
        ? dayjs(pipelineVersion?.createdAt).format("lll")
        : dayjs(pipeline?.lastModified).format("lll"),
    },
    {
      label: "Version",
      children: (
        <Flex justify="between">
          <Typography.Link
            onClick={() => {
              // to version screen without selected version
              setQueryParams({ pipelineVersionId: "NONE" });
            }}
          >
            {pipeline?.pipelineversionSet.totalCount} version
            {(pipeline?.pipelineversionSet.totalCount ?? 0 > 1) ? "s" : ""}
          </Typography.Link>
        </Flex>
      ),
    },
    {
      label: (
        <>
          <ClockCircleOutlined />
          &nbsp; Job
        </>
      ),
      children: (
        <Link
          to={`/pipeline-jobs?pipelineId=${encodeURIComponent(pipelineId)}`}
        >
          {pipeline?.pipelinejobSet.totalCount}
        </Link>
      ),
    },
    {
      label: "Virtual Folder",
      children: (
        <Flex direction="row" gap="xs">
          {" "}
          {/** TODO: Component (PipelineJobDetail) */}
          <FolderExplorerButton
            folderName={vFolderName}
            disabled={_.isNil(vFolder)}
          >
            {vFolderName}
          </FolderExplorerButton>
          {_.isNil(vFolder) ? (
            <Tooltip title="The dedicated virtual folder for the pipeline doesn't exist.">
              <WarningTwoTone twoToneColor={token.colorWarning} />
            </Tooltip>
          ) : null}
        </Flex>
      ),
    },
  ];

  return (
    <div
      className={className}
      style={{
        boxShadow: "0 0 4px #eee",
        ...style,
      }}
    >
      <Collapse
        activeKey={activeKey}
        expandIconPosition="end"
        onChange={setActiveKey}
      >
        <Panel
          header={
            <Flex justify="between" align="center">
              <Flex direction="row" align="center">
                <Text style={{ fontWeight: 500 }}>{pipeline?.name}</Text>
                &nbsp;
                <Tooltip title="Edit title and description">
                  <Button
                    type="text"
                    icon={<EditOutlined />}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleVisibleEditor();
                    }}
                  />
                </Tooltip>
                <PipelineDeleteButton
                  pipelineFrgmt={pipeline}
                  containerProps={{
                    // to prevent toggle collapse panel
                    onClick: (e) => e.stopPropagation(),
                  }}
                  connections={[
                    ConnectionHandler.getConnectionID(
                      "root",
                      "PipelineList_pipelines",
                      {
                        filter: defaultQueryFilter,
                        order: ["LAST_MODIFIED_DESC"],
                      }
                    ),
                  ]}
                  onCompleted={(
                    response: PipelineDeleteButtonDeleteMutation$data
                  ) => {
                    if (response.destroyPipeline?.error === null) {
                      if (
                        document.location.pathname ===
                        `/pipelines/${response.destroyPipeline.pipeline?.id}`
                      ) {
                        navigate("/pipelines");
                      }
                      return;
                    }
                    switch (response.destroyPipeline?.error?.__typename) {
                      case "UnauthenticatedError":
                      case "ScheduleExistsError":
                        message.error(response.destroyPipeline.error.message);
                        break;
                      default:
                        break;
                    }
                  }}
                  onError={(error: Error) => {
                    message.error(error.message);
                  }}
                />
              </Flex>
              <Flex onClick={(e) => e.stopPropagation()}>
                <Button icon={<OneToOneOutlined />} onClick={onClickCodeEditor}>
                  YAML Editor
                </Button>
                <WhiteSpace direction="row" />
                {pipeline?.scheduledTask ? (
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      setRunModalMode("schedule");
                    }}
                    disabled={isReadOnly}
                  >
                    <ScheduleOutlined />
                    <Tooltip
                      title={
                        isExpiredSchedule
                          ? `Expired at ${dayjs(
                              pipeline?.scheduledTask?.expires
                            ).format("lll")}`
                          : ""
                      }
                    >
                      <Text delete={isExpiredSchedule}>
                        Scheduled :{" "}
                        <CrontabView
                          crontabFrgmt={pipeline.scheduledTask.crontab}
                        />
                      </Text>
                    </Tooltip>
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    icon={<PlayCircleOutlined />}
                    onClick={() => {
                      setRunModalMode("run");
                    }}
                    disabled={isReadOnly || disableRun}
                  >
                    Run
                  </Button>
                )}
              </Flex>
            </Flex>
          }
          key="1"
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            {pipeline?.description ? (
              <Text type="secondary">{pipeline?.description}</Text>
            ) : null}
            <Descriptions
              size="small"
              bordered
              column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 2, xxl: 3 }}
              items={descriptionsItems}
            />
          </Space>
        </Panel>
      </Collapse>
      {children}
      <PipelineEditorModal
        pipelineFrgmt={pipeline}
        open={visibleEditor}
        onRequestClose={(pipelineId, type) => {
          toggleVisibleEditor();
        }}
      />
      <RunPipelineModal
        pipelineFgmt={pipeline}
        open={runModalMode !== undefined}
        onRequestClose={() => {
          setRunModalMode(undefined);
        }}
        disableRun={disableRun}
        destroyOnClose={true}
      />
    </div>
  );
};

export default PipelineSummary;
