/**
 * @generated SignedSource<<f6f8964bb780096a332d0f89dbb905bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaskInstanceResultChoices = "FAILURE" | "SUCCESS" | "UNDEFINED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TaskInstanceResultIcon_taskInstance$data = {
  readonly result: TaskInstanceResultChoices;
  readonly " $fragmentType": "TaskInstanceResultIcon_taskInstance";
};
export type TaskInstanceResultIcon_taskInstance$key = {
  readonly " $data"?: TaskInstanceResultIcon_taskInstance$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskInstanceResultIcon_taskInstance">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskInstanceResultIcon_taskInstance",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "result",
      "storageKey": null
    }
  ],
  "type": "TaskInstance",
  "abstractKey": null
};

(node as any).hash = "c8c725c575b5c7c210bea911ac949e22";

export default node;
