import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Tooltip, Typography, message } from "antd";
import { ButtonProps } from "antd/es/button";
import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment, useMutation } from "react-relay";

import { isFinalStatus } from "../helpers";
import { PipelineJobCancelButtonCancelMutation } from "./__generated__/PipelineJobCancelButtonCancelMutation.graphql";
import { PipelineJobCancelButtonFragment$key } from "./__generated__/PipelineJobCancelButtonFragment.graphql";

const { Text } = Typography;

interface Props extends ButtonProps {
  pipelineJobFragment: PipelineJobCancelButtonFragment$key;
  children?: React.ReactNode;
}

const PipelineJobCancelButton: React.FC<Props> = ({
  pipelineJobFragment,
  ...props
}) => {
  const pipelineJob = useFragment(
    graphql`
      fragment PipelineJobCancelButtonFragment on PipelineJob {
        id
        name
        status
        taskinstanceSet {
          edges {
            node {
              status
            }
          }
        }
      }
    `,
    pipelineJobFragment
  );

  const [commitCancel, isInFlightCommitCancel] =
    useMutation<PipelineJobCancelButtonCancelMutation>(graphql`
      mutation PipelineJobCancelButtonCancelMutation(
        $input: CancelPipelineJobInput!
      ) {
        cancelPipelineJob(input: $input) {
          pipelineJob {
            __typename
            ... on PipelineJob {
              id
              status
            }
            ... on UnauthenticatedError {
              message
            }
            ... on NotActivePipelineJobError {
              message
            }
          }
        }
      }
    `);

  const cancelPipelineJob = () => {
    if (pipelineJob) {
      return new Promise((resolve, reject) => {
        commitCancel({
          variables: {
            input: {
              id: pipelineJob.id,
            },
          },
          // https://relay.dev/docs/guided-tour/updating-data/graphql-mutations/#optimistic-response
          optimisticResponse: {
            cancelPipelineJob: {
              pipelineJob: {
                __typename: "PipelineJob",
                id: pipelineJob.id,
                status: "CANCELLING",
              },
            },
          },
          onCompleted(response) {
            switch (response.cancelPipelineJob?.pipelineJob?.__typename) {
              case "PipelineJob":
                message.success(
                  `Request accepted to cancel PipelineJob(${pipelineJob.name})`
                );
                break;
              case "UnauthenticatedError":
              case "NotActivePipelineJobError":
                message.error(response.cancelPipelineJob.pipelineJob.message);
                break;
            }
            resolve(response.cancelPipelineJob?.pipelineJob);
          },
          onError(error) {
            message.error(error.message);
            reject();
          },
        });
      });
    }
  };

  const onClick = () => {
    Modal.confirm({
      title: "Cancel PipelineJob?",
      content: (
        <Text>
          This cannot be undone. Are you sure to cancel{" "}
          <Text code>{pipelineJob.name}</Text> PipelineJob?
        </Text>
      ),
      okButtonProps: {
        danger: true,
      },
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        cancelPipelineJob();
      },
    });
  };

  const disabled = isFinalStatus(pipelineJob.status);
  const isPipelineJobCancelling = pipelineJob.status === "CANCELLING";

  return (
    <Tooltip
      title={
        disabled
          ? ""
          : isPipelineJobCancelling
            ? "The cancellation work is in progress."
            : "Cancel pipeline job"
      }
    >
      <Button
        size="small"
        onClick={onClick}
        disabled={disabled || isPipelineJobCancelling}
        loading={isInFlightCommitCancel || isPipelineJobCancelling}
        icon={<CloseCircleOutlined />}
        type="link"
        {...props}
      />
    </Tooltip>
  );
};

export default PipelineJobCancelButton;
