import { Result } from "antd";
import React from "react";

import Flex from "./Flex";

interface NotFoundTemplateProps {
  title: string;
  subTitle: string;
}

const NotFoundTemplate: React.FC<NotFoundTemplateProps> = ({
  title,
  subTitle,
}) => {
  return (
    <Flex
      direction="row"
      justify="center"
      align="center"
      style={{ flex: 1, height: "100%" }}
    >
      <Result status={"warning"} title={title} subTitle={subTitle} />
    </Flex>
  );
};

export default NotFoundTemplate;
