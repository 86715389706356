/**
 * @generated SignedSource<<80992de07fa6cb6b5618f0cf0ccb5190>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PipelineJobStatusChoices = "CANCELLED" | "CANCELLING" | "ERROR" | "PAUSED" | "PENDING" | "RUNNING" | "TERMINATED" | "WAITING" | "%future added value";
export type TaskInstanceStatusChoices = "BUILDING" | "CANCELLED" | "ERROR" | "PENDING" | "PREPARING" | "PULLING" | "RESIZING" | "RESTARTING" | "RUNNING" | "SCHEDULED" | "SKIPPED" | "SUSPENDED" | "TERMINATED" | "TERMINATING" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PipelineJobCancelButtonFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly status: PipelineJobStatusChoices;
  readonly taskinstanceSet: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly status: TaskInstanceStatusChoices;
      } | null;
    } | null>;
  };
  readonly " $fragmentType": "PipelineJobCancelButtonFragment";
};
export type PipelineJobCancelButtonFragment$key = {
  readonly " $data"?: PipelineJobCancelButtonFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineJobCancelButtonFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineJobCancelButtonFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TaskInstanceConnection",
      "kind": "LinkedField",
      "name": "taskinstanceSet",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "TaskInstanceEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TaskInstance",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PipelineJob",
  "abstractKey": null
};
})();

(node as any).hash = "17dd1a7d84ccba3701649ca76b35d166";

export default node;
