/**
 * @generated SignedSource<<64b407812170b371d8a037148d3035e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CancelPipelineScheduleInput = {
  clientMutationId?: string | null;
  id: string;
};
export type RunPipelineModalCancelScheduleMutation$variables = {
  input: CancelPipelineScheduleInput;
};
export type RunPipelineModalCancelScheduleMutation$data = {
  readonly cancelPipelineSchedule: {
    readonly pipeline: {
      readonly __typename: string;
      readonly id?: string;
      readonly message?: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"PipelineSummaryFragment">;
    } | null;
  } | null;
};
export type RunPipelineModalCancelScheduleMutation = {
  response: RunPipelineModalCancelScheduleMutation$data;
  variables: RunPipelineModalCancelScheduleMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "message",
      "storageKey": null
    }
  ],
  "type": "UnauthenticatedError",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RunPipelineModalCancelScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelPipelineSchedulePayload",
        "kind": "LinkedField",
        "name": "cancelPipelineSchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v4/*: any*/),
                "type": "Pipeline",
                "abstractKey": null
              },
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PipelineSummaryFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RunPipelineModalCancelScheduleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CancelPipelineSchedulePayload",
        "kind": "LinkedField",
        "name": "cancelPipelineSchedule",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "pipeline",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v3/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastModified",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isActive",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "storage",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "yaml",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "owner",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PipelineJobConnection",
                    "kind": "LinkedField",
                    "name": "pipelinejobSet",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PipelineVersionConnection",
                    "kind": "LinkedField",
                    "name": "pipelineversionSet",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PipelineVersionEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PipelineVersion",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "createdAt",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PeriodicTask",
                    "kind": "LinkedField",
                    "name": "scheduledTask",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "CrontabSchedule",
                        "kind": "LinkedField",
                        "name": "crontab",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "minute",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hour",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dayOfWeek",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dayOfMonth",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "monthOfYear",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expires",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "expireSeconds",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "Pipeline",
                "abstractKey": null
              },
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": (v4/*: any*/),
                "type": "Node",
                "abstractKey": "__isNode"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "81edb1c0a00cb60240e05682c880ca98",
    "id": null,
    "metadata": {},
    "name": "RunPipelineModalCancelScheduleMutation",
    "operationKind": "mutation",
    "text": "mutation RunPipelineModalCancelScheduleMutation(\n  $input: CancelPipelineScheduleInput!\n) {\n  cancelPipelineSchedule(input: $input) {\n    pipeline {\n      __typename\n      ... on Pipeline {\n        id\n      }\n      ... on UnauthenticatedError {\n        message\n      }\n      ...PipelineSummaryFragment\n      ... on Node {\n        __isNode: __typename\n        id\n      }\n    }\n  }\n}\n\nfragment CrontabViewFragment on CrontabSchedule {\n  id\n  minute\n  hour\n  dayOfWeek\n  dayOfMonth\n  monthOfYear\n}\n\nfragment PipelineDeleteButtonFragment on Pipeline {\n  id\n  name\n  storage\n  scheduledTask {\n    expires\n    id\n  }\n}\n\nfragment PipelineEditorModalFragment on Pipeline {\n  id\n  name\n  description\n  storage\n  yaml\n  scheduledTask {\n    id\n  }\n}\n\nfragment PipelineSummaryFragment on Pipeline {\n  id\n  name\n  description\n  lastModified\n  isActive\n  storage\n  yaml\n  owner {\n    username\n    name\n    id\n  }\n  pipelinejobSet {\n    totalCount\n  }\n  pipelineversionSet {\n    totalCount\n    edges {\n      node {\n        id\n        createdAt\n      }\n    }\n  }\n  scheduledTask {\n    crontab {\n      ...CrontabViewFragment\n      id\n    }\n    expires\n    expireSeconds\n    id\n  }\n  ...PipelineEditorModalFragment\n  ...RunPipelineModalFragment\n  ...PipelineDeleteButtonFragment\n}\n\nfragment RunPipelineModalFragment on Pipeline {\n  id\n  name\n  yaml\n  scheduledTask {\n    crontab {\n      minute\n      hour\n      dayOfMonth\n      monthOfYear\n      dayOfWeek\n      id\n    }\n    expires\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d96f66e12dc7ce770c73a65b54e7a3b7";

export default node;
