import { useUpdateEffect } from "ahooks";
import { Divider, Skeleton, Table, Typography } from "antd";
import graphql from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import _ from "lodash";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { usePaginationFragment } from "react-relay";

import { from_global_id } from "../helpers";
import Flex from "./Flex";
import {
  PipelineVersionInfiniteListPaginationFragment$data,
  PipelineVersionInfiniteListPaginationFragment$key,
} from "./__generated__/PipelineVersionInfiniteListPaginationFragment.graphql";
import {
  PipelineVersionInfiniteListPaginationQuery,
  PipelineVersionInfiniteListPaginationQuery$variables,
} from "./__generated__/PipelineVersionInfiniteListPaginationQuery.graphql";

interface IPipelineVersionId {
  id: string | undefined;
}

export type PipelineVersionNodeType = NonNullableNodeOnEdges<
  PipelineVersionInfiniteListPaginationFragment$data["pipelineVersions"]
>;

export interface PipelineVersionInfiniteListProps {
  pipelineVersionQueryRef: any;
  queryVariables: PipelineVersionInfiniteListPaginationQuery$variables;
  refreshKey?: string;
  selectedPipelineVersionId?: string;
  onSelectPipelineVersion: (
    pipelineVersion: PipelineVersionNodeType | IPipelineVersionId
  ) => void;
}

export type Ref = HTMLDivElement;

const PipelineVersionInfiniteList = React.forwardRef<
  Ref,
  PipelineVersionInfiniteListProps
>(
  (
    {
      pipelineVersionQueryRef,
      queryVariables,
      refreshKey,
      onSelectPipelineVersion,
      selectedPipelineVersionId,
    },
    ref
  ) => {
    const pipelineVersionPagination = usePaginationFragment<
      PipelineVersionInfiniteListPaginationQuery,
      PipelineVersionInfiniteListPaginationFragment$key
    >(
      graphql`
        fragment PipelineVersionInfiniteListPaginationFragment on Query
        @refetchable(queryName: "PipelineVersionInfiniteListPaginationQuery") {
          pipelineVersions(
            first: $first
            after: $after
            order: $order
            filter: $filter
          ) @connection(key: "PipelineVersionList_pipelineVersions") {
            __id
            totalCount
            edges {
              node {
                id
                createdAt
                author {
                  username
                }
              }
            }
          }
        }
      `,
      pipelineVersionQueryRef
    );

    useUpdateEffect(() => {
      pipelineVersionPagination.refetch(queryVariables, {
        fetchPolicy: "network-only",
      });
    }, [JSON.stringify(queryVariables), refreshKey]);

    const columns = [
      {
        title: "ID / Created At",
        dataIndex: "id",
        key: "id",
        render: (id: string, record: PipelineVersionNodeType) => {
          const pipelineVersionId = from_global_id(id)[1];
          return (
            <Flex direction="column" align="start">
              <Typography.Link
                // onClick={() => {
                //   onSelectPipelineVersion(record);
                // }}
                style={{
                  fontFamily: "monospace",
                }}
              >
                {pipelineVersionId.split("-")[0]}
              </Typography.Link>
              <Typography.Text
                style={{
                  fontFamily: "monospace",
                }}
              >
                Author: {record.author.username}
              </Typography.Text>
              <Typography.Text type="secondary">
                {dayjs(record.createdAt).utc(true).format("lll")}
              </Typography.Text>
            </Flex>
          );
        },
      },
      // {
      //   title: "Created At",
      //   dataIndex: "createdAt",
      //   key: "createdAt",
      //   render: (createdAt: Date) => {
      //     return dayjs(createdAt).utc(true).format("lll");
      //   },
      // },
      // {
      //   title: "Created By",
      //   dataIndex: "owner",
      //   key: "owner",
      //   render: (owner: IPipelineVersionOwner) => {
      //     return owner.username;
      //   },
      // },
    ];

    const x = _.filter(
      _.map(
        pipelineVersionPagination.data.pipelineVersions?.edges,
        (e) => e?.node
      ),
      (x) => x
    );

    return (
      <InfiniteScroll
        dataLength={
          pipelineVersionPagination.data.pipelineVersions?.edges.length || 0
        }
        next={() => {
          pipelineVersionPagination.loadNext(10);
        }}
        hasMore={pipelineVersionPagination.hasNext}
        loader={
          <Skeleton
            paragraph={{ rows: 1 }}
            style={{ padding: "8px 10px" }}
            loading={pipelineVersionPagination.isLoadingNext}
            active
          />
        }
        endMessage={
          <Divider plain>
            (Total:{" "}
            {pipelineVersionPagination.data.pipelineVersions?.totalCount})
          </Divider>
        }
        scrollableTarget="pipeline-version-table"
      >
        <Table
          size="small"
          id="pipeline-version-table"
          rowKey={(record) => record.id}
          dataSource={
            pipelineVersionPagination.data.pipelineVersions?.edges.map(
              (e) => e?.node
            ) as PipelineVersionNodeType[]
          }
          rowSelection={{
            type: "radio",
            onChange: (selectedRowKeys: React.Key[]) => {
              onSelectPipelineVersion({ id: selectedRowKeys[0] as string });
            },
            selectedRowKeys: selectedPipelineVersionId
              ? [selectedPipelineVersionId]
              : [],
          }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                onSelectPipelineVersion({ id: record.id });
              },
            };
          }}
          /*
          summary={() => (
            <Table.Summary fixed="top">
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <Typography.Link
                    onClick={() => {
                      onSelectPipelineVersion({ id: undefined });
                    }}
                  >
                    main
                  </Typography.Link>
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>Created At</Table.Summary.Cell>
                <Table.Summary.Cell index={2}>Created By</Table.Summary.Cell>
              </Table.Summary.Row>
            </Table.Summary>
          )}
          sticky
          */
          columns={columns}
          pagination={false}
        />
      </InfiniteScroll>
    );
  }
);

export default PipelineVersionInfiniteList;
