import { MenuOutlined } from "@ant-design/icons";
import { theme } from "antd";
import React from "react";

import Flex from "./Flex";
import { PipelineTask } from "./PipelineTaskTemplates";

const PipelineTaskTemplate: React.FC<{
  taskType: PipelineTask;
  label: React.ReactNode;
  color: string;
}> = ({ taskType, label, color }) => {
  const onDragStart = (event: React.DragEvent, nodeType: PipelineTask) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const { token } = theme.useToken();

  return (
    <Flex
      align="center"
      justify="start"
      style={{
        width: 160,
        height: 40,
        border: "1px solid gray",
        borderRadius: 5,
        backgroundColor: token.colorPrimaryBg,
        borderLeftColor: color,
        borderLeftWidth: 8,
        borderTopLeftRadius: 4,
        borderBottomLeftRadius: 4,
        cursor: "grab",
      }}
      className="dndnode input"
      onDragStart={(event) => onDragStart(event, taskType)}
      draggable
    >
      <Flex
        align="center"
        justify="center"
        style={{
          paddingLeft: 10,
          paddingRight: 30,
        }}
      >
        {label}
      </Flex>
      <Flex
        align="center"
        justify="end"
        style={{ position: "absolute", margin: 128 }}
      >
        <MenuOutlined style={{ color: "gray" }} />
      </Flex>
    </Flex>
  );
};

export default PipelineTaskTemplate;
