import { atom } from "jotai";

import { coreServiceVersionAtom } from ".";
import { isAPIVersionCompatible } from "../backendai";

export const pipelineSchemaVersionAtom = atom(async (get) => {
  const { version: apiVersion } = await get(coreServiceVersionAtom);
  const versionPairs = [
    ["v24.03", "v7.20230615"],
    ["v3", "v6.20230315"],
    ["v2", "v6.20200815"],
    ["v1", "v0.00000000"],
  ];
  for (const [yamlSchemaVersion, targetAPIVersion] of versionPairs) {
    if (isAPIVersionCompatible(apiVersion, targetAPIVersion)) {
      return yamlSchemaVersion;
    }
  }
  return null;
});
