/**
 * @generated SignedSource<<613b8ba852333f43abeb93913500cae1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PipelineJobResultChoices = "FAILURE" | "SUCCESS" | "UNDEFINED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type PipelineJobResultIcon_pipelineJob$data = {
  readonly result: PipelineJobResultChoices;
  readonly " $fragmentType": "PipelineJobResultIcon_pipelineJob";
};
export type PipelineJobResultIcon_pipelineJob$key = {
  readonly " $data"?: PipelineJobResultIcon_pipelineJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"PipelineJobResultIcon_pipelineJob">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PipelineJobResultIcon_pipelineJob",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "result",
      "storageKey": null
    }
  ],
  "type": "PipelineJob",
  "abstractKey": null
};

(node as any).hash = "6da19a184bebb407039586bfd420bc28";

export default node;
