import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import { baiFetch } from "../hooks/auth";
import DoubleTag, { DoubleTagObjectValue } from "./DoubleTag";

const hasPermission = (permission: string | undefined, perm: string) => {
  if (permission?.includes(perm)) {
    return true;
  }
  if (permission?.includes("w") && perm === "r") {
    return true;
  }
  return false;
};

type VFolderPermissionTagProps = {
  vFolderName?: string;
  permission?: string;
};

const VFolderPermissionTag: React.FC<VFolderPermissionTagProps> = ({
  vFolderName,
  permission,
}) => {
  const [vFolderPermission, setVFolderPermission] = useState<
    string | undefined
  >(permission);
  const requestVFolderPermission = useCallback(
    async (vFolderName: string) => {
      if (_.isEmpty(vFolderName)) {
        return;
      }
      const response = await baiFetch(`/func/folders/${vFolderName}`, {
        method: "GET",
      }).then((res) => {
        if (res.status === 404) {
          console.error(`VFolder(${vFolderName}) not found.`);
          return;
        }
        return res.json();
      });
      setVFolderPermission(response?.permission);
    },
    [vFolderName]
  );
  useEffect(() => {
    if (vFolderName !== undefined) {
      requestVFolderPermission(vFolderName);
    }
  }, [requestVFolderPermission, vFolderName]);
  const tagValues: DoubleTagObjectValue[] = _.chain({
    r: "green",
    w: "blue",
    d: "red",
  })
    .map((color, perm) => {
      if (hasPermission(vFolderPermission, perm)) {
        return {
          label: perm.toUpperCase(),
          color,
        };
      }
      return undefined;
    })
    .value();

  return <DoubleTag values={tagValues} />;
};

export default VFolderPermissionTag;
